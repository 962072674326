<template>
              <div class="row mt-4">
              <div class="col-12">

                <div v-if="classId != '0'">
                  <div class="row border-bottom1">
                    <div class="col-sm">
                      <div class="f_24 font_bold">Class {{className}}</div>
                      <div class="">{{schoolName}} </div>
                    </div>
                    <div class="col-sm ">
                      <div class=" text-sm-right">
                        <span class="color_muted f_12  mr-2"><span class="classkey_border pa-2 f_14 color_black">Class Key: {{classKey}}</span></span>
                      </div>
                      <div class="text-sm-right f_14 mt-3 pr-2" >
                        <v-icon size="20" >{{icons.mdiCalendarRange }}</v-icon> {{classStartDate }} > {{classEndDate }}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="d-none d-sm-block" v-if="classId == '0'">
                  <div class="d-flex flex-row">
                    <div>
                      <div class="f_20 font_bold">No class attached! </div>
                      <div class="">You can join a class for this book with your 'ClassKey'.</div>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn outlined class="ma-2 rounded-pill btn_outline_pink" @click="runChange">
                      <v-icon left  >{{icons.mdiPlusCircleOutline }}</v-icon> Join A Class
                    </v-btn>
                  </div>
                </div>

                <div class="d-block d-sm-none text-center mt-0" v-if="classId == '0'">
                    <div>
                      <div class="f_20 font_bold">No class attached! </div>
                      <div class=" f_14 color_muted">You can join a class for this book with your 'ClassKey'.</div>
                    </div>
                    <v-btn outlined small class="ma-2 rounded-pill btn_outline_pink" @click="runChange">
                      <v-icon left  >{{icons.mdiPlusCircleOutline }}</v-icon> Join A Class
                    </v-btn>
                </div>



              </div>
            </div>

</template>

<script>
import { mdiPlusCircleOutline, mdiCalendarRange   } from '@mdi/js'

export default {
  data: () => ({
      
  }),
  props: ['className','classKey','schoolName', 'classId', 'dialogClassKey', 'classStartDate', 'classEndDate'],
  methods: {

    runChange(){
      this.$emit('changeDialogBox')
    }

  },
  setup() {
    return {
      icons: { mdiPlusCircleOutline, mdiCalendarRange  },
    }
  },
  created () {
    
  },
}
</script>

