<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>CLASSROOM STUDENTS</div>
          </v-card-title>
          <v-card-subtitle>{{schoolName}} - {{className}}</v-card-subtitle>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">Name Surname</th>
                  <th class="text-left">Email Address</th>
                  <th width="200"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in students" :key="item.studentId" >
                  
                    <td>{{ index +1 }}</td>
                    <td>{{ item.studentName }}</td>
                    <td>{{ item.studentEmail }}</td>
                    <td class="text-end">
                        <v-btn small text @click="remove(item.studentId)">REMOVE</v-btn>
                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          
      </v-card>
    </div>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold  },
      }
    },

    data(){
      return {
        className: "",
        schoolName: "",
        students: [],
        teachers_all : [],
        teachers_selected: [],
        dialog: false
        
      }
    },

    methods: {
      getData() {
        
        const data = { classId : this.$route.params.classId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/class_students",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.students = response.data.students;
            this.paginationLenght = Math.ceil(response.data.itemCount / 10);
            this.className = response.data.className;
            this.schoolName = response.data.schoolName;
          }  
        );

      },

      getTeachers_allData() {
        
        const data = { page : 0 }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/teachers",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.teachers_all = response.data.teachers;
          }  
        );

      },

      saveData(){
        const data = {classId : this.$route.params.classId, teachers : this.teachers_selected }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/class_addteachers",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            if(response.data.result == "success"){
              this.teachers_selected = [];
              this.dialog = false;
              this.getTeachersData();
            }
          }  
        );
      },

      vtoastCallback() {

      },

      async remove(studentId) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to remove this student from this class?"
          )
        ){

          const data = { studentId : studentId, classId : this.$route.params.classId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/class_removestudent",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Student removed from class succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.getData();
              }
            }  
          );

          
        }
      }

    },

    watch: {
      schoolSelect: function (newValue, oldValue) {
        if(newValue != oldValue){ this.getClassData(); }
      },
      dialog: function (newValue) {
        if(newValue == true){ this.getTeachers_allData(); }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

