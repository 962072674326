<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>TEACHERS</div>
            <v-spacer></v-spacer>
            <div style="width:200px">
              <v-text-field class="ml-0 mr-3 my-0" hide-details outlined dense @keyup="searchCode" v-model="searchKeyword" label="Search"></v-text-field>
            </div>
            <v-btn small color="accent" elevation="0" :href="'/eisadmin/teacher_add/'" >CREATE NEW TEACHER</v-btn>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">Name Surname</th>
                  <th class="text-left">Email Address</th>
                  <th class="text-left">Books</th>
                  <th class="text-left">Schools</th>
                  <th class="text-left">Classrooms</th>
                  <th width="200"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in teachers" :key="item.teacherId" >
                  
                    <td>{{ (page - 1) * 10 + index +1 }}</td>
                    <td>{{ item.teacherName }}</td>
                    <td>{{ item.teacherEmail }}</td>
                    <td><v-btn :href="'/eisadmin/teacher_Books/'+ item.teacherId" small color="accent" outlined >{{ item.bookCount }}</v-btn></td>
                    <td><v-btn :href="'/eisadmin/teacher_schools/'+ item.teacherId" small color="accent" outlined >{{ item.schoolCount }}</v-btn></td>
                    <td><v-btn :href="'/eisadmin/teacher_classes/'+ item.teacherId" small color="accent" outlined >{{ item.classCount }}</v-btn></td>
                    <td class="text-right">

                        <v-btn v-if="item.classCount == 0" small text @click="deleteTeacher(item.teacherId, index)">DELETE</v-btn>
                        <v-btn small text :href="'/eisadmin/teacher_edit/'+ item.teacherId">EDIT</v-btn>

                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions class="mt-3" >
            <v-spacer></v-spacer>
            <v-pagination v-model="page" :length= this.paginationLenght :total-visible=7 ></v-pagination>
          </v-card-actions>
      </v-card>
    </div>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold  },
      }
    },

    data(){
      return {
        searchKeyword: "",
        teachers: [],
        page: 1,
        paginationLenght : 0
      }
    },

    methods: {
      searchCode(){   

        console.log(this.searchKeyword);

        if(this.searchKeyword.length >=4){

          const data = {searchKeyword : this.searchKeyword }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/teachers_search",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              this.teachers = response.data.teachers;
              this.paginationLenght = 0;
              //this.bookName = response.data.bookName;
            }  
          );

        }

        if(this.searchKeyword.length ==0){
          this.page = 1;
          this.teachers = [];
          this.getData();
        }

      },

      getData() {
        
        const data = { page : this.page }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/teachers",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.teachers = response.data.teachers;
            this.paginationLenght = Math.ceil(response.data.itemCount / 10);
          }  
        );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
      },

      async deleteTeacher(teacherId, index) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to delete this teacher?"
          )
        ){

          const data = { teacherId : teacherId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/teacher_delete",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Teacher deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.teachers.splice(index, 1);
              }
            }  
          );

          
        }
      }

    },

    watch: {
      page: function (newValue, oldValue) {
        if(newValue != oldValue){
          this.getData();
        }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

