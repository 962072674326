<template>
              <div class="row mt-5 ">
                <div class="col-md-2 ">
                    <v-img height="140" :src="VUE_APP_BOOK_MEDIA+bookCover" contain class="mx-3" position="center top"  />
                </div>
                <div class="col-md-10">
                  <div class="f_24 font_bold color_pink">{{bookName}}</div>
                  <div class="mt-1 font_bold color_black">{{bookSubtitle}}</div>
                  <div class="f_14 color_muted mt-1">{{bookDescription}}</div>
                  <div class="f_14 color_muted mt-1">KEY: {{bookCode}}</div>
                  <div class="f_14 color_muted mt-1">{{bookCodeDates}}</div>
                </div>
              </div>

</template>

<script>
import { mdiPlusCircleOutline  } from '@mdi/js'

export default {
  data: () => ({
      VUE_APP_API_DOMAIN : "",
      VUE_APP_BOOK_MEDIA : process.env.VUE_APP_BOOK_MEDIA,
  }),
  props: ['bookCover','bookName','bookSubtitle', 'bookDescription', 'bookCode', 'bookCodeDates'],
  methods: {

  

  },
  setup() {
    return {
      icons: { mdiPlusCircleOutline },
    }
  },
  created () {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
  },
}
</script>

