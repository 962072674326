<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>CLASSROOMS</div>
          </v-card-title>
          <v-card-subtitle><a href="/eisadmin/teachers" class="link_blue">Teachers</a> > {{teacherName}}</v-card-subtitle>
          

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">Classroom</th>
                  <th class="text-left">Book</th>
                  <th class="text-left">Dates</th>
 
                  <th class="text-left">Students</th>
                  <th width="200"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in classes" :key="item.classId" >
                  
                    <td>{{ index +1 }}</td>
                    <td>
                      <div><span class="font-weight-bold">{{ item.className }}</span> - Key: {{ item.classKey }}</div> 
                      <div>{{ item.schoolName }}</div> 

                    </td>
                    <td>{{ item.bookName }}</td>
                    <td>
                      <div>{{ item.startDate }}</div>
                      <div>{{ item.endDate }}</div>
                    </td>
                    <td><v-btn :href="'/eisadmin/class_students/'+ item.classId" small color="accent" outlined >{{ item.studentCount }}</v-btn></td>
                    <td class="text-right">

                        <v-btn v-if="item.studentCount != 0" small text @click="dialog = !dialog; selectedC(item.classId) ">MOVE</v-btn>
                        <v-btn v-if="item.studentCount == 0" small text @click="deleteClass(item.classId, index)">DELETE</v-btn>
                        <v-btn small text :href="'/eisadmin/class_edit/'+ item.classId">EDIT</v-btn>

                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          
      </v-card>
    </div>


    <v-dialog v-model="dialog" persistent max-width="600px">
      
      <v-card>
        <v-card-title>
          <span class="text-h5">Move Class to Another Teacher</span>
        </v-card-title>

        <v-card-text>

          <v-combobox
          v-model="teacherSelect"
          :items="teachers"
          label="Teacher"
          item-value="teacherId"
          item-text="teacherName"
          outlined
          
        ></v-combobox>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="dialog = false" >Close</v-btn>
          <v-btn color="primary darken-1" elevation="0" @click="saveData" >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold  },
      }
    },

    data(){
      return {
        teacherName: "",
        classes: [],
        teachers : [],

        schools_classes : [],
        teacherSelect: "",
        classSelected: 0,
        dialog: false,
      }
    },

    methods: {

      selectedC(cc){
        this.classSelected = cc
      },

      getData() {
        
        const data = { teacherId : this.$route.params.teacherId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/teacher_classes",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.classes = response.data.classes;
            this.teacherName = response.data.teacherName;
          }  
        );

      },

      getAllTeachersData() {
        
        const data = { page : 0 }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/teachers",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.teachers = response.data.teachers;
          }  
        );

      },

      getClassData(){
        const data = { page : 0, schoolId : this.schoolSelect }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/school_classes",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.schools_classes = response.data.classes;
          }  
        );
      },

      saveData(){
        const data = {classId : this.classSelected, teacherId : this.teacherSelect.teacherId }
        
        axios.post(process.env.VUE_APP_API_DOMAIN + "/class_move2teacher",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.classes = response.data.classes;
            this.dialog = false;
            this.getData();
          }  
        );
        
      },

      vtoastCallback() {
        //this.getTeachersData();
      },

      async deleteClass(classId, index) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to delete this class?"
          )
        ){

          const data = { classId : classId, teacherId : this.$route.params.teacherId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/teacher_deletefromclass",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Class deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.classes.splice(index, 1);
              }
            }  
          );

          
        }
      }

    },

    watch: {
      schoolSelect: function (newValue, oldValue) {
        if(newValue != oldValue){ this.getClassData(); }
      },
      dialog: function (newValue) {
        if(newValue == true){ this.getAllTeachersData(); }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

