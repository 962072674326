<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      
      <v-card elevation="0" outlined>
        <v-card-title>Edit Book</v-card-title>

        <div class="pa-5"> 
          <v-form ref="form" v-model="valid" lazy-validation>
          
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field outlined v-model="bookName"  :rules="nameRules" label="Book Name" required ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="bookSubtitle" outlined  label="SubTitle" ></v-text-field>
              </v-col>
            </v-row>

            <v-textarea outlined v-model="bookDescription" name="description" label="Book Description" value=""></v-textarea>
            
            <v-row>
              <v-col cols="12" md="1">
                <v-img :src="VUE_APP_BOOK_MEDIA+this.bookCover" contain width="60" />
              </v-col>
              <v-col cols="12" md="11">
                <v-file-input v-model="files" outlined accept="image/png, image/jpeg" placeholder="Book Cover" label="Book Cover" name ="Book Cover" ></v-file-input>
              </v-col>
            </v-row>

            <v-color-picker v-model="bgCo" hide-inputs elevation="0" mode="hexa"  class="mt-5" ></v-color-picker>
            <v-text-field v-model="bgCo" outlined  label="Leaflet Header Color" ></v-text-field>



          </v-form>


        </div>

        <v-card-actions class="pt-0" >
          <v-btn v-if="bookStatus == 'active'" small color="black" class=" white--text" @click="deleteBook">ARCHIVE</v-btn>
          <v-btn v-if="bookStatus == 'archived'" small color="green" class=" white--text" @click="activateBook">ACTIVATE</v-btn>
          <v-spacer></v-spacer>
          <v-btn to="/eisadmin/books" color="error" small elevation="0" class="mb-2 mr-2 mt-2" >CANCEL</v-btn>
          <v-btn  small @click="submit" color="primary" >Save</v-btn>
        </v-card-actions>

      </v-card>

    </div>

</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiGithub, mdiAlertRhombus, mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline, mdiGithub,},
      }
    },

    data: () => ({
      VUE_APP_API_DOMAIN : "",
      VUE_APP_BOOK_MEDIA : process.env.VUE_APP_BOOK_MEDIA,
      valid: true,
      bookId : 0,
      bookName: '',
      bookSubtitle: '',
      bookDescription: '',
      bgCo : "",
      bookStatus : "",
      bookCover: 'null.jpg',
      files: [],
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 2) || 'Name must be more than 2 characters',
      ],
    }),

    methods: {
      getData(bookId) {
        
        const data = { bookId : bookId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/book",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.bookName = response.data.book.bookName;
            this.bookSubtitle = response.data.book.bookSubtitle;
            this.bookDescription = response.data.book.bookDescription;
            this.bookCover = response.data.book.bookCover;
            this.bgCo = response.data.book.bookHeaderColor;
            this.bookStatus = response.data.book.bookStatus;
          }  
        );

      },
      submit () {
        
        if(this.$refs.form.validate()){
          
          const fd = new FormData();

          fd.append('image', this.files);
          fd.append('bookName', this.bookName);
          fd.append('bookDescription', this.bookDescription);
          fd.append('bookSubtitle', this.bookSubtitle);
          fd.append('bgColor', this.bgCo);
          fd.append('bookId', this.$route.params.bookId);

          axios.post(process.env.VUE_APP_API_DOMAIN + "/book_save",fd,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken}})
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Book is saved succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }
            }  
          );

        }

      },
      vtoastCallback() {
        //vtoastCallback
        this.$router.push('/eisadmin/books');
      },

      async deleteBook() {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to archive this book?"
          )
        ){

          const data = { bookId : this.bookId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/book_archive",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Book archived succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }
            }  
          );
        }
      },
      async activateBook() {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to activate this book?"
          )
        ){

          const data = { bookId : this.bookId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/book_activate",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Book activated succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }
            }  
          );
        }
      }
      
    },
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.bookId = this.$route.params.bookId;
      this.getData(this.$route.params.bookId);
    },
  }
</script>

