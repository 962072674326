<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />

    <div class="pa-5" >

      <v-row class="mx-0" >
        <div>
          <div class="f_18 font-weight-bold">EDIT DROPDOWN LIST QUESTION</div>
          <div class="f_14 color_muted">
            <a href="/eisadmin/books" class="link_blue">Books</a> > 
            <a :href="'/eisadmin/units/'+bookId" class="link_blue">{{bookName}}</a> > 
            <a :href="'/eisadmin/leaflets/'+unitId" class="link_blue">{{unitName}}</a> > 
            <a :href="'/eisadmin/questions/'+leafletId" class="link_blue">{{this.leafletName}}</a>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn :to="'/eisadmin/questions/'+leafletId" color="error" small elevation="0" class="mb-2 mr-2 mt-2" >CANCEL</v-btn>
        <v-btn @click="submit" color="success" small elevation="0" class="mb-2 mt-2" >SAVE</v-btn>
      </v-row >

      
      <v-row >
        <v-col cols="12" sm="8">

            <v-card elevation="0" outlined>
              <div class="pa-5"> 
                <v-form ref="form"  >
                  <v-text-field outlined v-model="questionTitle"  label="Question Text"  ></v-text-field>
                  <v-select v-model="selectedCover" @change="changeCover"  :items="coverTypes" item-text="name" item-value="type" class="mt-2"  label="Cover Types"  outlined  ></v-select>
                  <div v-if="visible_image">
                    <v-row class="ms-4">
                      <div class=" mr-2 mb-4 text-center "  v-for="imgs in this.images_old" :key="imgs.imageId" >
                        <div><v-img  max-height="100" max-width="100" :src="VUE_APP_QUESTION_MEDIA+imgs.image"></v-img> </div>
                        <v-btn class="mt-2" @click="deleteImage(imgs.imageId)" small text><v-icon>{{icons.mdiDelete}}</v-icon></v-btn>
                      </div>
                    </v-row>

                    <v-file-input v-model="images" outlined multiple small-chips   accept="image/png, image/jpeg" placeholder="Choose Images" label="Cover Images" name ="Cover Images" ></v-file-input>
                  </div>
                  
                  <v-text-field outlined v-model="video" v-if="visible_video"  label="Video Url"  placeholder="https://" ></v-text-field>
                  <v-text-field outlined v-model="sound" v-if="visible_sound"  label="Sound Url"  placeholder="https://"></v-text-field>
                  <v-textarea outlined v-model="soundText" v-if="visible_sound"  label="Sound Text"  placeholder=""></v-textarea>
                  <v-textarea outlined v-model="text" v-if="visible_text"  label="Text Paragraph" ></v-textarea>
                </v-form>
              </div>
            </v-card>

            <v-card elevation="0" outlined  class="mt-3">
              <div class="pa-5"> 

                <v-row class="mb-4">
                  <v-spacer></v-spacer>
                  <v-btn elevation="0" outlined small text @click="addList">+</v-btn>
                </v-row>

                            
                <draggable :list="items"  @end="endDrag(items)" >
                  <div v-for="item in items" :key="item.itemId">

                      <v-card class="pa-3 mb-6" color="#f4f3f9" outlined >

                        <div class="row">
                            <v-spacer></v-spacer>
                            <v-btn elevation="0" class="mt-2 " color="primary" small text @click="deleteItem(item.itemId)">
                              <v-icon>{{icons.mdiDelete}}</v-icon>
                            </v-btn>
                        </div>
                        <v-divider class="my-4"></v-divider>

                          <div class="row">
                            <div class="col  mx-0">
                              <v-textarea auto-grow rows="1" hide-details outlined  v-model="item.pre" background-color="white"  class="ma-0" label="Pre" required  ></v-textarea>
                            </div>
                            <div class="col text-center ">
                              <v-btn elevation="0" outlined  color="primary"  text @click="addOption(item.itemId)">Add option</v-btn>
                            </div>
                            <div class="col  mx-0">
                              <v-textarea auto-grow rows="1" hide-details outlined  v-model="item.post" background-color="white"  class="ma-0" label="Post" required  ></v-textarea>
                            </div>

                          </div>

                          <v-card class="px-3" color="#f4f3f9" outlined >
                              <v-radio-group hide-details v-model="item.selectedOption" class="mx-3" >
                                <v-row class="mt-1" v-for="drp in item.options" :key="drp.itemOptionId" >
                                    <v-radio class="mt-n6"  :value="drp.itemOptionId" @change="changeOption"></v-radio>
                                    <v-text-field background-color="white" dense outlined v-model="drp.value" label="Option" required ></v-text-field>
                                    <v-btn text small class="ml-4 mt-1" elevation="0" @click="deleteOption(drp.itemOptionId)"><v-icon>{{icons.mdiDelete}}</v-icon></v-btn>
                                </v-row>  
                              </v-radio-group>
                          </v-card>

                      </v-card>

                  </div>
                </draggable>




              </div>

            </v-card>



        </v-col>

        <v-col cols="12" sm="4">

          <v-card elevation="0" outlined >  
            <v-card-title primary-title>Listing</v-card-title>
            <div class="mx-5"> 
              <v-radio-group class="mt-0" v-model="selectedListing">
                <v-radio label="list (i, ii, iii, iv)" value="list_i" ></v-radio>
                <v-radio label="list (a, b, c, d)" value="list_a" ></v-radio>
                <v-radio label="list (1, 2, 3, 4)" value="list_1" ></v-radio>
              </v-radio-group>
            </div>

          </v-card> 
          <v-card elevation="0" outlined class="mt-3">  
            <v-card-title primary-title>RICH TEXT</v-card-title>
            <div class="mx-5">Rich texts can be used for text covers, question texts. Do not use it for answer areas.</div>
            <div class="mx-5"> 

              <v-simple-table>
                <template v-slot:default>
                  
                  <tbody>
                    <tr>
                      <td>**text**</td>
                      <td><strong>BOLD</strong></td>
                    </tr>
                    <tr>
                      <td>*text*</td>
                      <td><em>ITALIC</em></td>
                    </tr>
                    <tr>
                      <td>`text`</td>
                      <td><code>CODE</code></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </div>
          </v-card> 
        </v-col>
      </v-row>



    </div>

</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiGithub, mdiAlertRhombus, mdiCheckBold, mdiDelete  } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import draggable from 'vuedraggable';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, draggable },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline, mdiGithub,mdiDelete },
      }
    },
    
    data: () => ({
      VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
      questionId: '',
      leafletId : '',
      unitId: '',
      bookId: '',
      bookName: '',
      unitName: '',
      leafletName: '',
      questionTitle: '',
      text: '',
      video: '',
      sound: '',
      soundText: '',
      images: [],
      images_old: [],
      selectedListing:'list_i',

      writtenWord : "",

      selectedOption:'',
      items : [],
      
      visible_image: false,
      visible_video: false,
      visible_sound: false,
      visible_text: false,
      selectedCover: {
        type: "empty",
        name: "Please Choose"
      },
      coverTypes : [
          {type: "empty", name: "Please Choose"} ,
          {type: "image", name: "Images"} ,
          {type: "video", name: "Video"} ,
          {type: "sound", name: "Sound"},
          {type: "text", name: "Text"}
      ],

    }),

    methods: {


      addList(){

        let groupId = this.randString();

        this.items.push({
          itemId:this.randString(),
          groupId: groupId,
          pre:'',
          post:'',
          options: [],
          selectedOption:''
        });

      },
      deleteOption(itemOptionId){
        
        let k = 0;
        this.items.forEach(e => {
          let i = 0;
          e.options.forEach(o => {
            if(o.itemOptionId == itemOptionId){
              this.items[k].options.splice(i, 1); 
            }
            i++;
          });

          k++;
        });

      },
      deleteImage(imageId){
          let i = 0;
          this.images_old.forEach(res => {

            if(res.imageId == imageId){
              this.images_old.splice(i, 1); 
            }
            console.log(res.image);
            i++;
          });
      },

      changeOption(){

        setTimeout(() => {

          this.items.forEach(e => {

            e.options.forEach(op => {
              if(op.itemOptionId == e.selectedOption){
                op.correct = true; 
              }else{
                op.correct = false; 
              }

            });

          });

        }, 500);

      },
      
      addOption(itemId){
        /* eslint-disable no-unused-vars */
        let i = 0;
        this.items.forEach(e => {

          if(e.itemId == itemId){
              e.options.push({
                itemOptionId: this.randString(),
                value: '',
                correct: false
              });
            }
          
          i++;
        });

      },

      randString() {
          var result = '';
          var characters = 'abcdefghijklmnopqrstuvwxyz';
          var charactersLength = characters.length;
          for ( var i = 0; i < 10; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      
      getData() {

        const data = { questionId : this.$route.params.questionId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/question",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            console.log(response.data);

            this.questionTitle = response.data.questionTitle;
            this.bookId = response.data.bookId;
            this.bookName = response.data.bookName;
            this.unitId = response.data.unitId;
            this.unitName = response.data.unitName;
            this.leafletName = response.data.leafletName;
            this.leafletId = response.data.leafletId;
            this.selectedListing = 'list_'+response.data.listing_symbol;
            this.listingDirection = response.data.listing_type;
            this.selectedCover = response.data.coverType;
            this.changeCover();
            this.video =  response.data.coverVideo;
            this.sound =  response.data.coverSound;
            this.soundText =  response.data.coverSoundText;
            this.text =  response.data.coverText;

            response.data.coverImages.forEach((entry) => {
              this.images_old.push(entry);
            });


            response.data.dropdown_list_items.forEach(e => {

                let selectedOptionId = '';
                let options_temp = [];

                e.options.forEach(op => {
                    let cor = false;
                      if(op.correct == "1"){
                        cor = true;
                        selectedOptionId = op.dropOptionId;
                      }
                      options_temp.push({
                        itemOptionId: op.dropOptionId,
                        value: op.content,
                        correct: cor
                      });
                  });

                this.items.push({
                  itemId: e.dropId,
                  pre : e.text_pre,
                  post:e.text_post,
                  options: options_temp,
                  selectedOption:selectedOptionId
                });
                

                
            });

            


          }  
        );

      },

      validateForm(){
        let res = true;
        
        if(this.questionTitle ==""){res = false}
        if(this.items.length == 0){res = false}

        this.items.forEach(e => {
          console.log(e);
          if(e.options.length == 0){res = false}

          if(e.options.length >= 1){
            let co = 0;
            e.options.forEach(y => {
              if(y.value == ""){res = false}
              if(y.correct){co++;}
            });
            if(co == 0){res = false}
          }

        });

        if(res == false){
          this.$root.Vtoast.show({message: 'Please fill the necessary fields!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
        }

        return res;
      },


      submit () {

        if(this.validateForm()){

          const fd = new FormData();
          fd.append('questionType', "dropdown_list");

          if(this.selectedCover != 'image' && this.selectedCover != 'video' && this.selectedCover != 'sound' && this.selectedCover != 'text'){
            this.selectedCover = 'empty';
          }
          
          fd.append('leafletId', this.leafletId);
          fd.append('questionId', this.questionId);
          fd.append('questionTitle', this.questionTitle);
          fd.append('selectedCover', this.selectedCover);
          fd.append('video', this.video);
          fd.append('sound', this.sound);
          fd.append('soundText', this.soundText);
          fd.append('text', this.text);
          fd.append('listing_symbol', this.selectedListing);

          fd.append('items', JSON.stringify(this.items));
          
          fd.append('images_old', JSON.stringify(this.images_old));


          for( var i = 0; i < this.images.length; i++ ){
            let image = this.images[i];
            fd.append('images[' + i + ']', image);
          }


          axios.post(process.env.VUE_APP_API_DOMAIN + "/question_dropdown_list_save",fd,{ headers: { 'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            
            response => {
              //console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Question is saved successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }
            }  
          );

        
        }

      },
      vtoastCallback() {
        this.$router.push(`/eisadmin/questions/${this.leafletId}`);
      },
      changeCover(){

        this.visible_image = false;
        this.visible_video = false;
        this.visible_sound = false;
        this.visible_text = false;
        
        if(this.selectedCover == "image"){
          this.visible_image = true;
        }
        if(this.selectedCover == "video"){
          this.visible_video = true;
        }
        if(this.selectedCover == "sound"){
          this.visible_sound = true;
        }
        if(this.selectedCover == "text"){
          this.visible_text = true;
        }
      },
      chipColor(used) {
        return used == "1" ? "primary" : "";
      },

      deleteItem(itemId){
        let i = 0;
        this.items.forEach(e => {
          if(e.itemId == itemId){
            this.items.splice(i, 1);
          }
          i++;
        });

      },
      endDrag(temp_items){
        this.items = temp_items
      },


      
      
    },


    created() {

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.questionId = this.$route.params.questionId;
      this.getData();
    },

    watch: {
      

    }
  }
</script>
