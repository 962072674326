<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <div class="row">
        <div class="col-10">
          <v-card elevation="0" outlined>
              <v-card-title>
                <div>STUDENT CODES</div>
                <v-spacer></v-spacer>

                <div style="width:200px">
                  <v-text-field class="ml-0 mr-3 my-0" hide-details outlined dense @keyup="searchCode" v-model="searchKeyword" label="Search"></v-text-field>
                </div>
                <v-btn small color="accent" elevation="0" @click="dialog = !dialog" >CREATE CODE</v-btn>
              </v-card-title>
              <v-card-subtitle>{{this.bookName}}</v-card-subtitle>

              <div v-if="cG != ''" class="pa-2 text-center">
                <v-btn small color="teal accent-4" class="white--text"  elevation="0" @click="download" >DOWNLOAD - {{cG}}</v-btn>
              </div>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="40" class="text-left">#</th>
                      <th width="80" class="text-left">Code</th>
                      <th class="text-left">Owner Student</th>
                      <th class="text-left">Created Date</th>
                      <th class="text-left">Owned Date</th>
                      <th class="text-left">End Date</th>
                      <th class="text-left">Group</th>
                      <th width="180"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in codes" :key="item.codeId" >
                      
                        <td>{{ (page - 1) * 10 + index +1 }}</td>
                        <td>{{item.code}}</td>
                        <td>{{ item.studentName }} </td>
                        <td>{{ item.create_date }}</td>
                        <td>{{ item.owned_date }}</td>
                        <td>{{ item.end_date }}</td>
                        <td>{{ item.codeGroup }}</td>

                        <td>
                          <v-row justify="space-around">
                            <v-btn small text @click="deleteCode(item.codeId, index)">DELETE</v-btn>
                          </v-row>
                        </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-card-actions class="mt-3" >
                <v-spacer></v-spacer>
                <v-pagination v-model="page" :length= this.paginationLenght :total-visible=7 ></v-pagination>
              </v-card-actions>
          </v-card>
        </div>
        <div class="col-2">
          <v-card elevation="0" outlined>
              <div class="text-center font-weight-bold pa-2">GROUPS</div>

              <div class="pa-2" v-for="(item,index) in codeGroups" :key="index">
                <a href="#" class="link_blue" @click="showCodes(item.codeGroup)">{{item.codeGroup}}</a>
              </div>
              
          </v-card>

        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" persistent max-width="600px">
      
      <v-card>
        <v-card-title>
          <span class="text-h5">Create Codes for Students</span>
        </v-card-title>

        <v-card-text>

          <v-combobox
          v-model="howmanygroup"
          :items="codeGroups"
          label="Group"
          outlined
          item-value="codeGroup" 
          item-text="codeGroup"
        ></v-combobox>


        <v-text-field
            label="How many?"
            v-model="howmany"
          ></v-text-field>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="dialog = false" >Close</v-btn>
          <v-btn color="primary darken-1" elevation="0" @click="createCodes()" >Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold , mdiAlertRhombus} from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from './components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold , mdiAlertRhombus },
      }
    },

    data(){
      return {
        searchKeyword: "",
        bookId: this.$route.params.bookId,
        bookName: "",
        codes: [],
        codeGroups: [],
        page: 1,
        paginationLenght : 0,
        dialog: false,
        howmany:0,
        howmanygroup:"",
        cG : ""
      }
    },

    methods: {
      download(){
        const data = { bookId: this.bookId, cG : this.cG  }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/book_codes_student_download",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            console.log(response.data);
            
            const link = document.createElement('a');
            link.href = response.data.fileUrl;
            link.setAttribute('download', 'file.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();

          }  
        );
      },

      searchCode(){   

        console.log(this.searchKeyword);

        if(this.searchKeyword.length >=4){
          this.cG = "";
          this.getData();
        }

        if(this.searchKeyword.length == 0){
          this.page = 1;
          this.codes = [];
          this.getData();
        }

      },

      showCodes(cG){
        this.searchKeyword = "";
        this.page = 1;
        this.cG = cG;
        this.getData();
      },

      getData() {
        
        const data = { page : this.page, bookId: this.bookId, cG : this.cG , searchKeyword: this.searchKeyword }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/book_codes_student",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            console.log(response.data);
            this.codes = response.data.codes;
            this.paginationLenght = Math.ceil(response.data.itemCount / 10);
            this.bookName = response.data.bookName;
          }  
        );
        
        axios.post(process.env.VUE_APP_API_DOMAIN + "/book_codes_student_groups",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            console.log(response.data);
            this.codeGroups = response.data.codeGroups;
            this.dialog = false;
            this.howmanygroup = "";
          }  
        );

      },

      createCodes(){

        if(typeof this.howmanygroup === 'string' || this.howmanygroup instanceof String){
          console.log("string");
        }else{
          this.howmanygroup = this.howmanygroup.codeGroup
        }
        const data = { bookId : this.bookId, howmany : this.howmany, howmanygroup : this.howmanygroup }

        if(this.howmanygroup ==""){
          this.$root.Vtoast.show({message: 'Plese write/select a group!', snackType: 'fail', color: 'error', icon: mdiAlertRhombus})
        }else{
          axios.post(process.env.VUE_APP_API_DOMAIN + "/book_code_student_create",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          () => {
            this.getData()
          }  
        );
        }

        
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },

      async deleteCode(codeId, index) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to delete this code?"
          )
        ){

          const data = { codeId : codeId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/book_student_code_delete",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Code deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.codes.splice(index, 1);
              }
            }  
          );

          
        }
      }

    },

    watch: {
      page: function (newValue, oldValue) {
        if(newValue != oldValue){
          this.getData();
        }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

