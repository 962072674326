<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>CLASSROOM TEACHERS</div>
            <v-spacer></v-spacer>
            <v-btn small color="accent" elevation="0" @click="dialog = !dialog" >ADD TEACHER to CLASSROOM</v-btn>
          </v-card-title>
          <v-card-subtitle>{{schoolName}} - {{className}}</v-card-subtitle>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">Name</th>
                  <th width="200"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in teachers" :key="item.teacherId" >
                  
                    <td>{{ index +1 }}</td>
                    <td>{{ item.teacherName }}</td>
                    <td class="text-end">
                        <v-btn small text @click="deleteTeacher(item.teacherId, index)">DELETE</v-btn>
                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          
      </v-card>
    </div>


    <v-dialog v-model="dialog" persistent max-width="600px">
      
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Teacher to Classroom</span>
        </v-card-title>

        <v-card-text>

          <v-autocomplete
            v-model="teachers_selected"
            :items="teachers_all"
            item-text="teacherName"
            item-value="teacherId"
            deletable-chips
            multiple
            small-chips
            outlined
          ></v-autocomplete>


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="dialog = false" >Close</v-btn>
          <v-btn color="primary darken-1" elevation="0" @click="saveData" >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold  },
      }
    },

    data(){
      return {
        className: "",
        schoolName: "",
        teachers: [],
        teachers_all : [],
        teachers_selected: [],
        dialog: false
        
      }
    },

    methods: {
      getTeachersData() {
        
        const data = { classId : this.$route.params.classId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/class_teachers",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.teachers = response.data.teachers;
            this.paginationLenght = Math.ceil(response.data.itemCount / 10);
            this.className = response.data.className;
            this.schoolName = response.data.schoolName;
          }  
        );

      },

      getTeachers_allData() {
        
        const data = { page : 0 }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/teachers",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.teachers_all = response.data.teachers;
          }  
        );

      },

      saveData(){
        const data = {classId : this.$route.params.classId, teachers : this.teachers_selected }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/class_addteachers",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            if(response.data.result == "success"){
              this.teachers_selected = [];
              this.dialog = false;
              this.getTeachersData();
            }
          }  
        );
      },

      vtoastCallback() {

      },

      async deleteTeacher(teacherId, index) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to delete this teacher?"
          )
        ){

          const data = { teacherId : teacherId, classId : this.$route.params.classId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/class_deleteteacher",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Teacher deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.teachers.splice(index, 1);
              }
            }  
          );

          
        }
      }

    },

    watch: {
      schoolSelect: function (newValue, oldValue) {
        if(newValue != oldValue){ this.getClassData(); }
      },
      dialog: function (newValue) {
        if(newValue == true){ this.getTeachers_allData(); }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getTeachersData();
    }
  }
</script>

