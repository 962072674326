<template>

    <div class="pa-2">

            <div class="text-center mt-5">
                <div style="width:90px; height:90px" class="bg_green mx-auto rounded-circle pt-5">
                    <v-icon class="mt-n1 white--text" size="54">{{icons.mdiCheckOutline }}</v-icon>
                </div>
            </div>

            <div class="f_24 text-center font_bold color_green mt-2">COMPLETED</div>
            <div class="row mt-4">
                <div class="col-md text-md-right text-center">
                    <span class="btn_outline_green border1 rounded-pill px-4 py-2">{{corrects}} Correct</span>
                </div>
                <div class="col-md text-md-left text-center">
                    <span class="btn_outline_pink border1 rounded-pill px-4 py-2">{{wrongs}} Wrong</span>
                </div>
            </div>

            <div class="text-center mt-10">
                <v-btn rounded class="color_white" color="primary" @click="goAfterFinish()" >See Results</v-btn>
            </div>


    </div>
</template>


<script>

import axios from 'axios';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import {  mdiCheckOutline  } from '@mdi/js'

 

export default {
    components: { },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverText : "",
        coverImages : null,
        listing_type: "",
        listing_symbol: "",

        sessionCode : "",
        bookId : 0,

        wrongs : 0,
        corrects : 0,



    }),
    props: {
        questionId: String
    },
    methods: {

        goAfterFinish(){
            this.$router.push('/student/book/'+this.bookId);
        },

        test(){
            console.log(this.dropdown_list_items);
            //this.$emit('makeAllAnswered', true);
            console.log(this.goingData());
        },

        getData(){
            const data = { sessionCode : this.sessionCode }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_finish_answer_session",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {
                    console.log(response.data);

                    this.wrongs = response.data.wrongs;
                    this.corrects = response.data.corrects;

                    this.bookId = response.data.bookId
                }  
            );
        }


    },
    setup() {
        return {
        icons: {mdiCheckOutline },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
        this.sessionCode = this.$route.params.sessionCode;
    },
    mounted() {
        this.getData();
    },
}
</script>