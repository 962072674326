<template>
    <div class="pa-2">
        <div class="text-right mt-3">
            <v-btn elevation="1" class="mr-2" color="info" text-color="white" small @click="correctQuestion()">Correct Answers</v-btn>
            <v-btn elevation="1"  color="primary" small @click="resetQuestion()">Reset</v-btn>
        </div>

        <div class="f_20 font_bold">{{questionOrder}} - {{questionTitle}}</div>
        
        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w"  controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>
        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>

        <div class="mt-2 prglineh row">
            <div class="col-12">
                <span v-for="item in fill_paragraph_items" :key="item.fillId"  >
                    <span  v-if="item.type == 'text'"><span v-html='markup_do(item.text)'></span></span>
                    <v-text-field v-if="item.type == 'empty'" @keyup="writtenChange(item.fillId)" :style="{width: item.inputW + 'px'}"
                        class="input_inline mx-1"
                        single-line hide-details
                        v-model="item.writtenAnswer"
                    ></v-text-field>
                    <div :id="'v'+item.fillId" class="fake_div" >{{item.writtenAnswer}}</div>
                    
                </span>
            </div>
            
        </div>

        <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'

export default {
    mixins: [markup],
    components: { Splide, SplideSlide  },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        coverImages : null,
        fill_paragraph_items: null,
        dialogSound: false
        

    }),
    props: {
        questionId: String
    },
    methods: {

        test(){
            console.log(this.fill_paragraph_items);
        },
        reload() {
            this.$forceUpdate();
        },

        resetQuestion(){

            this.fill_paragraph_items.forEach(e => {
                e.writtenAnswer = "";
            });
            this.reload();


            setTimeout(() => {

                this.fill_paragraph_items.forEach(e => {
                    e.inputW = 60;
                });
                
                this.reload();
            }, 100);


        },

        

        correctQuestion(){

            this.fill_paragraph_items.forEach(e => {
                e.writtenAnswer = e.text;
            });
            this.reload();

            setTimeout(() => {
                this.fill_paragraph_items.forEach(e => {
                    let newW = this.$el.querySelector('#v'+e.fillId).clientWidth  + 35;
                    e.inputW = newW;
                });
                this.reload();
                    
            }, 100);

        },
        

        writtenChange(fillId){

            setTimeout(() => {
            
                let newW = this.$el.querySelector('#v'+fillId).clientWidth + 35;
                console.log(fillId);
                this.fill_paragraph_items.forEach(e => {
                    if(e.fillId == fillId){
                        e.inputW = newW;
                    }
                });

            }, 0);

            this.reload();
            
        },



        showText(pairId){
            let metin = "";
            this.pair_click_paragraph_items.forEach(e => {
                if(pairId == e.pairId){
                    if(e.type == "text"){
                        metin += e.text+' ';
                    }else{
                        metin += '<span class="pair_chip">'+e.answer+'</span> ';
                    }
                }
            });

            return metin;
        },

        getData(){
            const data = { questionId : this.questionId }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_question_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {
                    this.questionOrder = response.data.questionOrder;
                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.coverImages = response.data.coverImages;
                    this.fill_paragraph_items = response.data.fill_paragraph_items;
                    
                    this.fill_paragraph_items.forEach(element => {
                        element.inputW = 16;
                        element.writtenAnswer = "";
                    });


                }  
            );
        }

    },
    setup() {
        return {
        icons: {mdiFormatAlignLeft },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted() {
        console.log(this.questionId);
        this.reload();
        this.getData();
    },
    
}
</script>