<template>
  <div class="bg_login">

    <video id="background-video" autoplay loop muted poster="@/assets/bg.mp4">
      <source src="@/assets/bg.mp4" type="video/mp4">
    </video> 

    
    <div class="auth-wrapper auth-v1 ">
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <div class="auth-inner">
        <v-card class="">

          <div class=" text-center pt-8" ><img src="@/assets/images/EISEnglish_logo.png" width="220"></div>

          <div class="text-center px-2 f_12 mb-10">
            Please register with your name and email address.
          </div>

          <v-card-text>

              <v-radio-group  v-model="userType" class="mt-8 mb-5 " row>
                <v-radio label="Student" value="student"></v-radio>
                <v-radio label="Teacher" value="teacher"></v-radio>
              </v-radio-group>

              <v-text-field v-model="name" outlined label="Name Surname" placeholder="Name Surname" hide-details class="mb-3" ></v-text-field>
              <v-text-field v-model="email" outlined label="Email Address" placeholder="Email Address" hide-details class="mb-3" ></v-text-field>

              <v-text-field v-model="password" outlined :type="isPasswordVisible ? 'text' : 'password'"
                label="Password" placeholder="*******" :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" hide-details @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>

              <v-select v-if="userType == 'teacher'" v-model="schoolSelect" :items="schools" hide-details required item-text="schoolName" item-value="schoolId" class="mt-3"  label="Select School"  outlined  ></v-select>

              <v-btn v-on:click="handleRegister" large block class="mt-4 btn_eis" >Register</v-btn>

              <div class="mt-8 text-center">or</div>

              <v-btn to="/" block text class="mt-1" >Sign In</v-btn>

          </v-card-text>


        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {  mdiEyeOutline, mdiEyeOffOutline, mdiAlertRhombus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Vtoast from '../views/eisadmin/components/Vtoast';
import '@/styles/login.css'

  export default {
    components: {  Vtoast },
    setup() {
      const isPasswordVisible = ref(false)
      const email = ref('')
      const password = ref('')

      return {
        isPasswordVisible,
        email,
        password,
        
        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline,
          mdiAlertRhombus
        },
      }
    },
    data(){
      return{
        name : '',
        email : '',
        password: '',
        schoolSelect: "0",
        userType: 'student',
        schools : []
      }
      
    },
    computed: {},
    methods:{
      vtoastCallback() { 
        console.log("vtoastCallback");
      },

      getDada(){

        axios.post(process.env.VUE_APP_API_DOMAIN + "/schools_public",null
        ).then(
          response => {
            if(response.data.result == "success"){
              this.schools = response.data.schools
            }
          }  

        );

      },
      validEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      handleRegister() {

        const data = {
          name : this.name,
          email : this.email,
          password : this.password,
          userType : this.userType,
          schoolSelect: this.schoolSelect
        }

        if(!this.validEmail(this.email)){
          this.$root.Vtoast.show({message: 'Please enter a valid email address!', snackType: 'fail', color: 'error', icon: mdiAlertRhombus});
        }else{

          if(this.email == "" || this.password == "" || this.name == ""){
            this.$root.Vtoast.show({message: 'Please fill all fields!', snackType: 'fail', color: 'error', icon: mdiAlertRhombus});
          }else{

            if(this.userType == "teacher" && this.schoolSelect == "0"){
                this.$root.Vtoast.show({message: 'Please select a school!', snackType: 'fail', color: 'error', icon: mdiAlertRhombus})
            }else{

                axios.post(process.env.VUE_APP_API_DOMAIN + "/register",JSON.stringify(data)
                ).then(
                  response => {

                    console.log(response.data);
                    if(response.data.result == "success"){

                      this.$store.commit('setTokenLogin', {
                        accessToken : response.data.accesstoken,
                        refreshToken : response.data.refreshtoken,
                        userGroup : response.data.user_group
                      });

                      this.$store.commit('setUid', response.data.uid);
                      this.$store.commit('setName', response.data.name);
                      this.$store.commit('setInitials', response.data.initials);
                      this.$store.commit('setUserGroup', response.data.user_group);

                      if(response.data.user_group == "student"){
                        this.$router.push('/student');
                      }else if(response.data.user_group == "teacher"){
                        this.$router.push('/teacher/classes');
                      }else{
                        this.$router.push('/');
                      }

                    }else{
                      this.$root.Vtoast.show({message: 'This email is already used! Please try with another email address!', snackType: 'fail', color: 'error', icon: mdiAlertRhombus})
                    }
                  }  

                );
              
            }

          

          }

        }

        

      }
    },
    created() {
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.getDada();
    },
  }
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
