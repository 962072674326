import Vue from "vue";
import VueRouter from "vue-router";
import store from './store/index'


import Home from './views/Home';
import About from './views/About';
import Admin from './views/Admin';
import Register from './views/Register';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';


// admin panel components
import admin_Dashboard from './views/eisadmin/Dashboard';
import admin_Logout from './views/eisadmin/Logout';


    //books
    import admin_Books from './views/eisadmin/Books';
    import admin_Book_Add from './views/eisadmin/Book_Add';
    import admin_Book_Edit from './views/eisadmin/Book_Edit';
    import admin_Book_Teacher_Codes from './views/eisadmin/Book_Teacher_Codes';
    import admin_Book_Student_Codes from './views/eisadmin/Book_Student_Codes';

    //archived_books
    import admin_Archived_Books from './views/eisadmin/Archived_Books';
    

    //units
    import admin_Units from './views/eisadmin/Units';
    import admin_Unit_Add from './views/eisadmin/Unit_Add';
    import admin_Unit_Edit from './views/eisadmin/Unit_Edit';

    //users
    import admin_Users from './views/eisadmin/Users';
    import admin_User_Add from './views/eisadmin/User_Add';
    import admin_User_Edit from './views/eisadmin/User_Edit';

    //leaflets
    import admin_Leaflets from './views/eisadmin/Leaflets';
    import admin_Leaflet_Add from './views/eisadmin/Leaflet_Add';
    import admin_Leaflet_Edit from './views/eisadmin/Leaflet_Edit';


    //materials
    import admin_Materials from './views/eisadmin/Materials';
    import admin_Material_Add from './views/eisadmin/Material_Add';
    import admin_Material_Edit from './views/eisadmin/Material_Edit';
    

    //questions
    import admin_Questions from './views/eisadmin/Questions';
    

    import admin_Question_pair_click_paragraph_Add from './views/eisadmin/Question_pair_click_paragraph_Add';
    import admin_Question_pair_click_list_Add from './views/eisadmin/Question_pair_click_list_Add';
    import admin_Question_pair_click_list_Add2 from './views/eisadmin/Question_pair_click_list_Add2';
    import admin_Question_fill_paragraph_Add from './views/eisadmin/Question_fill_paragraph_Add';
    import admin_Question_fill_list_Add from './views/eisadmin/Question_fill_list_Add';
    import admin_Question_order_words_Add from './views/eisadmin/Question_order_words_Add';
    import admin_Question_dropdown_paragraph_Add from './views/eisadmin/Question_dropdown_paragraph_Add';
    import admin_Question_dropdown_list_Add from './views/eisadmin/Question_dropdown_list_Add';
    import admin_Question_multiple_choice_Add from './views/eisadmin/Question_multiple_choice_Add';
    import admin_Question_true_false_Add from './views/eisadmin/Question_true_false_Add';
    import admin_Question_free_write_Add from './views/eisadmin/Question_free_write_Add';
    import admin_Question_group_words_Add from './views/eisadmin/Question_group_words_Add';
    import admin_Question_fill_table_Add from './views/eisadmin/Question_fill_table_Add';
    import admin_Question_images_click_Add from './views/eisadmin/Question_images_click_Add';
    import admin_Question_images_fill_Add from './views/eisadmin/Question_images_fill_Add';

    import admin_Question_pair_click_paragraph_Edit from './views/eisadmin/Question_pair_click_paragraph_Edit';
    import admin_Question_pair_click_list_Edit from './views/eisadmin/Question_pair_click_list_Edit';
    import admin_Question_pair_click_list2_Edit from './views/eisadmin/Question_pair_click_list2_Edit';
    import admin_Question_fill_paragraph_Edit from './views/eisadmin/Question_fill_paragraph_Edit';
    import admin_Question_fill_list_Edit from './views/eisadmin/Question_fill_list_Edit';
    import admin_Question_fill_table_Edit from './views/eisadmin/Question_fill_table_Edit';
    import admin_Question_order_words_Edit from './views/eisadmin/Question_order_words_Edit';
    import admin_Question_group_words_Edit from './views/eisadmin/Question_group_words_Edit';
    import admin_Question_dropdown_paragraph_Edit from './views/eisadmin/Question_dropdown_paragraph_Edit';
    import admin_Question_dropdown_list_Edit from './views/eisadmin/Question_dropdown_list_Edit';
    import admin_Question_multiple_choice_Edit from './views/eisadmin/Question_multiple_choice_Edit';
    import admin_Question_true_false_Edit from './views/eisadmin/Question_true_false_Edit';
    import admin_Question_free_write_Edit from './views/eisadmin/Question_free_write_Edit';
    import admin_Question_images_fill_Edit from './views/eisadmin/Question_images_fill_Edit';
    import admin_Question_images_click_Edit from './views/eisadmin/Question_images_click_Edit';





    //schools
    import admin_Schools from './views/eisadmin/Schools';
    import admin_School_Add from './views/eisadmin/School_Add';
    import admin_School_Edit from './views/eisadmin/School_Edit';
    import admin_School_Classes from './views/eisadmin/School_Classes';

    //classes
    import admin_Classes from './views/eisadmin/Classes';
    import admin_Class_Edit from './views/eisadmin/Class_Edit';
    import admin_Class_Add from './views/eisadmin/Class_Add';
    import admin_Class_Teachers from './views/eisadmin/Class_Teachers';
    import admin_Class_Students from './views/eisadmin/Class_Students';

    //teachers
    import admin_Teachers from './views/eisadmin/Teachers';
    import admin_Teacher_Edit from './views/eisadmin/Teacher_Edit';
    import admin_Teacher_Add from './views/eisadmin/Teacher_Add';
    import admin_Teacher_Classes from './views/eisadmin/Teacher_Classes';
    import admin_Teacher_Schools from './views/eisadmin/Teacher_Schools';
    import admin_Teacher_Books from './views/eisadmin/Teacher_Books';

    //students
    import admin_Students from './views/eisadmin/Students';
    import admin_Student_Edit from './views/eisadmin/Student_Edit';
    import admin_Student_Add from './views/eisadmin/Student_Add';
    import admin_Student_Classes from './views/eisadmin/Student_Classes';
    import admin_Student_Books from './views/eisadmin/Student_Books';
    


// student panel components
import student_Dashboard from './views/student/Dashboard';
import student_Book from './views/student/Book';
import student_Unit from './views/student/Unit';
import student_Leaflet from './views/student/Leaflet';
import student_Messages from './views/student/Messages';
import student_MessageUser from './views/student/MessageUser';
import student_Logout from './views/student/Logout';
import student_Settings from './views/student/Settings';
import approve_Email from './views/student/ApproveEmail';

// teacher panel components
import teacher_Library from './views/teacher/Library';
import teacher_Classes from './views/teacher/Classes';
import teacher_Class from './views/teacher/Class';
import teacher_ClassUnit from './views/teacher/ClassUnit';
import teacher_BookUnit from './views/teacher/BookUnit';
import teacher_Leaflet from './views/teacher/Leaflet';
import teacher_BookLeaflet from './views/teacher/BookLeaflet';
import teacher_Settings from './views/teacher/Settings';
import teacher_Messages from './views/teacher/Messages';
import teacher_MessageUser from './views/teacher/MessageUser';
import teacher_Logout from './views/teacher/Logout';
import teacher_Homework_Results from './views/teacher/Homework_Results';
import teacher_Student_Results from './views/teacher/Student_Results';
import approve_Email_Teacher from './views/teacher/ApproveEmail';



import teacher_Book from './views/teacher/Book';


Vue.use(VueRouter);

const routes = [

    //website
    { name: "Home", component : Home,  path : '/',  meta: {permission: 'all'}  },
    { name: "About", component : About, path : '/about',  meta: {permission: 'all'} },
    { name: "Admin", component : Admin, path : '/admin',  meta: {permission: 'all'} },
    { name: "Register", component : Register, path : '/register',  meta: {permission: 'all'} },
    { name: "ForgotPassword", component : ForgotPassword, path : '/forgot_password',  meta: {permission: 'all'} },
    { name: "ResetPassword", component : ResetPassword, path : '/reset_password/:md',  meta: {permission: 'all'} },

    //admin
    { name: "admin_Dashboard", component : admin_Dashboard, path : '/eisadmin', meta: {permission: 'admin'} },
    { name: "admin_Logout", component : admin_Logout, path : '/eisadmin/logout', meta: {permission: 'admin'} },

        //users
        { name: "admin_Users", component : admin_Users, path : '/eisadmin/users', meta: {permission: 'admin'} },
        { name: "admin_User_Add", component : admin_User_Add, path : '/eisadmin/user_add', meta: {permission: 'admin'} },
        { name: "admin_User_Edit", component : admin_User_Edit, path : '/eisadmin/user_edit/:uid', meta: {permission: 'admin'} },


        //books
        { name: "admin_Books", component : admin_Books, path : '/eisadmin/books', meta: {permission: 'admin'} },
        { name: "admin_Book_Add", component : admin_Book_Add, path : '/eisadmin/book_add', meta: {permission: 'admin'} },
        { name: "admin_Book_Edit", component : admin_Book_Edit, path : '/eisadmin/book_edit/:bookId', meta: {permission: 'admin'} },
        { name: "admin_Book_Teacher_Codes", component : admin_Book_Teacher_Codes, path : '/eisadmin/book_teacher_codes/:bookId', meta: {permission: 'admin'} },
        { name: "admin_Book_Student_Codes", component : admin_Book_Student_Codes, path : '/eisadmin/book_student_codes/:bookId', meta: {permission: 'admin'} },

        //archived books
        { name: "admin_Archived_Books", component : admin_Archived_Books, path : '/eisadmin/archivedbooks', meta: {permission: 'admin'} },
        
        //units
        { name: "admin_Units", component : admin_Units, path : '/eisadmin/units/:bookId', meta: {permission: 'admin'} },
        { name: "admin_Unit_Add", component : admin_Unit_Add, path : '/eisadmin/unit_add/:bookId', meta: {permission: 'admin'} },
        { name: "admin_Unit_Edit", component : admin_Unit_Edit, path : '/eisadmin/unit_edit/:unitId', meta: {permission: 'admin'} },

        //leaflets
        { name: "admin_Leaflets", component : admin_Leaflets, path : '/eisadmin/leaflets/:unitId', meta: {permission: 'admin'} },
        { name: "admin_Leaflet_Add", component : admin_Leaflet_Add, path : '/eisadmin/leaflet_add/:unitId', meta: {permission: 'admin'} },
        { name: "admin_Leaflet_Edit", component : admin_Leaflet_Edit, path : '/eisadmin/leaflet_edit/:leafletId', meta: {permission: 'admin'} },

        //materials
        { name: "admin_Materials", component : admin_Materials, path : '/eisadmin/materials/:bookId', meta: {permission: 'admin'} },
        { name: "admin_Material_Add", component : admin_Material_Add, path : '/eisadmin/material_add/:bookId', meta: {permission: 'admin'} },
        { name: "admin_Material_Edit", component : admin_Material_Edit, path : '/eisadmin/material_edit/:materialId', meta: {permission: 'admin'} },


        //questions
        { name: "admin_Questions", component : admin_Questions, path : '/eisadmin/questions/:leafletId', meta: {permission: 'admin'} },
        
        { name: "admin_Question_pair_click_paragraph_Add", component : admin_Question_pair_click_paragraph_Add, path : '/eisadmin/question_pair_click_paragraph_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_pair_click_list_Add", component : admin_Question_pair_click_list_Add, path : '/eisadmin/question_pair_click_list_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_pair_click_list_Add2", component : admin_Question_pair_click_list_Add2, path : '/eisadmin/question_pair_click_list_add2/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_fill_paragraph_Add", component : admin_Question_fill_paragraph_Add, path : '/eisadmin/question_fill_paragraph_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_fill_list_Add", component : admin_Question_fill_list_Add, path : '/eisadmin/question_fill_list_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_order_words_Add", component : admin_Question_order_words_Add, path : '/eisadmin/question_order_words_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_dropdown_paragraph_Add", component : admin_Question_dropdown_paragraph_Add, path : '/eisadmin/question_dropdown_paragraph_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_dropdown_list_Add", component : admin_Question_dropdown_list_Add, path : '/eisadmin/question_dropdown_list_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_multiple_choice_Add", component : admin_Question_multiple_choice_Add, path : '/eisadmin/question_multiple_choice_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_true_false_Add", component : admin_Question_true_false_Add, path : '/eisadmin/question_true_false_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_free_write_Add", component : admin_Question_free_write_Add, path : '/eisadmin/question_free_write_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_group_words_Add", component : admin_Question_group_words_Add, path : '/eisadmin/question_group_words_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_fill_table_Add", component : admin_Question_fill_table_Add, path : '/eisadmin/question_fill_table_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_images_click_Add", component : admin_Question_images_click_Add, path : '/eisadmin/question_images_click_add/:leafletId', meta: {permission: 'admin'} },
        { name: "admin_Question_images_fill_Add", component : admin_Question_images_fill_Add, path : '/eisadmin/question_images_fill_add/:leafletId', meta: {permission: 'admin'} },
        
        { name: "admin_Question_pair_click_paragraph_Edit", component : admin_Question_pair_click_paragraph_Edit, path : '/eisadmin/question_pair_click_paragraph_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_pair_click_list_Edit", component : admin_Question_pair_click_list_Edit, path : '/eisadmin/question_pair_click_list_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_pair_click_list2_Edit", component : admin_Question_pair_click_list2_Edit, path : '/eisadmin/question_pair_click_list2_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_fill_paragraph_Edit", component : admin_Question_fill_paragraph_Edit, path : '/eisadmin/question_fill_paragraph_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_fill_list_Edit", component : admin_Question_fill_list_Edit, path : '/eisadmin/question_fill_list_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_fill_table_Edit", component : admin_Question_fill_table_Edit, path : '/eisadmin/question_fill_table_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_order_words_Edit", component : admin_Question_order_words_Edit, path : '/eisadmin/question_order_words_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_group_words_Edit", component : admin_Question_group_words_Edit, path : '/eisadmin/question_group_words_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_dropdown_paragraph_Edit", component : admin_Question_dropdown_paragraph_Edit, path : '/eisadmin/question_dropdown_paragraph_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_dropdown_list_Edit", component : admin_Question_dropdown_list_Edit, path : '/eisadmin/question_dropdown_list_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_multiple_choice_Edit", component : admin_Question_multiple_choice_Edit, path : '/eisadmin/question_multiple_choice_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_true_false_Edit", component : admin_Question_true_false_Edit, path : '/eisadmin/question_true_false_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_free_write_Edit", component : admin_Question_free_write_Edit, path : '/eisadmin/question_free_write_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_images_fill_Edit", component : admin_Question_images_fill_Edit, path : '/eisadmin/question_images_fill_edit/:questionId', meta: {permission: 'admin'} },
        { name: "admin_Question_images_click_Edit", component : admin_Question_images_click_Edit, path : '/eisadmin/question_images_click_edit/:questionId', meta: {permission: 'admin'} },
        
        

        //schools
        { name: "admin_School_Add", component : admin_School_Add, path : '/eisadmin/school_add', meta: {permission: 'admin'} },
        { name: "admin_School_Edit", component : admin_School_Edit, path : '/eisadmin/school_edit/:schoolId', meta: {permission: 'admin'} },
        { name: "admin_Schools", component : admin_Schools, path : '/eisadmin/schools', meta: {permission: 'admin'} },
        { name: "admin_School_Classes", component : admin_School_Classes, path : '/eisadmin/school_classes/:schoolId', meta: {permission: 'admin'} },


        //classes
        { name: "admin_Class_Add", component : admin_Class_Add, path : '/eisadmin/class_add', meta: {permission: 'admin'} },
        { name: "admin_Classes", component : admin_Classes, path : '/eisadmin/classes', meta: {permission: 'admin'} },
        { name: "admin_Class_Edit", component : admin_Class_Edit, path : '/eisadmin/class_edit/:classId', meta: {permission: 'admin'} },
        { name: "admin_Class_Teachers", component : admin_Class_Teachers, path : '/eisadmin/class_teachers/:classId', meta: {permission: 'admin'} },
        { name: "admin_Class_Students", component : admin_Class_Students, path : '/eisadmin/class_students/:classId', meta: {permission: 'admin'} },

        //teachers
        { name: "admin_Teachers", component : admin_Teachers, path : '/eisadmin/teachers', meta: {permission: 'admin'} },
        { name: "admin_Teacher_Add", component : admin_Teacher_Add, path : '/eisadmin/teacher_add', meta: {permission: 'admin'} },
        { name: "admin_Teacher_Edit", component : admin_Teacher_Edit, path : '/eisadmin/teacher_edit/:teacherId', meta: {permission: 'admin'} },
        { name: "admin_Teacher_Classes", component : admin_Teacher_Classes, path : '/eisadmin/teacher_classes/:teacherId', meta: {permission: 'admin'} },
        { name: "admin_Teacher_Schools", component : admin_Teacher_Schools, path : '/eisadmin/teacher_schools/:teacherId', meta: {permission: 'admin'} },
        { name: "admin_Teacher_Books", component : admin_Teacher_Books, path : '/eisadmin/teacher_books/:teacherId', meta: {permission: 'admin'} },

        //students
        { name: "admin_Students", component : admin_Students, path : '/eisadmin/students', meta: {permission: 'admin'} },
        { name: "admin_Student_Add", component : admin_Student_Add, path : '/eisadmin/student_add', meta: {permission: 'admin'} },
        { name: "admin_Student_Edit", component : admin_Student_Edit, path : '/eisadmin/student_edit/:studentId', meta: {permission: 'admin'} },
        { name: "admin_Student_Classes", component : admin_Student_Classes, path : '/eisadmin/student_classes/:studentId', meta: {permission: 'admin'} },
        { name: "admin_Student_Books", component : admin_Student_Books, path : '/eisadmin/student_books/:studentId', meta: {permission: 'admin'} },
        

    //student
    { name: "student_Dashboard", component : student_Dashboard, path : '/student', meta: {permission: 'student'} },
    { name: "student_Book", component : student_Book, path : '/student/book/:bookId/:tab?/:leafletId?', meta: {permission: 'student'} },
    { name: "student_Unit", component : student_Unit, path : '/student/unit/:bookId/:unitId', meta: {permission: 'student'} },
    { name: "student_Leaflet", component : student_Leaflet, path : '/student/leaflet/:sessionCode', meta: {permission: 'student'} },
    { name: "student_Messages", component : student_Messages, path : '/student/messages', meta: {permission: 'student'} },
    { name: "student_MessageUser", component : student_MessageUser, path : '/student/message/:teacherId', meta: {permission: 'student'} },
    { name: "student_Logout", component : student_Logout, path : '/student/logout', meta: {permission: 'student'} },
    { name: "student_Settings", component : student_Settings, path : '/student/settings', meta: {permission: 'student'} },
    { name: "approve_Email", component : approve_Email, path : '/student/approve_email', meta: {permission: 'student'} },

    //teacher
    { name: "teacher_Library", component : teacher_Library, path : '/teacher/library', meta: {permission: 'teacher'} },
    { name: "teacher_Classes", component : teacher_Classes, path : '/teacher/classes', meta: {permission: 'teacher'} },
    { name: "teacher_Class", component : teacher_Class, path : '/teacher/class/:classId', meta: {permission: 'teacher'} },
    { name: "teacher_ClassUnit", component : teacher_ClassUnit, path : '/teacher/unit/:classId/:bookId/:unitId', meta: {permission: 'teacher'} },
    { name: "teacher_Leaflet", component : teacher_Leaflet, path : '/teacher/leaflet/:bookId/:classId/:unitId/:leafletId', meta: {permission: 'teacher'} },
    { name: "teacher_Homework_Results", component : teacher_Homework_Results, path : '/teacher/homework_results/:homeworkId', meta: {permission: 'teacher'} },
    { name: "teacher_Student_Results", component : teacher_Student_Results, path : '/teacher/student_results/:classId/:bookId/:studentId', meta: {permission: 'teacher'} },
    { name: "teacher_Settings", component : teacher_Settings, path : '/teacher/settings', meta: {permission: 'teacher'} },
    { name: "teacher_Messages", component : teacher_Messages, path : '/teacher/messages', meta: {permission: 'teacher'} },
    { name: "teacher_MessageUser", component : teacher_MessageUser, path : '/teacher/message/:studentId', meta: {permission: 'teacher'} },
    { name: "teacher_Logout", component : teacher_Logout, path : '/teacher/logout', meta: {permission: 'teacher'} },
    { name: "approve_Email_Teacher", component : approve_Email_Teacher, path : '/teacher/approve_email', meta: {permission: 'teacher'} },
    
    { name: "teacher_Book", component : teacher_Book, path : '/teacher/book/:bookId', meta: {permission: 'teacher'} },
    { name: "teacher_BookUnit", component : teacher_BookUnit, path : '/teacher/book_unit/:bookId/:unitId', meta: {permission: 'teacher'} },
    { name: "teacher_BookLeaflet", component : teacher_BookLeaflet, path : '/teacher/book_leaflet/:bookId/:unitId/:leafletId', meta: {permission: 'teacher'} },


];

const router = new VueRouter({

    mode: 'history',
    base: '',
    routes
    
});


const loginRoute = '/';

router.beforeEach(async (to, from, next) => {

    const user_group = store.getters.getUserGroup;


    if( to.meta.permission != "all" && user_group != to.meta.permission){
        next({ path: loginRoute });
    }else{
        next();
    }
    

    
    
    
});

export default router;

