    
export default {
    data () {
        return {
            msg: "Hello World"
        }
    },

    methods: {
        markup_do(ne){
            
            ne = ne.replace(/(\*\*|__)(?=\S)([^\r]*?\S[*_]*)\1/g, '<strong>$2</strong>');
            ne = ne.replace(/(\*|_)(?=\S)([^\r]*?\S)\1/g, '<em>$2</em>');
            ne = ne.replace(/`([^`]+)`/g, '<code>$1</code>');

            return ne ; 
        }
    }
}