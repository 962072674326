<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>QUESTIONS</div>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small color="accent" elevation="0" v-bind="attrs" v-on="on">NEW QUESTION</v-btn>
              </template>

              <div class="bg_white pa-4" style="width:450px;">

                <div class="row ">
                  <div class="col-4 pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_pair_click_paragraph_add')">Match Parag.</v-btn>
                  </div>
                  <div class="col-4 pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_pair_click_list_add')">Match List</v-btn>
                  </div>
                  <div class="col-4 pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_pair_click_list_add2')">Match List2</v-btn>
                  </div>
                </div>

                <div class="row mt-2 ">
                  <div class="col-6 pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_fill_paragraph_add')">Fill Parag.</v-btn>
                  </div>
                  <div class="col-6 pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_fill_list_add')">Fill List</v-btn>
                  </div>
                </div>

                <div class="row mt-2 ">
                  <div class="col pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_fill_table_add')">Fill Table</v-btn>
                  </div>
                </div>

                <div class="row mt-2 ">
                  <div class="col pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_order_words_add')">Order Words</v-btn>
                  </div>
                </div>
                
                <div class="row mt-2">
                  <div class="col pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_group_words_add')">Group Words</v-btn>
                  </div>
                </div>

                <div class="row mt-2 ">
                  <div class="col-6 pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_dropdown_paragraph_add')">Drop Parag.</v-btn>
                  </div>
                  <div class="col-6 pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_dropdown_list_add')">Drop List</v-btn>
                  </div>
                </div>

                <div class="row mt-2 ">
                  <div class="col pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_multiple_choice_add')">Multiple Choice</v-btn>
                  </div>
                </div>

                <div class="row mt-2 ">
                  <div class="col pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_true_false_add')">True / False</v-btn>
                  </div>
                </div>

                <div class="row mt-2 ">
                  <div class="col pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_free_write_add')">Free Writing</v-btn>
                  </div>
                </div>

                <div class="row mt-2 ">
                  <div class="col-6 pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_images_fill_add')">Img Fill</v-btn>
                  </div>
                  <div class="col-6 pa-1 ma-0">
                    <v-btn small outlined block @click="question_add('question_images_click_add')">Img Match</v-btn>
                  </div>
                </div>

              </div>

            </v-menu>

          </v-card-title>
          <v-card-subtitle>
            <a href="/eisadmin/books" class="link_blue">Books</a> > 
            <a :href="'/eisadmin/units/'+bookId" class="link_blue">{{bookName}}</a> > 
            <a :href="'/eisadmin/leaflets/'+unitId" class="link_blue">{{unitName}}</a> > 
            {{leafletName}}</v-card-subtitle>
          
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left"></th>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">Question</th>
                  <th class="text-left">Type</th>
                  <th class="text-left">Point</th>
                  <th width="200"></th>
                </tr>
              </thead>

                <draggable :list="questions" tag="tbody" @end="endDrag(questions)" >
                <tr v-for="(item, index) in questions" :key="item.questionId" >
                    <td><v-icon class="dragIcon">{{icons.mdiDrag}}</v-icon></td>
                    <td>{{ index +1 }}</td>
                    <td>
                      <div class="font-weight-black">{{ item.questionTitle }}</div>
                    </td>
                    <td>
                      <div>{{ item.questionType }}</div>
                    </td>
                    <td>
                      <div>{{ item.point }}</div>
                    </td>

                    <td>
                      <v-row justify="space-around">
                        <v-btn small text @click="deleteQuestion(item.questionId, index)">DELETE</v-btn>

                        <v-btn v-if="item.questionType == 'pair_click_paragraph'" small text :href="'/eisadmin/question_pair_click_paragraph_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'pair_click_list'" small text :href="'/eisadmin/question_pair_click_list_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'pair_click_list2'" small text :href="'/eisadmin/question_pair_click_list2_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'fill_paragraph'" small text :href="'/eisadmin/question_fill_paragraph_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'fill_list'" small text :href="'/eisadmin/question_fill_list_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'fill_table'" small text :href="'/eisadmin/question_fill_table_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'order_words'" small text :href="'/eisadmin/question_order_words_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'dropdown_paragraph'" small text :href="'/eisadmin/question_dropdown_paragraph_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'dropdown_list'" small text :href="'/eisadmin/question_dropdown_list_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'multiple_choice'" small text :href="'/eisadmin/question_multiple_choice_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'true_false'" small text :href="'/eisadmin/question_true_false_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'group_words'" small text :href="'/eisadmin/question_group_words_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'free_write'" small text :href="'/eisadmin/question_free_write_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'images_click'" small text :href="'/eisadmin/question_images_click_edit/' + item.questionId">EDIT</v-btn>
                        <v-btn v-if="item.questionType == 'images_fill'" small text :href="'/eisadmin/question_images_fill_edit/' + item.questionId">EDIT</v-btn>
                        
                      
                      </v-row>
                    </td>
                </tr>
                </draggable>

            </template>
          </v-simple-table>

      </v-card>
    </div>

  </v-app>
</template>

<style >
  
</style>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold,mdiDrag } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';
  import draggable from 'vuedraggable';
  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm ,draggable},

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold ,mdiDrag },
      }
    },

 

    data(){
      return {
        leafletId : this.$route.params.leafletId,
        bookId: '',
        unitId: '',
        bookName: '',
        unitName: '',
        leafletName: '',
        questions: [],
        

      }
    },
    methods: {
      getData() {
        
        const data = { leafletId : this.$route.params.leafletId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/questions",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.questions = response.data.questions;
            this.bookId = response.data.bookId;
            this.unitId = response.data.unitId;
            this.bookName = response.data.bookName;
            this.unitName = response.data.unitName;
            this.leafletName = response.data.leafletName;

          }  
        );

      },
      
      endDrag(temp_items){
        this.questions = temp_items
        console.log(this.questions);

        const data = { leafletId : this.$route.params.leafletId, questions : this.questions }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/questions_setorder",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } }).then(
            response => {
              console.log(response.data);
            }  
          );

      },

      question_add(url){
        this.$router.push('/eisadmin/'+url+"/"+this.$route.params.leafletId);
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },

      async deleteQuestion(questionId, index) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to delete this question?"
          )
        ){

          const data = { questionId : questionId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/question_delete",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Question deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.questions.splice(index, 1);
              }
            }  
          );

          
        }
      }

    },

    watch: {
      page: function (newValue, oldValue) {
        if(newValue != oldValue){
          this.getData();
        }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

