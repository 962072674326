<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>MATERIALS</div>
            <v-spacer></v-spacer>
            <v-btn small color="accent" elevation="0" :href="'/eisadmin/material_add/'+this.$route.params.bookId" >NEW MATERIAL</v-btn>
          </v-card-title>
          <v-card-subtitle><a href="/eisadmin/books" class="link_blue">Books</a> > {{bookName}}</v-card-subtitle>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left"></th>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Type</th>
                  <th class="text-left">File</th>
                  <th width="200"></th>
                </tr>
              </thead>


                <draggable :list="materials" tag="tbody" @end="endDrag(materials)" >

                  <tr v-for="(item, index) in materials" :key="item.materialId" >
                    
                      <td><v-icon class="dragIcon">{{icons.mdiDrag}}</v-icon></td>
                      <td>{{ index +1 }}</td>
                      <td>{{ item.materialName }} <v-chip small v-if="item.teacherOnly == '1'" color="green" text-color="white">for teachers</v-chip></td>
                      <td>{{ item.materialType }}</td>
                      <td><a :href="item.materialFile" target="_blank">{{ item.materialFile }}</a></td>
                      <td>
                        <v-row justify="space-around">
                          <v-btn small text @click="deleteMaterial(item.materialId, index)">DELETE</v-btn>
                          <v-btn small text :href="'/eisadmin/material_edit/' + item.materialId">EDIT</v-btn>
                        </v-row>
                      </td>
                  </tr>

                </draggable>

            </template>
          </v-simple-table>

      </v-card>
    </div>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold,mdiDrag  } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';
  import draggable from 'vuedraggable';


  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm, draggable },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold ,mdiDrag },
      }
    },

    data(){
      return {
        bookId : this.$route.params.bookId,
        bookName: '',
        materials: []
      }
    },
    methods: {
      getData() {
        
        const data = { bookId : this.$route.params.bookId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/materials",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.materials = response.data.materials;
            this.bookName = response.data.bookName;

          }  
        );

      },

      endDrag(temp_materials){
        let new_order = [];
        let i = 1;
        temp_materials.forEach(e => {
          const _u = {
            materialId: e.materialId,
            orderNo: i
          }
          new_order.push(_u);
          i++;
        });

        console.log(new_order);

        const data = { materialOrders : new_order }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/material_reorder",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } });
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },

      async deleteMaterial(materialId, index) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to delete this material?"
          )
        ){

          const data = { materialId : materialId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/material_delete",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Material deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.materials.splice(index, 1);
              }
            }  
          );

          
        }
      }

    },

    watch: {
      page: function (newValue, oldValue) {
        if(newValue != oldValue){
          this.getData();
        }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

<style>
  .dragIcon{
    cursor: grab;
  }
</style>
