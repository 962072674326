<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P">
      <v-container class="pt-0">

        
        <div class="mt-5">
          <a :href="'/teacher/book/'+bookId" class="mt-4 link_blue2">
              <v-icon class="mt-n1 link_blue2" >{{icons.mdiChevronLeft  }}</v-icon>Back
          </a>
        </div>


        <BookDetails 
            :bookCode="bookCode" 
            :bookCodeDates="bookCodeDates" 
            :bookCover="bookCover" 
            :bookName="bookName" 
            :bookSubtitle="bookSubtitle" 
            :bookDescription="bookDescription" 
            />

        <div class="mt-8">

          <div class="tabs_border pb-1 f_24 font_bold  mb-4">
            <v-icon size="32">{{icons.mdiTextBoxOutline }}</v-icon>  {{unitName}}
          </div>

          <v-simple-table v-for="item in leaflets" :key="item.leafletId">
            <template v-slot:default>
              <tbody>
                <tr >

                  <td width="70" class="text-right  d-none d-sm-table-cell">
                    <v-img :src="require('@/assets/images/quizIcon.png')" max-width="70px" class="ma-1"></v-img>
                  </td>

                  <td class="tabs_border">
                    <div class="f_18 font_bold ">{{ item.leafletName }} </div>
                    <div class="f_12 color_muted mt-1">{{ limitText(item.leafletDescription,80) }}</div>
                  </td>

                  <td width="130" class=" tabs_border  d-none d-sm-table-cell">
                    {{ item.questionCount }}  questions
                  </td>

                  <td width="100" class="text-right tabs_border">
                    <v-menu  bottom left offset-y  max-width="200">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" icon v-bind="attrs" v-on="on">
                          <v-icon>{{icons.mdiDotsVertical}}</v-icon>
                        </v-btn>
                      </template>
                      <v-list >
                        <div class="mx-2"><v-btn text block :href="'/teacher/book_leaflet/'+bookId+'/'+unitId+'/'+item.leafletId" >QUESTIONS</v-btn></div>
                      </v-list>
                    </v-menu>
                  </td>

                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </div>





            




      </v-container>
      </div>

  </v-app>

  

</template>

<style >
  
</style>
 
<script>
  import checkAuthTeacher from '../../mixins/checkAuthTeacher';
  import TopBar from '../../views/teacher/components/TopBar'
  import BookDetails from '../../views/teacher/components/BookDetails'

  import {  mdiBookOpenPageVariant,mdiPlusCircleOutline,mdiAccountGroup ,mdiDotsVertical ,mdiChevronRight,
  mdiRecordCircleOutline ,mdiTextBoxOutline,mdiChevronLeft     } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/teacher/components/Vtoast';
  import Confirm from './components/Confirm.vue';

  export default {
    mixins: [checkAuthTeacher],
    components: {
      TopBar,  Vtoast, Confirm, BookDetails
    },
    setup() {
      return {

        icons: {
          mdiBookOpenPageVariant,
          mdiPlusCircleOutline ,
          mdiAccountGroup ,
          mdiDotsVertical ,
          mdiChevronRight,
          mdiRecordCircleOutline ,
          mdiTextBoxOutline ,
          mdiChevronLeft
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',

      classId:0,
      bookId:0,
      unitId:0,
      unitName:"",
      schoolName:"",
      className:"",
      classKey:"",
      bookName:"",
      bookSubtitle:"",
      bookCover: "",
      bookDescription: "",
      bookCode: "",
      bookCodeDates: "",
      leaflets:[],

      selectedLeaflet : 0,


    }),
    methods:{

      limitText(text,len){
        return  text.length > len ? text.substring(0, len - 3) + "..." : text;
      },


      openDateModal(leafletId){
        this.dialogDates = !this.dialogDates; 
        this.selectedLeaflet = leafletId

      },

      saveDates(){
        const data2 = { 
          leafletId : this.selectedLeaflet,
          dateEnd : this.dateEnd,
          hourEnd : this.hourEnd,
          minuteEnd : this.minuteEnd,
          classId : this.classId
        };

        console.log(data2);

        axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_leafletdate_save",JSON.stringify(data2)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          () => {
            this.dialogDates = false;
            this.getData();
          }  
        );

      },


      getData(){

          const data = { bookId : this.bookId, unitId: this.unitId  }

          console.log(data);
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_book_unit",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log("response.data");
              console.log(response.data);
              this.bookId = response.data.bookId;
              this.bookName = response.data.bookName;
              this.bookSubtitle = response.data.bookSubtitle;
              this.bookCover = response.data.bookCover;
              this.bookDescription = response.data.bookDescription;
              this.bookCode = response.data.bookCode;
              this.bookCodeDates = response.data.bookCodeDates;
              this.leaflets = response.data.leaflets;
              this.className = response.data.className; 
              this.unitName = response.data.unitName; 
              this.classKey = response.data.classKey; 
              this.schoolName = response.data.schoolName; 
            }  
          );

          
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },


    },
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN
      this.uid = this.$store.getters.getUid;
      this.bookId = this.$route.params.bookId;
      this.unitId = this.$route.params.unitId;

      console.log(this.unitId);
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    },
    

  }
</script>