<template>
  <div class="leaflet_header_border  bg_header_leaflet">
    <v-card  tile flat color="transparent" >
      <v-container class="pa-0 " >
        <v-toolbar color="transparent" class="text-center " flat>
          <v-spacer></v-spacer>
          <a href="/student" >
          <v-img :src="require('@/assets/images/EISEnglish_logoWhite.png')" max-width="155px" class=""></v-img>
          </a>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-container>
    </v-card>
  </div>

</template>

<script>
import {   } from '@mdi/js'

export default {
  data: () => ({
      valid: true,
      name : '',
      initials: '',
      page : ""
  }),
  methods: {




    handleDrawer() {

      if(this.$store.getters.getAdminDrawer){
        this.$store.commit('setAdminDrawer', false);
      }else{
        this.$store.commit('setAdminDrawer', true);
      }

    },
    checkDrawer(){
      //console.log("checkDrawer");
      //console.log(this.$store.getters.getAdminDrawer);
      //console.log(this.$store.getters.getUserGroup);
    }
  },
  setup() {
    return {
      icons: {   },
    }
  },
  created () {
    this.initials =  this.$store.getters.getInitials;
    this.name =  this.$store.getters.getName;

  },
}
</script>

