<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />

    <div class="pa-5" >

      <v-row class="mx-0" >
        <div>
          <div class="f_18 font-weight-bold">CREATE FREE WRITE QUESTION</div>
          <div class="f_14 color_muted">
            <a href="/eisadmin/books" class="link_blue">Books</a> > 
            <a :href="'/eisadmin/units/'+bookId" class="link_blue">{{bookName}}</a> > 
            <a :href="'/eisadmin/leaflets/'+unitId" class="link_blue">{{unitName}}</a> > 
            <a :href="'/eisadmin/questions/'+leafletId" class="link_blue">{{this.leafletName}}</a>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn :to="'/eisadmin/questions/'+leafletId" color="error" small elevation="0" class="mb-2 mr-2 mt-2" >CANCEL</v-btn>
        <v-btn @click="submit" color="success" small elevation="0" class="mb-2 mt-2" >SAVE</v-btn>
      </v-row >

      
      <v-row >
        <v-col cols="12" sm="9">

            <v-card elevation="0" outlined>
              <div class="pa-5"> 
                <v-form ref="form" v-model="valid" lazy-validation >
                  <v-text-field outlined v-model="questionTitle"  label="Question Text"  ></v-text-field>
                  <v-select v-model="selectedCover" @change="changeCover" :items="coverTypes"  item-text="name" item-value="type" class="mt-2"  label="Cover Types"  outlined  ></v-select>
                  <v-file-input v-model="images" v-if="visible_image" outlined multiple small-chips   accept="image/png, image/jpeg" placeholder="Choose Images" label="Cover Images" name ="Cover Images" ></v-file-input>
                  <v-text-field outlined v-model="video" v-if="visible_video"  label="Video Url"  placeholder="https://" ></v-text-field>
                  <v-text-field outlined v-model="sound" v-if="visible_sound"  label="Sound Url"  placeholder="https://"></v-text-field>
                  <v-textarea outlined v-model="soundText" v-if="visible_sound"  label="Sound Text"  placeholder=""></v-textarea>
                  <v-textarea outlined v-model="text" v-if="visible_text"  label="Text Paragraph" ></v-textarea>
                </v-form>
              </div>
            </v-card>

        </v-col>

        <v-col cols="12" sm="3">

          <v-card elevation="0" outlined >  
            <v-card-title primary-title>RICH TEXT</v-card-title>
            <div class="mx-5">Rich texts can be used for text covers, question texts. Do not use it for answer areas.</div>
            <div class="mx-5"> 

              <v-simple-table>
                <template v-slot:default>
                  
                  <tbody>
                    <tr>
                      <td>**text**</td>
                      <td><strong>BOLD</strong></td>
                    </tr>
                    <tr>
                      <td>*text*</td>
                      <td><em>ITALIC</em></td>
                    </tr>
                    <tr>
                      <td>`text`</td>
                      <td><code>CODE</code></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </div>
          </v-card> 

        </v-col>

      </v-row>

    </div>

</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiGithub, mdiAlertRhombus, mdiCheckBold, mdiDelete  } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline, mdiGithub,mdiDelete },
      }
    },
    
    data: () => ({
      valid: true,
      leafletId : '',
      unitId: '',
      bookId: '',
      bookName: '',
      unitName: '',
      leafletName: '',
      questionTitle: '',
      text: '',
      video: '',
      sound: '',
      soundText: '',
      images: [],


      visible_image: false,
      visible_video: false,
      visible_sound: false,
      visible_text: false,
      selectedCover: {
        type: "empty",
        name: "Please Choose"
      },
      coverTypes : [
          {type: "empty", name: "Please Choose"} ,
          {type: "image", name: "Images"} ,
          {type: "video", name: "Video"} ,
          {type: "sound", name: "Sound"},
          {type: "text", name: "Text"}
      ],
      answerTypes : ['true','false'],
      


    }),

    methods: {


      randString() {
          var result = '';
          var characters = 'abcdefghijklmnopqrstuvwxyz';
          var charactersLength = characters.length;
          for ( var i = 0; i < 10; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      getData() {
        
        const data = { leafletId : this.$route.params.leafletId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/leaflet",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.bookId = response.data.bookId;
            this.bookName = response.data.bookName;
            this.unitId = response.data.unitId;
            this.unitName = response.data.unitName;
            this.leafletName = response.data.leaflet.leafletName;
          }  
        );

      },
      validateForm(){
        let res = true;
        
        if(this.questionTitle ==""){res = false}
        
        if(res == false){
          this.$root.Vtoast.show({message: 'Please fill the necessary fields!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
        }

        return res;
      },
      submit () {

        if(this.validateForm()){

          const fd = new FormData();

          fd.append('questionType', "free_write");

          if(this.selectedCover != 'image' && this.selectedCover != 'video' && this.selectedCover != 'sound' && this.selectedCover != 'text'){
            this.selectedCover = 'empty';
          }
          
          fd.append('leafletId', this.leafletId);
          fd.append('questionTitle', this.questionTitle);
          fd.append('selectedCover', this.selectedCover);
          fd.append('video', this.video);
          fd.append('sound', this.sound);
          fd.append('soundText', this.soundText);
          fd.append('text', this.text);

          for( var i = 0; i < this.images.length; i++ ){
            let image = this.images[i];
            fd.append('images[' + i + ']', image);
          }

          axios.post(process.env.VUE_APP_API_DOMAIN + "/question_free_write_add",fd,{ headers: { 'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            
            response => {
              //console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Question is added successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }
            }  
          );
        }

      },

      vtoastCallback() {
        this.$router.push(`/eisadmin/questions/${this.leafletId}`);
      },
      changeCover(){

        this.visible_image = false;
        this.visible_video = false;
        this.visible_sound = false;
        this.visible_text = false;
        
        if(this.selectedCover == "image"){
          this.visible_image = true;
        }
        if(this.selectedCover == "video"){
          this.visible_video = true;
        }
        if(this.selectedCover == "sound"){
          this.visible_sound = true;
        }
        if(this.selectedCover == "text"){
          this.visible_text = true;
        }
      },


      
      
    },


    created() {

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.leafletId = this.$route.params.leafletId;
      this.getData();
    },

    watch: {
      

    }
  }
</script>
