<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P">
      <v-container class="pt-0 color_normal" >

        <div class="mt-5">
          <a href="/teacher/library" class="mt-4 link_blue2">
              <v-icon class="mt-n1 link_blue2" >{{icons.mdiChevronLeft  }}</v-icon>Back
          </a>
        </div>


        <v-tabs v-model="tab" class="mt-10 tabs_border">
          <v-tab @click="tabshow('tabBookdetails')">Book Details</v-tab>
          <v-tab @click="tabshow('tabMaterials')">Materials</v-tab>
        </v-tabs>


        <div v-if="activetab == 'tabBookdetails'">

            <BookDetails 
            :bookCode="bookCode" 
            :bookCodeDates="bookCodeDates" 
            :bookCover="bookCover" 
            :bookName="bookName" 
            :bookSubtitle="bookSubtitle" 
            :bookDescription="bookDescription" 
            />

            <div class="mt-8 pb-5">

                <v-simple-table v-for="item in units" :key="item.unitId">
                  <template v-slot:default>
                    <tbody>
                      <tr @click="gotoUnit(item.unitId)" style="cursor: pointer;" >
                        <td width="70" class="text-right  d-none d-sm-table-cell">
                          <v-img :src="require('@/assets/images/quizIcon.png')" max-width="70px" class="ma-1"></v-img>
                        </td>
                        <td class="tabs_border">
                          <div class="f_18 font_bold ">{{ item.unitName }}</div>
                          <div class="f_12 color_muted">{{ limitText(item.unitDescription,80) }}</div>
                        </td>
                        <td class="text-right tabs_border">
                          <v-icon>{{icons.mdiChevronRight}}</v-icon>
                        </td>
                      </tr>

                    </tbody>
                  </template>
                </v-simple-table>

            </div>

        </div>


        <div v-if="activetab == 'tabMaterials'">
          <TabMaterials :bookId="bookId" />
        </div>

      </v-container>
      </div>

      
  </v-app>

  

</template>

<style >
  
</style>
 
<script>
  import checkAuthTeacher from '../../mixins/checkAuthTeacher';
  import TopBar from '../../views/teacher/components/TopBar'
  import BookDetails from '../../views/teacher/components/BookDetails'
  import TabMaterials from '../../views/teacher/components/TabMaterials.vue'
  import {  mdiBookOpenPageVariant,mdiPlusCircleOutline,mdiAccountGroup ,mdiDotsVertical ,mdiChevronRight,
  mdiRecordCircleOutline ,mdiEmailOutline ,mdiChevronLeft    } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/teacher/components/Vtoast';
  import Confirm from './components/Confirm.vue';

  export default {
    mixins: [checkAuthTeacher],
    components: {
      TopBar,  Vtoast, Confirm, TabMaterials , BookDetails 
    },
    setup() {
      return {

        icons: {
          mdiBookOpenPageVariant,
          mdiPlusCircleOutline ,
          mdiAccountGroup ,
          mdiDotsVertical ,
          mdiChevronRight,
          mdiRecordCircleOutline,
          mdiEmailOutline,
          mdiChevronLeft 
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',

      classId:0,
      schoolName:"",
      className:"",
      classKey:"",
      bookId:0,
      bookName:"",
      bookSubtitle:"",
      bookCover: "",
      bookDescription: "",
      bookCode: "",
      bookCodeDates : "",
      units:[],

      selectedLeaflet : 0,

      
      activetab: 'tabBookdetails',



    }),
    methods:{

      tabshow(gl){
        this.activetab = gl;
      },

      

      findClass4Leaflet(statu){
        console.log(statu);
        let res = "grey lighten-2";
        if(statu == "finished"){
          res = "primary";
        }
        if(statu == "started"){
          res = "green";
        }
        return res
      },

      limitText(text,len){
        return  text.length > len ? text.substring(0, len - 3) + "..." : text;
      },

      gotoUnit(unitId){
        this.$router.push('/teacher/book_unit/'+this.bookId+'/'+unitId);

      },



      saveDates(){
        console.log(this.selectedLeaflet);
        console.log(this.dateStart);
        console.log(this.dateEnd);

        const data2 = { 
          leafletId : this.selectedLeaflet,
          dateStart : this.dateStart,
          hourStart : this.hourStart,
          minuteStart : this.minuteStart,
          dateEnd : this.dateEnd,
          hourEnd : this.hourEnd,
          minuteEnd : this.minuteEnd,
          classId : this.classId
        }

        axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_leafletdate_save",JSON.stringify(data2)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          () => {
            this.dialogDates = false;
            this.getData();
          }  
        );

      },


      getData(){

          const data = { bookId : this.bookId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_book",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              this.bookId = response.data.bookId;
              this.bookName = response.data.bookName;
              this.bookSubtitle = response.data.bookSubtitle;
              this.bookCover = response.data.bookCover;
              this.bookDescription = response.data.bookDescription;
              this.bookCode = response.data.bookCode;
              this.bookCodeDates = response.data.bookCodeDates;
              this.units = response.data.units;

            }  
          );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
      },


    },
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN
      this.uid = this.$store.getters.getUid;
      this.bookId = this.$route.params.bookId;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    },
    

  }
</script>