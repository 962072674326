<template>

    <div class="pa-2">




        <div class="f_20 font_bold">{{questionOrder}} - {{questionTitle}}</div>

        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" id="vid" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w"  controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>
        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>

        <div style="overflow-x:auto; max-width: 90vw;">
        <table  class="table_border mt-5" cellpadding="0" cellspacing="0">
            <tr  v-for="r in row" :key='r'>
                <td :class="classFinder(r-1,c-1)" v-for="c in column" :key='c'>
                    <div v-if="isText(r-1,c-1)"><span v-html='markup_do(findContent(r-1,c-1))'></span></div>    
                    <div v-if="isAnswer(r-1,c-1)">
                        <v-chip outlined  class="ma-1" :color="findColor(corrects[(r-1).toString()+(c-1).toString()])"  ><span class="color_black">{{answers[(r-1).toString()+(c-1).toString()]}}</span></v-chip>
                        
                    </div>    
                </td>
            </tr>
        </table>
        </div>


        <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'

export default {
    mixins: [markup],
    components: { Splide, SplideSlide  },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        coverImages : null,
        isTopTitle: "",
        isLeftTitle: "",
        fill_table_items: null,
        row: 0,
        column:0,

        answers_correct : [],
        answers : [],
        corrects : [],



        dialogSound: false

    }),
    props: {
        questionId: String,
        homeworkId: String,
        studentId: String,
    },
    methods: {

        test(){
            console.log(this.answers_correct);
        },

        stopVideo(){
            document.getElementById("vid").pause();
        },

        findColor(crr){
            let res = "pink";
            if(crr == "1"){
                res = "green";
            }
            return res
        },

        reload() {
            this.$forceUpdate();
        },

        correctQuestion(){
            this.answers = [];
            this.answers_correct.forEach((e,index) => {
                this.answers[index] = e
            });
            this.reload();
        },

        resetQuestion(){
            this.answers = [];
            this.answers_correct.forEach((e,index) => {
                this.answers[index] = ""
            });
            this.reload();
        },

        classFinder(r,c){
            let cls = "table_border pa-2";
            if(this.isTopTitle == "1" && r == 0){
                cls = "table_border pa-2 table_bg_selected";
            }
            if(this.isLeftTitle == "1" && c == 0){
                cls = "table_border pa-2 table_bg_selected";
            }
            //console.log(c);
            return cls;
        },

        isAnswer(r,c){
            let res = false;
            this.fill_table_items.forEach(e => {
                if(r == e.row && c == e.colomn){
                    if(e.answer == "1"){
                        res = true;
                    }
                }
            });
            return res;
        },
        isText(r,c){
            let res = false;
            this.fill_table_items.forEach(e => {
                if(r == e.row && c == e.colomn){
                    if(e.answer == "0"){
                        res = true;
                    }
                }
            });
            return res;
        },

        findContent(r,c){
            let metin = "";
            
            this.fill_table_items.forEach(e => {
                if(r == e.row && c == e.colomn){
                    if(e.answer == "1"){
                        metin = e.text;
                    }else{
                        metin = e.text;
                    }
                    
                }
            });

            return metin;
        },

        getData(){
            const data = { questionId : this.questionId ,homeworkId : this.homeworkId , studentId : this.studentId }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_homework_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {



                    this.questionOrder = response.data.questionOrder;
                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.coverImages = response.data.coverImages;
                    this.isTopTitle = response.data.isTopTitle;
                    this.isLeftTitle = response.data.isLeftTitle;
                    this.fill_table_items = response.data.fill_table_items;
                    this.row = response.data.row;
                    this.column = response.data.column;

                    this.fill_table_items.forEach(e => {
                        if(e.answer == "1"){
                            let ind = e.row + e.colomn
                            
                            this.answers_correct[ind] = e.text;
                            this.answers[ind] = e.writtenAnswer;
                            this.corrects[ind] = e.correct;
                        }
                    });
                    

                    
                }  
            );
        }

    },
    setup() {
        return {
        icons: { mdiFormatAlignLeft},
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted() {
        this.getData();
    },
}
</script>