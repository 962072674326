<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P">
      <v-container class="pt-0">

        
        <div class="mt-5">
          <a :href="'/teacher/class/'+classId" class="mt-4 link_blue2">
              <v-icon class="mt-n1 link_blue2" >{{icons.mdiChevronLeft  }}</v-icon>Back
          </a>
        </div>

        <ClassDetails :classStartDate="classStartDate" :classEndDate="classEndDate" :className="className" :schoolName="schoolName" :classKey="classKey"/>


        <BookDetails 
          :bookCode="bookCode" 
          :bookCodeDates="bookCodeDates" 
          :bookCover="bookCover" 
          :bookName="bookName" 
          :bookSubtitle="bookSubtitle" 
          :bookDescription="bookDescription" 
          />

        <div class="mt-8">

          <div class="tabs_border pb-1 f_24 font_bold  mb-4">
            <v-icon size="32">{{icons.mdiTextBoxOutline }}</v-icon> {{name}} results
          </div>

            <v-data-table :headers="headers" :items="homeworks" item-key="studentName" >
                <template v-slot:item="row">
                    <tr>
                        <td class="font_bold">{{ row.item.unitName }} > {{ row.item.leafletName }}</td>
                        <td>
                          <v-chip v-if="row.item.sessionStatus == 'finished'" color="primary" class="font_regular" small>finished</v-chip>
                          <v-chip v-if="row.item.sessionStatus == 'started'" color="warning" class="font_regular" small>started</v-chip>
                          <v-chip v-if="row.item.sessionStatus == 'failed'" color="red" class="font_regular" small>failed</v-chip>
                        </td>
                        <td>{{ row.item.correctPoint }}</td>
                        <td>{{ row.item.wrongPoint }}</td>
                        <td>{{row.item.successPoint}}</td>
                        <td>
                          <div >{{row.item.startDate }}</div>
                          <div class="  ">{{row.item.duration }} Min.</div>
                        </td>
                        <td>
                          <v-btn v-if="row.item.sessionStatus == 'finished'" @click="openDateModal(row.item.studentId, row.item.homeworkId, row.item.leafletId, row.item.unitId)"  small elevation="0" color="info">Details</v-btn>
                        </td>
                        
                    </tr>
                </template>
            </v-data-table>



        </div>



        <v-dialog v-model="dialogResults" max-width="600px">
          <v-card>
            <v-card-title class="text-h5">
              {{selectedStudentName}}
              <v-spacer></v-spacer>
              <v-btn color="grey" small text @click="dialogResults = false" >Close</v-btn>  
            </v-card-title>
            <v-card-text>
                <div style="height:400px; overflow-y:auto; overflow-x:hidden ">
                  <Pair_click_paragraph v-if="whichQuestionType('pair_click_paragraph')" ref="childQ" :key="componentKey" :homeworkId="homeworkId" :questionId="selectedQuestionId" :studentId="selectedStudentId" />
                  <Pair_click_list2 v-if="whichQuestionType('pair_click_list2')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Pair_click_list v-if="whichQuestionType('pair_click_list')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Fill_paragraph v-if="whichQuestionType('fill_paragraph')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Fill_list v-if="whichQuestionType('fill_list')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Fill_table v-if="whichQuestionType('fill_table')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Order_words v-if="whichQuestionType('order_words')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Group_words v-if="whichQuestionType('group_words')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Dropdown_paragraph v-if="whichQuestionType('dropdown_paragraph')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Dropdown_list v-if="whichQuestionType('dropdown_list')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Multiple_choice v-if="whichQuestionType('multiple_choice')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <True_false v-if="whichQuestionType('true_false')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Free_write v-if="whichQuestionType('free_write')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Images_fill v-if="whichQuestionType('images_fill')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                  <Images_click v-if="whichQuestionType('images_click')" ref="childQ" :key="componentKey" :homeworkId="homeworkId"  :questionId="selectedQuestionId" :studentId="selectedStudentId"/>
                </div>
    
            </v-card-text>
            <v-card-actions class="text-center" >
              <div>
                <span  v-for="(question, index) in questions" :key="question.questionId">
                <v-chip v-if="question.questionId != selectedQuestionId " class="mr-1 mt-1" @click="selectQuestion(question.questionId)">{{ index+1 }}</v-chip>
                <v-chip v-if="question.questionId == selectedQuestionId " class="mr-1 mt-1" text-color="white" color="green" @click="selectQuestion(question.questionId)">{{ index+1 }}</v-chip>
                </span>
              </div>

            </v-card-actions>
          </v-card>
        </v-dialog>



      </v-container>
      </div>

  </v-app>

  

</template>

<style >
  
</style>
 
<script>
  import checkAuthTeacher from '../../mixins/checkAuthTeacher';
  import TopBar from '../../views/teacher/components/TopBar';
  import BookDetails from '../../views/teacher/components/BookDetails';
  import ClassDetails from '../../views/teacher/components/ClassDetails';
  import {  mdiBookOpenPageVariant,mdiPlusCircleOutline,mdiAccountGroup ,mdiDotsVertical ,mdiInformationOutline,mdiChevronRight,
  mdiRecordCircleOutline ,mdiTextBoxOutline,mdiChevronLeft     } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/teacher/components/Vtoast';
  import Confirm from './components/Confirm.vue';

  import Pair_click_paragraph from '../../views/teacher/components_question_results/Pair_click_paragraph';
  import Pair_click_list from '../../views/teacher/components_question_results/Pair_click_list';
  import Pair_click_list2 from '../../views/teacher/components_question_results/Pair_click_list2';
  import Fill_paragraph from '../../views/teacher/components_question_results/Fill_paragraph';
  import Fill_list from '../../views/teacher/components_question_results/Fill_list';
  import Fill_table from '../../views/teacher/components_question_results/Fill_table';
  import Order_words from '../../views/teacher/components_question_results/Order_words';
  import Group_words from '../../views/teacher/components_question_results/Group_words';
  import Dropdown_paragraph from '../../views/teacher/components_question_results/Dropdown_paragraph';
  import Dropdown_list from '../../views/teacher/components_question_results/Dropdown_list';
  import Multiple_choice from '../../views/teacher/components_question_results/Multiple_choice';
  import True_false from '../../views/teacher/components_question_results/True_false';
  import Free_write from '../../views/teacher/components_question_results/Free_write';
  import Images_fill from '../../views/teacher/components_question_results/Images_fill';
  import Images_click from '../../views/teacher/components_question_results/Images_click';

  export default {
    mixins: [checkAuthTeacher],
    components: {
      TopBar,  Vtoast, Confirm, BookDetails, ClassDetails, 
      Pair_click_paragraph, Pair_click_list, Fill_paragraph, Fill_list, Fill_table, Order_words, Group_words , Dropdown_paragraph,
      Dropdown_list, Multiple_choice, True_false, Free_write, Images_fill, Images_click, Pair_click_list2
    },
    setup() {
      return {

        icons: {
          mdiBookOpenPageVariant,
          mdiPlusCircleOutline ,
          mdiAccountGroup ,
          mdiDotsVertical ,
          mdiInformationOutline ,
          mdiChevronRight,
          mdiRecordCircleOutline ,
          mdiTextBoxOutline ,
          mdiChevronLeft
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',

      headers: [
            { text: 'Leaflet', value: 'unitName' },
            { text: 'Status', value: 'sessionStatus'  },
            { text: 'Correct', value: 'correctPoint' },
            { text: 'Wrong', value: 'wrongPoint' },
            { text: 'Success%', value: 'successPoint'  },
            { text: 'Dates', value: 'startDate',sortable: false },
            { text: 'Actions', value: 'actions', sortable: false },
      ],

      studentId:0,
      leafletId:0,
      leafletName:"",
      classId:0,
      bookId:0,
      unitId:0,
      unitName:"",
      schoolName:"",
      className:"",
      classKey:"",
      classStartDate:"",
      classEndDate:"",
      bookName:"",
      bookSubtitle:"",
      bookCover: "",
      bookCode: "",
      bookCodeDates: "",
      bookDescription: "",
      homeworks:[],
      homeworkId : "",
      componentKey: 0,
      dialogResults: false,
      questions : [],

      selectedStudentId : 0,
      selectedStudentName : "",
      selectedQuestionId : 0,

    }),
    methods:{

      calculateSuccess(correctPoint,wrongPoint){
            let res = "";
            if(parseInt(wrongPoint) == 0 ){
                res = "%0";
            }else{
                res = "%" + Math.round(100* parseInt(correctPoint) / (parseInt(correctPoint)+parseInt(wrongPoint) ));
            }
            return res;
        },

      

      selectQuestion(id){
        this.reload();
        this.selectedQuestionId = id
      },

      reload() {
        this.componentKey += 1;  
        this.$forceUpdate();
      },

      whichQuestionType(typ){
        let res = false;
        this.questions.forEach(e => {
          if(e.questionId == this.selectedQuestionId){
            if(e.questionType == typ){
              res = true;
            } 
          }
        });

        return res;
      },

      limitText(text,len){
        return  text.length > len ? text.substring(0, len - 3) + "..." : text;
      },

      openDateModal(studentId,homeworkId, leafletId, unitId){

        this.selectedStudentId = studentId;
        this.homeworkId = homeworkId;
        this.leafletId = leafletId;
        this.unitId = unitId;
        this.selectedStudentName = this.name;


        
        const data = { bookId : this.bookId, unitId: this.unitId, leafletId: this.leafletId , classId: this.classId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_leaflet",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {

            this.questions = response.data.questions;
            this.selectedQuestionId = this.questions[0].questionId;
            this.dialogResults = !this.dialogResults; 


          }  
        );
        
        
        

      },



      getData(){

          const data = { studentId : this.studentId, classId : this.classId , bookId : this.bookId   }

          console.log(data);
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_student_results",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              
              
              this.unitId = response.data.unitId;
              this.leafletId = response.data.leafletId;
              this.bookName = response.data.bookName;
              this.bookSubtitle = response.data.bookSubtitle;
              this.bookCover = response.data.bookCover;
              this.bookCode = response.data.bookCode;
              this.bookCodeDates = response.data.bookCodeDates;
              this.bookDescription = response.data.bookDescription;
              this.className = response.data.className; 
              this.unitName = response.data.unitName; 
              this.classKey = response.data.classKey; 
              this.schoolName = response.data.schoolName; 
              this.name = response.data.studentName; 

              this.classStartDate = response.data.classStartDate; 
              this.classEndDate = response.data.classEndDate;

              this.homeworks = response.data.homeworks;

            }  
          );

          
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },


    },
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN
      this.uid = this.$store.getters.getUid;
      this.classId = this.$route.params.classId;
      this.studentId = this.$route.params.studentId;
      this.bookId = this.$route.params.bookId;

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    },

    watch:{
        dialogResults:function(newValue){
            if(!newValue){
              this.$refs.childQ.stopVideo();
            }
        }
    }
    

  }
</script>