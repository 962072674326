<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>TEACHER BOOKS</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-subtitle><a href="/eisadmin/teachers" class="link_blue">Teachers</a> > {{teacherName}}</v-card-subtitle>
          


          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="40" class="text-left">#</th>
                  <th width="80" class="text-left">Cover</th>
                  <th class="text-left">Book Name</th>
                  <th class="text-left">Book Status</th>
                  <th class="text-left">Used Code</th>
                  <th class="text-left">Code Dates</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in books" :key="item.bookId" >
                  
                    <td>{{ index +1 }}</td>
                    <td><v-img :src="VUE_APP_BOOK_MEDIA+item.bookCover" contain width="60" /></td>
                    <td>{{ item.bookName }} - {{ item.bookSubtitle }} </td>
                    <td>{{item.codeStatus}}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.teacher_code_start }} - {{ item.teacher_code_end }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          
      </v-card>
    </div>




  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold  },
      }
    },

    data(){
      return {
        VUE_APP_API_DOMAIN : "",
        VUE_APP_BOOK_MEDIA : process.env.VUE_APP_BOOK_MEDIA,
        teacherId: 0,
        teacherName: "",
        books: [],
      }
    },

    methods: {

      selectedC(cc){
        this.classSelected = cc
      },

      getData() {
        
        const data = { teacherId : this.$route.params.teacherId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/teacher_books",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.books = response.data.books;
            this.teacherName = response.data.teacherName;
          }  
        );

      },




      vtoastCallback() {
        //this.getTeachersData();
      },


    },

    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN
    },

    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open

      this.teacherId = this.$route.params.teacherId;
      this.getData();
    }
  }
</script>

