<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>ADMIN USERS</div>
            <v-spacer></v-spacer>
            <v-btn small color="accent" elevation="0" :href="'/eisadmin/user_add/'" >NEW USER</v-btn>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">Name Surname</th>
                  <th class="text-left">Email Address</th>
                  <th class="text-left">User Type</th>
                  <th width="200"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in users" :key="item.uid" >
                  
                    <td>{{ index +1 }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.authority }}</td>
                    <td class="text-right">
                        <v-btn v-if="item.authority == 'user'" small text @click="deleteUser(item.uid, index)">DELETE</v-btn>
                        <v-btn small text :href="'/eisadmin/user_edit/'+ item.uid">EDIT</v-btn>
                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
      </v-card>
    </div>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold  },
      }
    },

    data(){
      return {
        users: [],
      }
    },

    methods: {
      getData() {
        
        const data = {}
        axios.post(process.env.VUE_APP_API_DOMAIN + "/users",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.users = response.data.users;
          }  
        );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },

      async deleteUser(uid, index) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to delete this user?"
          )
        ){

          const data = { uid : uid }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/user_delete",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'User deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.users.splice(index, 1);
              }
            }  
          );

          
        }
      }

    },

    watch: {
      page: function (newValue, oldValue) {
        if(newValue != oldValue){
          this.getData();
        }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

