<template>
    <v-snackbar :color="color" :timeout="timer" v-model="showSnackbar" bottom right>
        <v-icon left color="white">{{icon}}</v-icon> {{message}}
    </v-snackbar>
</template>

<script>
    import { mdiCheckBold, mdiAlertRhombus   } from '@mdi/js'

    export default {
        name: "vtoast",
        data() {
            return{
                showSnackbar: false,
                snackType : 'success',
                message: '',
                color: 'success',
                icon: mdiCheckBold ,
                timer: 3000,
                callbackFunction : null
            }
        },
        props: {
            callBack: Function,
        },
        methods:{
            show(data) {
                this.message = data.message || 'missing "message".';
                this.color = data.color || 'success';
                this.timer = data.timer || 2000;
                this.icon = data.icon || mdiAlertRhombus;  
                this.showSnackbar = true;
                if(data.snackType == "success"){
                    setTimeout(() => this.callBack(), 1000);
                }
                
                
            }
        }
    }
</script>