<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P">
        <v-container class="pt-0 pb-12">

            <div class="d-flex flex-row mt-8">
              <v-icon size="30" class="black--text mr-3 mt-2" > {{ icons.mdiEmailOutline }}</v-icon>
              <div class="f_24 font_bold pt-1 black--text "> Messages</div>
            </div>

            <v-simple-table  class="mt-8">
              <template v-slot:default>
                <tbody >
                  <tr v-for="item in messageUsers" :key="item.studentId">
                    <td class="tabs_border font_medium">
                      {{item.studentName}}
                    </td>
                    <td class=" tabs_border">
                      {{item.className}}
                    </td>
                    <td width="100" class="text-right tabs_border">
                      <v-btn :to="'/teacher/message/'+item.studentId" small elevation="0" color="info">messages</v-btn>
                    </td>
                  </tr>

                </tbody>
              </template>
            </v-simple-table>

              <div v-if="messageUsers.length == 0" class="mt-8 text-center">
                <div class="font_bold f_24">No message found!</div>
                <div>You can send messages to your students. </div>
              </div>

              

        </v-container>
      </div>

  </v-app>

</template>
 
<script>
  import checkAuthTeacher from '../../mixins/checkAuthTeacher';
  import TopBar from '../../views/teacher/components/TopBar'
  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup , mdiMagnify ,mdiEmailOutline  } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/teacher/components/Vtoast';
  import Confirm from '../../views/teacher/components/Confirm.vue';

  export default {
    mixins: [checkAuthTeacher],
    components: {
      TopBar,  Vtoast, Confirm
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,
          mdiMagnify,
          mdiEmailOutline
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',
      dialogClass: false,
      dialogKey: false,

      teacherName: '',
      teacherEmail: '',

      messageUsers : []
      
      

    }),
    methods:{



      getData(){
          const data = { }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_message_users",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              this.messageUsers = response.data.users;
            }  
          );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },


    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    },
    

  }
</script>