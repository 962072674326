
<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />

    <div class="pa-5" >

      <v-row class="mx-0" >
        <div>
          <div class="f_18 font-weight-bold">CREATE DROPDOWN PARAGRAPH QUESTION</div>
          <div class="f_14 color_muted">
            <a href="/eisadmin/books" class="link_blue">Books</a> > 
            <a :href="'/eisadmin/units/'+bookId" class="link_blue">{{bookName}}</a> > 
            <a :href="'/eisadmin/leaflets/'+unitId" class="link_blue">{{unitName}}</a> > 
            <a :href="'/eisadmin/questions/'+leafletId" class="link_blue">{{this.leafletName}}</a>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn :to="'/eisadmin/questions/'+leafletId" color="error" small elevation="0" class="mb-2 mr-2 mt-2" >CANCEL</v-btn>
        <v-btn @click="submit" color="success" small elevation="0" class="mb-2 mt-2" >SAVE</v-btn>
      </v-row >

      <v-row >
        <v-col cols="12" sm="9">
            <v-card elevation="0" outlined>
              <div class="pa-5"> 
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field outlined v-model="questionTitle"   label="Question"  ></v-text-field>
                  <v-select v-model="selectedCover" @change="changeCover" :items="coverTypes"  item-text="name" item-value="type" class="mt-2"  label="Cover Types"  outlined  ></v-select>

                  <v-file-input v-model="images" v-if="visible_image" outlined multiple small-chips  accept="image/png, image/jpeg" placeholder="Choose Images" label="Cover Images" name ="Cover Images" ></v-file-input>
                  <v-text-field outlined v-model="video" v-if="visible_video"  label="Video Url"  placeholder="https://" ></v-text-field>
                  <v-text-field outlined v-model="sound" v-if="visible_sound" label="Sound Url"  placeholder="https://"></v-text-field>
                  <v-textarea outlined v-model="soundText" v-if="visible_sound"  label="Sound Text"  placeholder=""></v-textarea>
                  <v-textarea outlined v-model="text" v-if="visible_text"   label="Text Paragraph" ></v-textarea>


                  <v-divider></v-divider>
                  <div class="text-center my-8">
                    <v-btn elevation="0" outlined small text @click="addText">Add new text</v-btn>
                    <v-btn elevation="0" class="ml-2" outlined small text @click="addDrop">Add new dropdown</v-btn>
                  </div>

                  <draggable :list="items"  @end="endDrag(items)" >
                    <div v-for="item in items" :key="item.itemId">

                        <v-card class="pa-3 mb-6" color="#f4f3f9" outlined v-if="item.type == 'text'" >

                          <div class="row">
                              <v-card-title class="subtitle-1 border"><v-icon class="dragIcon">{{icons.mdiDrag}}</v-icon> TEXT AREA</v-card-title>
                              <v-spacer></v-spacer>
                              <v-btn  elevation="0" class="mt-2 me-2" color="primary" small text @click="deleteItem(item.itemId)">
                                <v-icon  >{{icons.mdiDelete}}</v-icon>
                              </v-btn>
                          </div>
                          <v-divider class="mb-4"></v-divider>
                          <v-textarea auto-grow rows="1" hide-details outlined  v-model="item.content" background-color="white" class="ma-0" label="Text" required  ></v-textarea>
                        </v-card>

                        <v-card class="pa-3 mb-6" color="#f4f3f9" outlined v-if="item.type == 'drop'">

                          <div class="row">
                              <v-card-title class="subtitle-1 border"><v-icon class="dragIcon">{{icons.mdiDrag}}</v-icon> DROPDOWN</v-card-title>
                              <v-spacer></v-spacer>
                              <v-btn  elevation="0" class="mt-2 me-2" color="primary" small text @click="deleteItem(item.itemId)">
                                <v-icon >{{icons.mdiDelete}}</v-icon>
                              </v-btn>
                          </div>
                          <v-divider></v-divider>

                            <v-radio-group v-model="item.selectedOption" class="mx-3" >
                              <v-row class="mt-1" v-for="drp in item.options" :key="drp.itemOptionId" >
                                  <v-radio class="mt-n6"  :value="drp.itemOptionId" @change="changeOption"></v-radio>
                                  <v-text-field background-color="white" dense outlined v-model="drp.value" label="Option" required ></v-text-field>
                                  <v-btn text small class="ml-4 mt-1" elevation="0" @click="deleteOption(drp.itemOptionId)"><v-icon>{{icons.mdiDelete}}</v-icon></v-btn>
                              </v-row>  
                            </v-radio-group>

                            <div class="text-center">
                              <v-btn elevation="0" outlined small text @click="addOption(item.itemId)">Add option</v-btn>
                            </div>

                        </v-card>
                    </div>
                  </draggable>


                </v-form>
              </div>


            </v-card>
        </v-col>

        <v-col cols="12" sm="3">

          <v-card elevation="0" outlined >  
            <v-card-title primary-title>RICH TEXT</v-card-title>
            <div class="mx-5">Rich texts can be used for text covers, question texts. Do not use it for answer areas.</div>
            <div class="mx-5"> 

              <v-simple-table>
                <template v-slot:default>
                  
                  <tbody>
                    <tr>
                      <td>**text**</td>
                      <td><strong>BOLD</strong></td>
                    </tr>
                    <tr>
                      <td>*text*</td>
                      <td><em>ITALIC</em></td>
                    </tr>
                    <tr>
                      <td>`text`</td>
                      <td><code>CODE</code></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </div>
          </v-card> 

        </v-col>

        
      </v-row >
      


    </div>

</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiAlertRhombus, mdiCheckBold, mdiDelete , mdiDrag  } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import draggable from 'vuedraggable';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, draggable , },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline, mdiDrag, mdiDelete },
      }
    },
    
    data: () => ({
      valid: true,
      leafletId : '',
      unitId: '',
      bookId: '',
      bookName: '',
      unitName: '',
      leafletName: '',
      questionTitle: '',
      text: '',
      video: '',
      sound: '',
      soundText: '',
      images: [],
      items : [],
      selectedOption:'',
      visible_image: false,
      visible_video: false,
      visible_sound: false,
      visible_text: false,
      selectedCover: {
        type: "empty",
        name: "Please Choose"
      },
      coverTypes : [
          {type: "empty", name: "Please Choose"} ,
          {type: "image", name: "Images"} ,
          {type: "video", name: "Video"} ,
          {type: "sound", name: "Sound"},
          {type: "text", name: "Text"}
        ],
      

    }),

    methods: {
      test(){
        console.log(this.items);
      },
      addText(){
        this.items.push({
          itemId:this.randString(),
          type:'text',
          content:'',
          options: [],
          selectedOption:''
        });
      },
      addDrop(){
        this.items.push({
          itemId:this.randString(),
          type:'drop',
          content:'',
          options: [],
          selectedOption:''
        });
      },
      deleteItem(itemId){
        let i = 0;
        this.items.forEach(e => {
          if(e.itemId == itemId){
            this.items.splice(i, 1);
          }
          i++;
        });
      },
      deleteOption(itemOptionId){
        
        let k = 0;
        this.items.forEach(e => {
          let i = 0;
          e.options.forEach(o => {
            if(o.itemOptionId == itemOptionId){
              this.items[k].options.splice(i, 1); 
            }
            i++;
          });

          k++;
        });

      },
      changeOption(){

        setTimeout(() => {

          this.items.forEach(e => {

            e.options.forEach(op => {
              if(op.itemOptionId == e.selectedOption){
                op.correct = true; 
              }else{
                op.correct = false; 
              }

            });

          });

        }, 500);

      },
      
      addOption(itemId){
        /* eslint-disable no-unused-vars */
        let i = 0;
        this.items.forEach(e => {

          if(e.itemId == itemId){
              e.options.push({
                itemOptionId: this.randString(),
                value: '',
                correct: false
              });
            }
          
          i++;
        });

      },
      randString() {
          var result = '';
          var characters = 'abcdefghijklmnopqrstuvwxyz';
          var charactersLength = characters.length;
          for ( var i = 0; i < 10; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      getData() {
        
        const data = { leafletId : this.$route.params.leafletId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/leaflet",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.bookId = response.data.bookId;
            this.bookName = response.data.bookName;
            this.unitId = response.data.unitId;
            this.unitName = response.data.unitName;
            this.leafletName = response.data.leaflet.leafletName;
          }  
        );

      },

      validateForm(){
        let res = true;
        
        if(this.questionTitle ==""){res = false}
        if(this.items.length == 0){res = false}

        this.items.forEach(e => {
          console.log(e);
          if(e.type == "text" && e.content == ""){res = false}
          if(e.type == "drop" && e.options.length == 0){res = false}

          if(e.options.length >= 1){
            let co = 0;
            e.options.forEach(y => {
              if(y.value == ""){res = false}
              if(y.correct){co++;}
            });
            if(co == 0){res = false}
          }


        });

        if(res == false){
          this.$root.Vtoast.show({message: 'Please fill the necessary fields!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
        }

        return res;
      },

      submit () {

        if(this.validateForm()){

          const fd = new FormData();
          fd.append('questionType', "dropdown_paragraph");

          if(this.selectedCover != 'image' && this.selectedCover != 'video' && this.selectedCover != 'sound' && this.selectedCover != 'text'){
            this.selectedCover = 'empty';
          }
          
          fd.append('leafletId', this.leafletId);
          fd.append('questionTitle', this.questionTitle);
          fd.append('selectedCover', this.selectedCover);
          fd.append('video', this.video);
          fd.append('sound', this.sound);
          fd.append('soundText', this.soundText);
          fd.append('text', this.text);
          fd.append('items', JSON.stringify(this.items));

          for( var i = 0; i < this.images.length; i++ ){
            let image = this.images[i];
            fd.append('images[' + i + ']', image);
          }


          axios.post(process.env.VUE_APP_API_DOMAIN + "/question_dropdown_paragraph_add",fd,{ headers: { 'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Question is added successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }
            }  
          );
        }

      },
      vtoastCallback() {
        this.$router.push(`/eisadmin/questions/${this.leafletId}`);
      },
      changeCover(){

        this.visible_image = false;
        this.visible_video = false;
        this.visible_sound = false;
        this.visible_text = false;
        
        if(this.selectedCover == "image"){
          this.visible_image = true;
        }
        if(this.selectedCover == "video"){
          this.visible_video = true;
        }
        if(this.selectedCover == "sound"){
          this.visible_sound = true;
        }
        if(this.selectedCover == "text"){
          this.visible_text = true;
        }
      },

      endDrag(temp_items){
        this.items = temp_items
      },
      
      
    },


    created() {

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.leafletId = this.$route.params.leafletId;
      this.getData();
    },
  }
</script>
