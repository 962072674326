<template>
    <div class="row mt-5 ">
        <div class="col-12 ">


            <v-simple-table v-if="materials.length >= 1">
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Type</th>
                        <th class="text-left"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="mat in materials" :key="mat.materialId">
                        <td class="tabs_border">
                            <div class="pa-1">
                                <div class="font_bold f_16">{{ mat.materialName }} </div>
                                <div class="f_14 mt-1">{{ mat.materialDescription }} </div>
                            </div>
                        </td>
                        <td class="tabs_border">
                                <div class="font_bold">{{ mat.materialType }} </div>
                        </td>

                        <td class="tabs_border">
                            <v-btn :href="mat.materialFile" target="_blank" small elevation="0" color="info">OPEN</v-btn>
                        </td>
                        
                    </tr>

                </tbody>
                </template>
            </v-simple-table>

            <div v-if="materials.length == 0" class="mt-8 text-center">
                <div class="font_bold f_24">No material found!</div>
            </div>
            
        </div>
        
    </div>
</template>



<script>
import { mdiPlusCircleOutline  } from '@mdi/js'
import axios from 'axios';

export default {
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        materials : []
    }),
    props: {
        bookId: String
    },
    methods: {



        getResults(){

            this. results = [];

            const data = { bookId : this.bookId }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_book_materials",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {

                    this.materials = response.data.materials;

                }  
            );


        }

    

    },
    setup() {
        return {
        icons: { mdiPlusCircleOutline },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted(){
        this.getResults();
    }
}
</script>

