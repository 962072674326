<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>ARCHIVED BOOKS</div>
            <v-spacer></v-spacer>

          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="40" class="text-left">#</th>
                  <th width="80" class="text-left">Cover</th>
                  <th class="text-left">Book Name</th>
                  <th class="text-left">Materials</th>
                  <th class="text-left">Units</th>
                  <th class="text-left">Student Codes</th>
                  <th class="text-left">Teacher Codes</th>
                  <th width="180"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in books" :key="item.bookId" >
                  
                    <td>{{ (page - 1) * 10 + index +1 }}</td>
                    <td><v-img :src="VUE_APP_BOOK_MEDIA+item.bookCover" contain width="60" /></td>
                    <td>{{ item.bookName }} - {{ item.bookSubtitle }}</td>
                    <td><v-btn :href="'/eisadmin/materials/'+ item.bookId" small color="dark" outlined>{{ item.materialsCount }}</v-btn></td>
                    <td><v-btn :href="'/eisadmin/units/'+ item.bookId" small color="success" outlined>{{ item.unitCount }}</v-btn></td>
                    <td><v-btn :href="'/eisadmin/book_student_codes/'+ item.bookId" small color="dark" outlined>{{ item.student_codeCount }}</v-btn></td>
                    <td><v-btn :href="'/eisadmin/book_teacher_codes/'+ item.bookId" small color="dark" outlined >{{ item.teacher_codeCount }}</v-btn></td>
                    <td>
                      <v-row justify="space-around">
                        <v-btn small text :href="'/eisadmin/book_edit/'+ item.bookId">EDIT</v-btn>
                      </v-row>
                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions class="mt-3" >
            <v-spacer></v-spacer>
            <v-pagination v-model="page" :length= this.paginationLenght :total-visible=7 ></v-pagination>
          </v-card-actions>
      </v-card>
    </div>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold  },
      }
    },

    data(){
      return {
        VUE_APP_API_DOMAIN : "",
        VUE_APP_BOOK_MEDIA : process.env.VUE_APP_BOOK_MEDIA,
        searchKeyword: "",
        books: [],
        page: 1,
        paginationLenght : 0
      }
    },

    methods: {
      getData() {
        
        const data = { page : this.page }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/archived_books",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.books = response.data.books;
            this.paginationLenght = Math.ceil(response.data.itemCount / 10);
          }  
        );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },

      

    },

    watch: {
      page: function (newValue, oldValue) {
        if(newValue != oldValue){
          this.getData();
        }
      }
    },

    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

