<template>
    <div class="pa-2">


        <div class="f_20 font_bold">{{questionOrder}} - {{questionTitle}}</div>
        
        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" id="vid" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w"  controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>
        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>

        <div class="row mt-4">

            <div v-for="cl in images_fill_items" :key="cl.imgId" :class="class4Column()">
                
                <img :src="VUE_APP_QUESTION_MEDIA+cl.image" style="width:100%">
                <div class="mt-1">
                    {{cl.pre}}
                    <v-chip outlined  class="mx-1" :color="findColor(cl.answer_correct)"  ><span class="color_black">{{cl.answer_ogrenci}}</span></v-chip>
                    {{cl.post}}
                </div>
                
            </div>

        </div>

        <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>

<style>
    .cencen input{
        text-align: center;
    }
</style>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'

export default {
    mixins: [markup],
    components: { Splide, SplideSlide  },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        coverImages : null,
        imageColumns: "",
        images_fill_items: null,

        dialogSound: false


    }),
    props: {
        questionId: String,
        homeworkId: String,
        studentId: String,
    },
    methods: {

        stopVideo(){
            document.getElementById("vid").pause();
        },

        test(){
            console.log(this.images_fill_items);
        },

        reload() {
            this.$forceUpdate();
        },

        findColor(crr){
            let res = "pink";
            if(crr == "1"){
                res = "green";
            }
            return res
        },


        resetQuestion(){

            this.images_fill_items.forEach(e => {
                e.writtenAnswer = "";
            });

            this.reload();

        },

        correctQuestion(){

            this.images_fill_items.forEach(e => {
                e.writtenAnswer = e.word;

            });

            this.reload();

        },

        class4Column(){
            return "col-lg-"+(12 / this.imageColumns) + " col-6 text-center mb-3"; 
        },

        getData(){
            const data = { questionId : this.questionId ,homeworkId : this.homeworkId , studentId : this.studentId}
            axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_homework_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {

                    this.questionOrder = response.data.questionOrder;
                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.coverImages = response.data.coverImages;
                    this.imageColumns = response.data.imageColumns;
                    this.images_fill_items = response.data.images_fill_items;



                }  
            );
        }

    },
    setup() {
        return {
        icons: {mdiFormatAlignLeft },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted() {
        this.getData();
    },
}
</script>