<template>
  <div class="bg_login">

    <video id="background-video" autoplay loop muted poster="@/assets/bg.mp4">
      <source src="@/assets/bg.mp4" type="video/mp4">
    </video> 

    <div class="auth-wrapper auth-v1 ">
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <div class="auth-inner">
        <v-card class="">

          <div class=" text-center pt-8" ><img src="@/assets/images/EISEnglish_logo.png" width="220"></div>

          <div class="text-center px-2 f_12 mb-10">
            Please sign in with your email address and password.
          </div>


          <v-card-text>
              <v-text-field v-model="email" outlined label="Email Address" placeholder="Email Address" hide-details class="mb-3" ></v-text-field>

              <v-text-field v-model="password" outlined :type="isPasswordVisible ? 'text' : 'password'"  @keyup="keymonitor"
                label="Password" placeholder="*******" :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" hide-details @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>

              <v-radio-group  v-model="userType" class="mt-10" row>
                <v-radio label="Student" value="student"></v-radio>
                <v-radio label="Teacher" value="teacher"></v-radio>
              </v-radio-group>

              <v-btn v-on:click="handleLogin" large block color="primary" class="mt-1" >Sign In</v-btn>

              <div class="text-right mt-4"><a href="/forgot_password" class="link_blue">Forgot your password?</a></div>

              <div class="mt-8 text-center">or</div>

              <v-btn to="/register" block text class="mt-1" >Register</v-btn>

          </v-card-text>


        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {  mdiEyeOutline, mdiEyeOffOutline, mdiAlertRhombus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Vtoast from '../views/eisadmin/components/Vtoast';
import '@/styles/login.css'

  export default {
    components: {  Vtoast },
    setup() {
      const isPasswordVisible = ref(false)
      const email = ref('')
      const password = ref('')
      

      return {
        isPasswordVisible,
        email,
        password,
        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline,
          mdiAlertRhombus
        },
      }
    },
    data(){
      return{
        VUE_APP_IMAGE_ASSETS : process.env.VUE_APP_IMAGE_ASSETS,
        email : '',
        password: '',
        userType: 'student'
      }
      
    },
    computed: {},
    methods:{
      vtoastCallback() { 
        console.log("vtoastCallback");
      },
      keymonitor(event){
          if(event.keyCode === 13){
            this.handleLogin();
          }
        
      },
      handleLogin() {

        const data = {
          email : this.email,
          password : this.password,
          userType : this.userType
        }

        if(this.email == "" || this.password == ""){
          this.$root.Vtoast.show({message: 'Please check your email or password!', snackType: 'fail', color: 'error', icon: mdiAlertRhombus})
        }else{

          axios.post(process.env.VUE_APP_API_DOMAIN + "/login",JSON.stringify(data)
          ).then(
            response => {
              
            

                if(response.data.result == "success"){

                  this.$store.commit('setTokenLogin', {
                    accessToken : response.data.accesstoken,
                    refreshToken : response.data.refreshtoken,
                    userGroup : response.data.user_group
                  });

                  this.$store.commit('setUid', response.data.uid);
                  this.$store.commit('setName', response.data.name);
                  this.$store.commit('setInitials', response.data.initials);
                  this.$store.commit('setUserGroup', response.data.user_group);

                  if(response.data.user_group == "student"){
                    this.$router.push('/student');
                  }else if(response.data.user_group == "teacher"){
                    this.$router.push('/teacher/classes');
                  }else{
                    this.$router.push('/');
                  }

                }else{
                  this.$root.Vtoast.show({message: 'Please check your email or password!', snackType: 'fail', color: 'error', icon: mdiAlertRhombus})
                }

            }  

          );

        }

      }
    },
    created() {
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
    },
  }
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
