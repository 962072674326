<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />

    <div class="pa-5" >
      
      <v-card elevation="0" outlined>
        <v-card-title>Edit Class </v-card-title>

        <div class="pa-5"> 
          <v-form ref="form" >
            <v-text-field outlined v-model="className"   label="Class Name" required ></v-text-field>
            <v-select v-model="schoolSelect" :items="schools"  required item-text="schoolName" item-value="schoolId" class="mt-2"  label="Select School"  outlined  ></v-select>
          

              <div class="row  mb-1">
                <div class="col-6 px-1">
                  
                  <v-menu  ref="menuStart"   v-model="menuStart" :close-on-content-click="false" :return-value.sync="dateStart" transition="scale-transition" offset-y min-width="auto">
                    <template  v-slot:activator="{ on, attrs }">
                      <v-text-field  hint="MM/DD/YYYY format"   v-model="dateStart" label="Start Date" outlined hide-details readonly v-bind="attrs" v-on="on" ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateStart"   no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuStart = false">Cancel</v-btn>
                      <v-btn color="primary" @click="$refs.menuStart.save(dateStart)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>

                </div>
                <div class="col-3 px-1">
                  <v-select hide-details v-model="hourStart" :items="hours" item-text="Hour" item-value="Hour" max-width="10"   label="Hour"  outlined  ></v-select>
                </div>
                <div class="col-3 px-1">
                  <v-select hide-details v-model="minuteStart" :items="minutes" item-text="Minute" item-value="Minute"   label="Minute"  outlined  ></v-select>
                </div>
              </div>

              <div class="row mt-1 mb-4">
                <div class="col-6 px-1">

                  
                  
                  <v-menu  ref="menuEnd"   v-model="menuEnd" :close-on-content-click="false" :return-value.sync="dateEnd" transition="scale-transition" offset-y min-width="auto">
                    <template  v-slot:activator="{ on, attrs }">
                      <v-text-field  hint="MM/DD/YYYY format"  v-model="dateEnd" label="Due Date" outlined hide-details readonly v-bind="attrs" v-on="on" ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateEnd"  no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuEnd = false">Cancel</v-btn>
                      <v-btn color="primary" @click="$refs.menuEnd.save(dateEnd)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>

                </div>
                <div class="col-3 px-1">
                  <v-select hide-details v-model="hourEnd" :items="hours" item-text="Hour" item-value="Hour" max-width="10"   label="Hour"  outlined  ></v-select>
                </div>
                <div class="col-3 px-1">
                  <v-select hide-details v-model="minuteEnd" :items="minutes" item-text="Minute" item-value="Minute"   label="Minute"  outlined  ></v-select>
                </div>
              </div>

    
          </v-form>



        </div>

        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <v-btn :to="'/eisadmin/schools'" color="error" small elevation="0" class="mb-2 mr-2 mt-2" >CANCEL</v-btn>
          <v-btn class="float-right" @click="submit" small color="primary" >Save</v-btn>
        </v-card-actions>

      </v-card>

    </div>

</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiGithub, mdiAlertRhombus, mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';


  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline, mdiGithub,},
      }
    },

    data: () => ({
      
      className: '',
      schoolSelect: 0,
      schools : [],

      menuStart: false,
      menuEnd: false,

      dateStart: null,
      hourStart:null,
      minuteStart:null,
      dateEnd: null,
      hourEnd:null,
      minuteEnd:null,

      hours:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
      minutes:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59],
      nowDate : "",



      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 2) || 'Name must be more than 2 characters',
      ],
      
    }),

    methods: {
      getData(classId) {
        
        const data = { classId : classId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/class",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            console.log(response.data);
            this.className = response.data.class.className;
            this.schoolSelect = response.data.class.schoolId;
            this.schools = response.data.schools;
            this.dateStart = response.data.dateStart; 
            this.hourStart = parseInt(response.data.hourStart); 
            this.minuteStart = parseInt(response.data.minStart); 
            this.dateEnd = response.data.dateEnd; 
            this.hourEnd = parseInt(response.data.hourEnd); 
            this.minuteEnd = parseInt(response.data.minEnd); 
          }  
        );

      },
      submit () {

          
          const data = {
            className : this.className,
            schoolId : this.schoolSelect,
            classId : this.$route.params.classId,

              dateEnd : this.dateEnd,
              hourEnd : this.hourEnd,
              minuteEnd : this.minuteEnd,
              
              dateStart : this.dateStart,
              hourStart : this.hourStart,
              minuteStart : this.minuteStart,
          

          }

          axios.post(process.env.VUE_APP_API_DOMAIN + "/class_save",JSON.stringify(data)
          ,{
              headers: {
                'Authorization': 'Bearer ' + this.$store.getters.getAccessToken  
              }
            }
          ).then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Class is saved succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }

            }  

          );


      },
      vtoastCallback() {
        this.$router.push('/eisadmin/classes');
      }
      
    },
    created() {},
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast
      this.getData(this.$route.params.classId);
    },
  }
</script>

