<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P">
        <v-container class="pt-0">

            <div class="mt-5">
              <a :href="'/student/book/'+ bookId" class="mt-4 link_blue2">
                  <v-icon class="mt-n1 link_blue2" >{{icons.mdiChevronLeft  }}</v-icon>Back
              </a>
            </div>

            <ClassMini :className = className 
            :schoolName = schoolName 
            :classKey = classKey 
            :classId = classId 
            :classStartDate = classStartDate 
            :classEndDate = classEndDate 
            v-on:changeDialogBox = changeDialogBox  />



            <div class="mt-8 " >
              
              <BookMini 
              :bookCode = bookCode 
              :bookCodeDates = bookCodeDates
              :bookCover = bookCover 
              :bookName = bookName 
              :bookSubtitle = bookSubtitle 
              :bookDescription = bookDescription  />

              <div class="mt-8 pb-5">

                <div class="tabs_border pb-1 f_24 font_bold  mb-4">
                  <v-icon size="32">{{icons.mdiTextBoxOutline }}</v-icon>  {{unitName}}
                </div>


                  <v-simple-table v-for="item in leaflets" :key="item.leafletId">
                    <template v-slot:default>
                      <tbody>
                        <tr >
                          <td width="70" class="text-right d-none d-sm-table-cell">
                            <v-img :src="require('@/assets/images/quizIcon.png')" max-width="70px" class="ma-1"></v-img>
                          </td>
                          <td class="tabs_border">
                            <div class="student_f_18_unit font_bold ">
                              {{ item.leafletName }}
                              <v-chip v-if="item.classDetails.statu == 'started'" color="green" class="ml-2 font_regular mb-2" small>homework started</v-chip>
                              <v-chip v-if="item.classDetails.statu == 'finished'" color="primary" class="ml-2 font_regular mb-2" small>homework finished</v-chip>
                              <v-chip v-if="item.classDetails.statu == 'finished_noHw'" color="primary" class="ml-2 font_regular mb-2" small>finished</v-chip>


                            </div>
                            <div class="f_12 color_muted">{{ limitText(item.leafletDescription,80) }}</div>
                          </td>
                          <td width="120" class="tabs_border d-none d-sm-table-cell">
                            {{item.leafletQuestionCount}} Questions
                          </td>
                          <td width="100" class="text-right tabs_border">

                            <v-menu  bottom left offset-y  max-width="200">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" icon v-bind="attrs" v-on="on">
                                  <v-icon>{{icons.mdiDotsVertical}}</v-icon>
                                </v-btn>
                              </template>
                              <v-list >
                                <div class="mx-2"><v-btn text block @click="goToLeaflet(item.leafletId)" >START</v-btn></div>
                                <div class="mx-2"><v-btn v-if="item.classDetails.statu != 'started'" text block @click="goToResults(item.leafletId)"  >RESULTS</v-btn></div>
                              </v-list>
                            </v-menu>

                          </td>
                        </tr>

                      </tbody>
                    </template>
                  </v-simple-table>

              </div>


            </div>

            <v-dialog v-model="dialogClassKey" persistent max-width="600px">
              <v-card>
                <v-card-title class="text-h5">Join Class</v-card-title>
                <v-card-text>
                  <v-text-field label="Class Key" outlined hide-details v-model="newClassKey"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="dialogClassKey = false" >Close</v-btn>
                  <v-btn color="primary darken-1" elevation="0" @click="joinClass()" >Join</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


        </v-container>
      </div>

  </v-app>

</template>

<script>
  import checkAuthStudent from '../../mixins/checkAuthStudent';
  import TopBar from '../../views/student/components/TopBar'
  import ClassMini from '../../views/student/components/ClassMini'
  import BookMini from '../../views/student/components/BookMini'
  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup,  
  mdiMagnify ,mdiAlertCircle ,mdiCheckBold, mdiRecordCircleOutline, mdiChevronRight, mdiChevronLeft,mdiDotsVertical ,mdiTextBoxOutline } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/student/components/Vtoast';
  import Confirm from '../../views/student/components/Confirm.vue';

  export default {
    mixins: [checkAuthStudent],
    components: {
      TopBar,  Vtoast, Confirm, ClassMini, BookMini
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,
          mdiMagnify,
          mdiAlertCircle,
          mdiCheckBold,
          mdiRecordCircleOutline,
          mdiChevronRight,
          mdiChevronLeft ,
          mdiDotsVertical,
          mdiTextBoxOutline
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',
      schoolName:"",
      classId:"0",
      className:"",
      classKey:"",
      classStartDate:"",
      classEndDate:"",
      bookId:0,
      unitId:0,

      dialogClassKey: false,
      newClassKey: '',

      bookName:"",
      bookSubtitle:"",
      bookCover: "",
      bookDescription: "",
      bookCode: "",
      bookCodeDates: "",

      unitName: '',

      activetab: 'tabBookdetails',

      leaflets:[],

      

    }),
    methods:{

      changeDialogBox(){
        console.log("dsadasda");
        this.dialogClassKey = true;
      },

      tabshow(gl){
        if(gl == "tabStudents"){
          this.getStudentData();
        }
        this.activetab = gl;
      },

      goToLeaflet(leafletId){

        const data = {leafletId: leafletId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_start_session",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          (res) => {
            
            console.log(res.data);

            if(res.data.sessType == 'none'){
              this.$root.Vtoast.show({message: 'No homework is ready! Please try later.', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }else if(res.data.sessType == 'homework_done'){
              this.$root.Vtoast.show({message: 'You have done this homework! Please wait the due time to solve again!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }else if(res.data.sessType == 'wait'){
              this.$root.Vtoast.show({message: 'Please wait your teacher to activate this leaflet!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }else{
              this.$router.push('/student/leaflet/'+res.data.sessionCode);
            }

          }  
        );

      },
      goToResults(leafletId){
            this.$router.push('/student/book/'+this.bookId+'/tabResults/'+leafletId);
      },

      limitText(text,len){
        return  text.length > len ? text.substring(0, len - 3) + "..." : text;
      },

      findClass4Leaflet(statu){
        console.log(statu);
        let res = "grey lighten-2";
        if(statu == "finished"){
          res = "primary";
        }
        if(statu == "started"){
          res = "green";
        }
        return res
      },


      joinClass(){
        
        const data = {classKey: this.newClassKey }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_class_join",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            if(response.data.result == "noClass"){
              console.log("noClass");
              this.$root.Vtoast.show({message: 'No class found! Please check your Class Key!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }else if(response.data.result == "noBook"){
              console.log("noBook");
              this.$root.Vtoast.show({message: 'No book found! You need to add book "'+response.data.bookName+'" in your library first!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }else if(response.data.result == "success"){
              console.log("success");
              this.$root.Vtoast.show({message: 'Class is added successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              
            }else{
              console.log("fail");
              this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }

          }  
        );

      },


      getData(){

          const data = { bookId : this.bookId, unitId : this.unitId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_unit_details",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log("response.data");
              console.log(response.data);

              this.className = response.data.className;
              this.classKey = response.data.classKey;
              this.schoolName = response.data.schoolName;
              this.unitName = response.data.unitName;
              this.classId = response.data.classId;
              this.classStartDate = response.data.classStartDate;
              this.classEndDate = response.data.classEndDate;

              this.bookName = response.data.bookDetails.bookName;
              this.bookSubtitle = response.data.bookDetails.bookSubtitle;
              this.bookCover = response.data.bookDetails.bookCover;
              this.bookDescription = response.data.bookDetails.bookDescription;
              this.bookCode = response.data.bookCode;
              this.bookCodeDates = response.data.bookCodeDates;

              this.leaflets = response.data.leaflets;

            }  
          );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        this.dialogClassKey = false
        this.getData();
      },


    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
      this.bookId = this.$route.params.bookId;
      this.unitId = this.$route.params.unitId;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.getData();
    },
    

  }
</script>