<template>
    <div class="pa-2">

        <div class="f_20 font_bold">{{questionOrder}} - {{questionTitle}}</div>
        
        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" id="vid" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w"  controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>
        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>

        <ol class="mt-8" :type="listing_symbol">
            <li  v-for="item in fill_list_items" :key='item.fillId'>
                <div v-if="listing_type == 'vertical_te'" class="row mb-5">
                    <div class="col"><span v-html='markup_do(item.text)'></span></div>
                    <div class="col">
                        <span v-html='markup_do(item.empty_pre)'></span>

                        <v-chip outlined  class="ma-1" :color="findColor(item.correct)"  ><span class="color_black">{{item.writtenAnswer}}</span></v-chip>

                        <span v-html='markup_do(item.empty_post)'></span>

                    </div>
                </div>
                <div v-if="listing_type == 'vertical_et'" class="row mb-5">
                    <div class="col">
                        <span v-html='markup_do(item.empty_pre)'></span>

                        <v-chip outlined  class="ma-1" :color="findColor(item.correct)"  ><span class="color_black">{{item.writtenAnswer}}</span></v-chip>

                        <span v-html='markup_do(item.empty_post)'></span>
                    </div>
                    <div class="col"><span v-html='markup_do(item.text)'></span></div>
                </div>

                <div v-if="listing_type == 'horizontal_te'" class="mb-5">
                    <div class="w_100"><span v-html='markup_do(item.text)'></span></div>
                    <div class="w_100 mt-2">
                        <span v-html='markup_do(item.empty_pre)'></span>

                        <v-chip outlined  class="ma-1" :color="findColor(item.correct)"  ><span class="color_black">{{item.writtenAnswer}}</span></v-chip>

                        <span v-html='markup_do(item.empty_post)'></span>
                    </div>
                </div>

                <div v-if="listing_type == 'horizontal_et'" class="mb-5">
                    <div class="w_100">
                        <span v-html='markup_do(item.empty_pre)'></span>

                        <v-chip outlined  class="ma-1" :color="findColor(item.correct)"  ><span class="color_black">{{item.writtenAnswer}}</span></v-chip>

                        <span v-html='markup_do(item.empty_post)'></span>
                    </div>
                    <div class="w_100"><span v-html='markup_do(item.text)'></span></div>
                </div>

            </li>
        </ol>


        <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'

export default {
    mixins: [markup],
    components: { Splide, SplideSlide  },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        coverImages : null,
        listing_type: "",
        listing_symbol: "",
        fill_list_items: null,
        dialogSound: false

    }),
    props: {
        questionId: String,
        homeworkId: String,
        studentId: String,
    },
    methods: {

        reload() {
            this.$forceUpdate();
        },

        stopVideo(){
            document.getElementById("vid").pause();
        },

        findColor(gln){
            let res = "pink";
            if(gln == "1"){
                res = "green";
            }
            return res
        },

        test(){
            console.log(this.fill_list_items);
        },

        resetQuestion(){

            this.fill_list_items.forEach(e => {
                e.writtenAnswer = "";

                setTimeout(() => {
                    let newW = this.$el.querySelector('#v'+e.fillId).clientWidth + 35;
                    e.inputW = newW;
                    this.reload();
                }, 2);
                
            });

            setTimeout(() => {
                this.reload();
                    
            }, 10);

        },

        correctQuestion(){

            this.fill_list_items.forEach(e => {
                e.writtenAnswer = e.empty;

                setTimeout(() => {
                    let newW = this.$el.querySelector('#v'+e.fillId).clientWidth + 35;
                    e.inputW = newW;
                    this.reload();
                }, 2);
                
            });

            setTimeout(() => {
                this.reload();
                    
            }, 10);

            

        },

        writtenChange(fillId){

            setTimeout(() => {
            
                let newW = this.$el.querySelector('#v'+fillId).clientWidth + 35;
                console.log(fillId);
                this.fill_list_items.forEach(e => {
                    if(e.fillId == fillId){
                        e.inputW = newW;
                        this.reload();
                    }
                });

            }, 0);

            this.reload();
            
        },

        showText(pairId){
            let metin = "";
            this.pair_click_paragraph_items.forEach(e => {
                if(pairId == e.pairId){
                    if(e.type == "text"){
                        metin += e.text+' ';
                    }else{
                        metin += '<span class="pair_chip">'+e.answer+'</span> ';
                    }
                }
            });

            return metin;
        },

        getData(){
            const data = { questionId : this.questionId, homeworkId : this.homeworkId , studentId : this.studentId }
            axios.post(this.VUE_APP_API_DOMAIN + "/t_teacher_homework_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {

                    console.log(response.data);
                    this.questionOrder = response.data.questionOrder;
                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.coverImages = response.data.coverImages;
                    this.listing_type = response.data.listing_type;
                    this.listing_symbol = response.data.listing_symbol;
                    this.fill_list_items = response.data.fill_list_items;

                    


                }  
            );
        }

    },
    setup() {
        return {
        icons: {mdiFormatAlignLeft },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted() {
        this.getData();
    },
}
</script>