<template>
    
    <div class="pa-2">

        

        <div class="f_20 font_bold mt-2">{{questionOrder}} - {{questionTitle}}</div>
        
        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" id="vid" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w"  controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>
        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>

        <div class="mt-3 row">
            <div class="col-12">
                
                <div class="row">
                    <div v-for="(item, index) in group_words_items" :key="item.groupId" :class="classBul()">
                        <div class="border2 rounded bg_grey1">
                            <div class="text-center f_20 font_bold border-bottom1 pb-1 mb-2">{{item.groupName}}</div>
                            <div class="pa-2 minhe150">
                                
                                <span v-if="show_correct == 0">
                                <v-chip outlined v-for="word in item.answerId_ogrenci" :key="word.reply" class="ma-1" :color="findColor(index,word.reply)"  ><span class="color_black">{{word.answer_text}}</span></v-chip>
                                </span>
                                <span v-if="show_correct == 1">
                                <v-chip outlined v-for="word in item.words" :key="word.reply" class="ma-1" color="green"  ><span class="color_black">{{word.word}}</span></v-chip>
                                </span>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            
        </div>

        <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css';
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'

export default {
    mixins: [markup],
    components: { Splide, SplideSlide,  },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        listing_type: "",
        listing_symbol: "",
        coverImages : null,
        group_words_items: null,
        group_words_words: null,

        selected_wordId : 0,

        word_list_original : [],
        word_list : [],
        dialogSound: false,
        show_correct: 0,
        imageColumns: 0,


    }),
    props: {
        questionId: String,
        sessionCode: String
    },
    methods: {

        stopVideo(){
            document.getElementById("vid").pause();
        },


        test(){
            console.log(this.group_words_items);
        },
        correctAnswers(){
            this.show_correct = 1;
        },
        studentAnswers(){
            this.show_correct = 0;
        },
        findColor(group,itemId){

            let res = "pink";

            this.group_words_items[group].words.forEach(e => {
                if(e.wordId == itemId){
                    res = "green";
                }
                
            });
            
            return res
        },
        reload() {
            this.$forceUpdate();
        },

        classBul(){
            return "col-sm-"+12/this.imageColumns
        },

        correctQuestion(){
            this.resetQuestion();
            this.group_words_items.forEach(e => {

                e.words.forEach(t => {
                    e.list.push({
                        wordId: t.wordId,
                        word: t.word
                    });
                });

            });
            

            this.word_list = [];
        },
        resetQuestion(){
            this.word_list = this.word_list_original;
            this.group_words_items.forEach(e => {
                e.list = [];
            });
        },
        
        startdragging(env){
            console.log("dragging id:"+env.clone.id);
            //this.selected_wordId = env.clone.id
            
        },
        enddragging(){
            this.reload()
        },
        kasa(){
            console.log("kasa değişti");
        },
        log(evt,pairId){

            console.log(evt);
            console.log(pairId);

        },
        getData(){
            const data = { questionId : this.questionId, sessionCode: this.sessionCode }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_session_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {

                    console.log("----");
                    console.log(response.data);

                    this.questionOrder = response.data.questionOrder;

                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.imageColumns = parseInt(response.data.imageColumns);
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.coverImages = response.data.coverImages;
                    this.listing_type = response.data.listing_type;
                    this.listing_symbol = response.data.listing_symbol;
                    this.group_words_items = response.data.group_words_items;
                    this.group_words_words = response.data.group_words_words;

                    
                    this.group_words_words.forEach(e => {
                        this.word_list.push({
                            wordId: e.wordId,
                            word: e.word
                        });
                        this.word_list_original.push({
                            wordId: e.wordId,
                            word: e.word
                        });
                    });

                }  
            );
        }

    },
    setup() {
        return {
        icons: { mdiFormatAlignLeft },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted() {
        this.getData();
    },
}
</script>