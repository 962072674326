<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />

    <div class="pa-5" >

      <v-row class="mx-0" >
        <div>
          <div class="f_18 font-weight-bold">CREATE FILL TABLE QUESTION</div>
          <div class="f_14 color_muted">
            <a href="/eisadmin/books" class="link_blue">Books</a> > 
            <a :href="'/eisadmin/units/'+bookId" class="link_blue">{{bookName}}</a> > 
            <a :href="'/eisadmin/leaflets/'+unitId" class="link_blue">{{unitName}}</a> > 
            <a :href="'/eisadmin/questions/'+leafletId" class="link_blue">{{this.leafletName}}</a>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn :to="'/eisadmin/questions/'+leafletId" color="error" small elevation="0" class="mb-2 mr-2 mt-2" >CANCEL</v-btn>
        <v-btn @click="submit" color="success" small elevation="0" class="mb-2 mt-2" >SAVE</v-btn>
      </v-row >

      <v-row >
        <v-col cols="12" sm="9">

            <v-card elevation="0" outlined>
              <div class="pa-5"> 
                <v-form ref="form" v-model="valid" lazy-validation >
                  <v-text-field outlined v-model="questionTitle"  label="Question Text"  ></v-text-field>
                  <v-select v-model="selectedCover" @change="changeCover" :items="coverTypes"  item-text="name" item-value="type" class="mt-2"  label="Cover Types"  outlined  ></v-select>
                  <v-file-input v-model="images" v-if="visible_image" outlined multiple small-chips   accept="image/png, image/jpeg" placeholder="Choose Images" label="Cover Images" name ="Cover Images" ></v-file-input>
                  <v-text-field outlined v-model="video" v-if="visible_video"  label="Video Url"  placeholder="https://" ></v-text-field>
                  <v-text-field outlined v-model="sound" v-if="visible_sound"  label="Sound Url"  placeholder="https://"></v-text-field>
                  <v-textarea outlined v-model="soundText" v-if="visible_sound"  label="Sound Text"  placeholder=""></v-textarea>
                  <v-textarea outlined v-model="text" v-if="visible_text"   label="Text Paragraph" ></v-textarea>
                </v-form>
              </div>
            </v-card>



            <table class="mt-8 ">
              

                <tbody>
                  <tr v-for="(r, r_index) in items" :key="r.rowId" :class="bgHeaderTop(r_index)">
                    <td style="position:relative" v-for="(c, c_index) in r.colomns" :key="c.colomnId" :class="bgHeaderLeft(c_index)">

                      <v-checkbox 
                        v-model="items[r_index].colomns[c_index].isAnswer"
                        hide-details style="position:absolute; right:-12px; top:-6px; z-index:5"
                        v-if="checkShow(r_index,c_index)"
                        class="ma-0 " color="red"
                      ></v-checkbox>
                      <v-text-field class="ma-2" v-model="items[r_index].colomns[c_index].content" dense  hide-details outlined  required ></v-text-field>

                    </td>
                  </tr>
                </tbody>

              
            </table>



        </v-col>

        <v-col cols="12" sm="3">

          <v-card elevation="0" outlined >  
            <v-card-title primary-title>Create Table</v-card-title>
            <div class="ma-3"> 

              <v-select hide-details v-model="row" dense  :items="row_colomn_list" class="mb-2" item-text="Row" item-value="type" label="Row"  outlined  ></v-select>
              <v-select hide-details v-model="colomn" dense   :items="row_colomn_list" class="mb-2" item-text="Colomn" item-value="type" label="Column"  outlined  ></v-select>
              
              <v-checkbox hide-details v-model="isTopTitle" label="Top Titles"></v-checkbox>
              <v-checkbox hide-details v-model="isLeftTitle" label="Left Titles"></v-checkbox>

              <v-btn elevation="0" class="mt-4" color="success" outlined text @click="addTable">Create Table</v-btn>

            </div>

          </v-card> 

        </v-col>
      </v-row>



    </div>

</v-app>

</template>

<style >
  .v-table tbody td {
    height: 40px;
    border: none;
}
.theme--light.v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiGithub, mdiAlertRhombus, mdiCheckBold, mdiDelete  } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';


  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline, mdiGithub,mdiDelete },
      }
    },
    
    data: () => ({
      valid: true,
      leafletId : '',
      unitId: '',
      bookId: '',
      bookName: '',
      unitName: '',
      leafletName: '',
      questionTitle: '',
      text: '',
      video: '',
      sound: '',
      soundText: '',
      images: [],

      row_colomn_list : [1,2,3,4,5,6,7,8,9,10],
      row:0,
      colomn:0,

      selectedListing:'list_i',
      writtenWord : "",
      isTopTitle: false,
      isLeftTitle: false,
      items : [],

      visible_image: false,
      visible_video: false,
      visible_sound: false,
      visible_text: false,
      selectedCover: {
        type: "empty",
        name: "Please Choose"
      },
      coverTypes : [
          {type: "empty", name: "Please Choose"} ,
          {type: "image", name: "Images"} ,
          {type: "video", name: "Video"} ,
          {type: "sound", name: "Sound"},
          {type: "text", name: "Text"}
      ],
      answerTypes : ['true','false'],
      


    }),

    methods: {

      test(){
        console.log(this.items);
      },

      checkShow(r_index,c_index){

        let res = true;
        if(this.isTopTitle && r_index == 0 ){
          res = false;
        }
        if(this.isLeftTitle && c_index == 0){
          res = false;
        }
        return res;
      },

      bgHeaderTop(index){
        let res = "";
        if(this.isTopTitle && index == 0){
          res = "bg_table_header font-weight-bold";
        }
        return res;
      },

      bgHeaderLeft(index){
        let res = "";
        if(this.isLeftTitle && index == 0){
          res = "bg_table_header font-weight-bold";
        }
        return res;
      },

      addTable(){

        this.items = [];
        
        for (let row_i = 1; row_i <= this.row; row_i++) {

          let colomns = [];
          for (let colomn_i = 1; colomn_i <= this.colomn; colomn_i++) {

            colomns.push(
              {
                'colomnId':this.randString(),
                'colomn' : colomn_i,
                'isAnswer' : false,
                'content' : ""
              }
            );

          }

          this.items.push({
            'rowId':this.randString(),
            'row_i': row_i,
            'colomns': colomns,
          });
          
        }

      },

      randString() {
          var result = '';
          var characters = 'abcdefghijklmnopqrstuvwxyz';
          var charactersLength = characters.length;
          for ( var i = 0; i < 10; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      getData() {
        
        const data = { leafletId : this.$route.params.leafletId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/leaflet",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.bookId = response.data.bookId;
            this.bookName = response.data.bookName;
            this.unitId = response.data.unitId;
            this.unitName = response.data.unitName;
            this.leafletName = response.data.leaflet.leafletName;
          }  
        );

      },
      validateForm(){
        let res = true;
        
        if(this.questionTitle ==""){res = false}
        if(this.items.length == 0){res = false}

        this.items.forEach(e => {
          console.log(e);
          e.colomns.forEach(t => {
            if(t.content == ""){res = false}
          });
          
        });

        if(res == false){
          this.$root.Vtoast.show({message: 'Please fill the necessary fields!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
        }

        return res;
      },
      submit () {

        if(this.validateForm()){

          const fd = new FormData();

          fd.append('questionType', "fill_table");

          if(this.selectedCover != 'image' && this.selectedCover != 'video' && this.selectedCover != 'sound' && this.selectedCover != 'text'){
            this.selectedCover = 'empty';
          }
          
          fd.append('leafletId', this.leafletId);
          fd.append('questionTitle', this.questionTitle);
          fd.append('selectedCover', this.selectedCover);
          fd.append('video', this.video);
          fd.append('sound', this.sound);
          fd.append('soundText', this.soundText);
          fd.append('text', this.text);

          if(this.isTopTitle){
              fd.append('isTopTitle', "1");
          }else{
              fd.append('isTopTitle', "0");
          }
          if(this.isLeftTitle){
              fd.append('isLeftTitle', "1");
          }else{
              fd.append('isLeftTitle', "0");
          }


          fd.append('items', JSON.stringify(this.items));


          for( var i = 0; i < this.images.length; i++ ){
            let image = this.images[i];
            fd.append('images[' + i + ']', image);
          }

          axios.post(process.env.VUE_APP_API_DOMAIN + "/question_fill_table_add",fd,{ headers: { 'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            
            response => {
              //console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Question is added successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }
            }  
          );
        }

      },

      vtoastCallback() {
        this.$router.push(`/eisadmin/questions/${this.leafletId}`);
      },
      changeCover(){

        this.visible_image = false;
        this.visible_video = false;
        this.visible_sound = false;
        this.visible_text = false;
        
        if(this.selectedCover == "image"){
          this.visible_image = true;
        }
        if(this.selectedCover == "video"){
          this.visible_video = true;
        }
        if(this.selectedCover == "sound"){
          this.visible_sound = true;
        }
        if(this.selectedCover == "text"){
          this.visible_text = true;
        }
      },
      chipColor(used) {
        return used == "1" ? "primary" : "";
      },

      deleteItem(itemId){
        let i = 0;
        this.items.forEach(e => {
          if(e.itemId == itemId){
            this.items.splice(i, 1);
          }
          i++;
        });

      },


      
      
    },


    created() {

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.leafletId = this.$route.params.leafletId;
      this.getData();
    },

    watch: {
      

    }
  }
</script>
