<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>CLASSROOMS</div>
          </v-card-title>
          <v-card-subtitle><a href="/eisadmin/students" class="link_blue">Students</a> > {{studentName}}</v-card-subtitle>
          

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">Classroom</th>
                  <th class="text-left">School</th>
                  <th class="text-left">Teacher</th>
                  <th width="200"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in classes" :key="item.classId" >
                  
                    <td>{{ index +1 }}</td>
                    <td><v-btn :href="'/eisadmin/class_students/'+ item.classId" small color="accent" outlined >{{ item.className }}</v-btn></td>
                    <td>{{ item.schoolName }}</td>
                    <td>{{ item.teacherName }}</td>
                    <td class="text-right">

                        <v-btn small text @click="removeFromClass(item.classId) ">REMOVE</v-btn>

                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          
      </v-card>
    </div>


  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold  },
      }
    },

    data(){
      return {
        studentName: "",
        classes: [],
        teachers : [],

        schools_classes : [],
        teacherSelect: 0,
        classSelected: 0,
        dialog: false,
      }
    },

    methods: {


      getData() {
        
        const data = { studentId : this.$route.params.studentId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/student_classes",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            console.log(response.data);
            this.classes = response.data.classes;
            this.studentName = response.data.studentName;
          }  
        );

      },



      vtoastCallback() {
        //this.getTeachersData();
      },

      async removeFromClass(classId) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to remove this student from this class?"
          )
        ){

          const data = { classId : classId, studentId : this.$route.params.studentId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/student_deletefromclass",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Student removed from class succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.getData();
              }
            }  
          );

          
        }
      }

    },

    watch: {
      schoolSelect: function (newValue, oldValue) {
        if(newValue != oldValue){ this.getClassData(); }
      },
      dialog: function (newValue) {
        if(newValue == true){ this.getAllTeachersData(); }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

