<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />

    <div class="pa-5" >
      
      <v-card elevation="0" outlined>
        <v-card-title>CREATE NEW UNIT - {{this.bookName}}</v-card-title>

        <div class="pa-5"> 
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field v-model="unitName" outlined :rules="nameRules" label="Unit Name" required ></v-text-field>
          </v-form>
        </div>

        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <v-btn class="float-right" @click="submit" color="primary" elevation="0" >CREATE</v-btn>
        </v-card-actions>

      </v-card>

    </div>

</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiGithub, mdiAlertRhombus, mdiCheckBold
  } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';


  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline, mdiGithub,},
      }
    },

    data: () => ({
      valid: true,
      bookName: '',
      unitName: '',
      nameRules: [
        v => !!v || 'Unit name is required',
        v => (v && v.length >= 1) || 'Name must be more than 1 characters',
      ]
    }),

    methods: {
      getBookData() {
        
        const data = { bookId : this.$route.params.bookId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/book",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.bookName = response.data.book.bookName;
          }  
        );

      },
      submit () {
        
        if(this.$refs.form.validate()){
          
          const data = {
            unitName : this.unitName,
            bookId : this.$route.params.bookId
          }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/unit_add",JSON.stringify(data)
          ,{
              headers: {
                'Authorization': 'Bearer ' + this.$store.getters.getAccessToken  
              }
            }
          ).then(
            response => {

              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Unit is added successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }

            }  

          );
          
        }

      },
      vtoastCallback() {
        //vtoastCallback
        this.$router.push(`/eisadmin/units/${this.$route.params.bookId}`);
      }
      
    },


    created() {

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.getBookData();
    },
  }
</script>
