<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>UNITS</div>
            <v-spacer></v-spacer>
            <v-btn small color="accent" elevation="0" :href="'/eisadmin/unit_add/'+this.$route.params.bookId" >NEW UNIT</v-btn>
          </v-card-title>
          <v-card-subtitle><a href="/eisadmin/books" class="link_blue">Books</a> > {{bookName}}</v-card-subtitle>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left"></th>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">Unit Name</th>
                  <th class="text-left">Leaflets</th>
                  <th width="200"></th>
                </tr>
              </thead>


                <draggable :list="units" tag="tbody" @end="endDrag(units)" >

                  <tr v-for="(item, index) in units" :key="item.unitId" >
                    
                      <td><v-icon class="dragIcon">{{icons.mdiDrag}}</v-icon></td>
                      <td>{{ index +1 }}</td>
                      <td>{{ item.unitName }}</td>
                      <td><v-btn :href="'/eisadmin/leaflets/'+ item.unitId" small color="accent" outlined>{{ item.leafletCount }}</v-btn></td>
                      <td  >
                          <div class="text-right">
                          <v-btn v-if="item.leafletCount == 0" small text @click="deleteUnit(item.unitId, index)">DELETE</v-btn>
                          <v-btn small text :href="'/eisadmin/unit_edit/' + item.unitId">EDIT</v-btn>
                          </div>
                      </td>
                  </tr>

                </draggable>

            </template>
          </v-simple-table>

      </v-card>
    </div>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold,mdiDrag  } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';
  import draggable from 'vuedraggable';


  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm, draggable },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold ,mdiDrag },
      }
    },

    data(){
      return {
        bookId : this.$route.params.bookId,
        bookName: '',
        units: []
      }
    },
    methods: {
      getData() {
        
        const data = { bookId : this.$route.params.bookId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/units",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.units = response.data.units;
            this.bookName = response.data.bookName;

          }  
        );

      },

      endDrag(temp_units){
        let new_order = [];
        let i = 1;
        temp_units.forEach(e => {
          const _u = {
            unitId: e.unitId,
            orderNo: i
          }
          new_order.push(_u);
          i++;
        });

        const data = { unitOrders : new_order }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/unit_reorder",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } });
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },

      async deleteUnit(unitId, index) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to delete this unit?"
          )
        ){

          const data = { unitId : unitId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/unit_delete",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Unit deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.units.splice(index, 1);
              }
            }  
          );

          
        }
      }

    },

    watch: {
      page: function (newValue, oldValue) {
        if(newValue != oldValue){
          this.getData();
        }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

<style>
  .dragIcon{
    cursor: grab;
  }
</style>
