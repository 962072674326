<template>
    <div class="row mt-5 ">
        <div class="col-12 ">

            <div class="f_14 mb-3" v-if="leafletId != '0'">
                Filter : {{leafletName}} <a href="#" class="link_blue ml-2" @click="showAllRes()">(Show All)</a>
            </div>

            <v-simple-table v-if="results.length >= 1" >
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Leaflets Name</th>
                        <th class="text-left">Date / Duration</th>
                        <th class="text-left">Questions</th>
                        <th class="text-left">Correct</th>
                        <th class="text-left">Wrong</th>
                        <th class="text-left">Success%</th>
                        <th class="text-left"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in results" :key="result.sessionId">
                        <td class="tabs_border">
                            <div class=" font_bold ">
                                {{ result.unitName }} > {{ result.leafletName }}
                                <v-chip v-if="result.homeworkId != '0'" color="green" class="ml-2 font_regular" small>homework</v-chip>
                            </div>
                        </td>
                        <td class="tabs_border">
                            <div >{{result.startDate }}</div>
                            <div v-if="result.duration >= 0" class="  ">{{result.duration }} Min.</div>
                        </td>
                        <td class="tabs_border">
                            <div >{{result.questionCount}}</div>
                        </td>
                        <td class="tabs_border">
                            <div >{{result.correctPoint }}</div>
                        </td>
                        <td class="tabs_border">
                            <div >{{result.wrongPoint }}</div>
                        </td>
                        <td class="tabs_border">
                            <div >{{result.successPoint}}</div>
                        </td>
                        <td class=" text-right tabs_border">
                            <v-btn href="#" @click="openDateModal(result.sessionCode,result.homework_statu,result.homeworkId)" small elevation="0" color="info">DETAILS</v-btn>
                        </td>

                    </tr>

                </tbody>
                </template>
            </v-simple-table>

            <div v-if="results.length == 0" class="mt-8 text-center">
                <div class="font_bold f_24">No result found!</div>
            </div>
                
        </div>

        <v-dialog v-model="dialogResults" max-width="600px">
            <v-card>
            
                <div class="d-none d-sm-block">
                    <v-card-title class="text-h5 ">
                        <v-btn color="grey" small outlined @click="dialogResults = false" >Close</v-btn>  
                        <v-spacer></v-spacer>
                        <v-btn color="green" class="mr-3" small outlined @click="correctAnswers" >Correct Answers</v-btn>  
                        <v-btn color="blue" small outlined @click="studentAnswers" >Your Answers</v-btn>
                    </v-card-title>
                </div>
                <div class="text-center d-block d-sm-none">
                    <v-card-title class="text-h5 ">
                        <v-btn color="grey" class="mr-3" small outlined @click="dialogResults = false" >X</v-btn>  
                        <v-btn color="green" class="mr-3" small outlined @click="correctAnswers" >Answers</v-btn>  
                        <v-btn color="blue" small outlined @click="studentAnswers" >Yours</v-btn>  
                    </v-card-title>
                </div>
            

            <v-card-text>
                <div style="height:400px; overflow-y:auto; overflow-x:hidden ">
                    <Pair_click_paragraph ref="childComponentRef" v-if="whichQuestionType('pair_click_paragraph')" :key="componentKey" :sessionCode="selectedSessionCode" :questionId="selectedQuestionId"  />
                    <Pair_click_list2 ref="childComponentRef" v-if="whichQuestionType('pair_click_list2')" :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <Pair_click_list ref="childComponentRef" v-if="whichQuestionType('pair_click_list') " :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <Fill_paragraph ref="childComponentRef" v-if="whichQuestionType('fill_paragraph') " :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <Fill_list ref="childComponentRef" v-if="whichQuestionType('fill_list') " :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <Fill_table ref="childComponentRef" v-if="whichQuestionType('fill_table') " :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <Order_words ref="childComponentRef" v-if="whichQuestionType('order_words') " :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <Group_words ref="childComponentRef" v-if="whichQuestionType('group_words') " :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId"/>
                    <Dropdown_paragraph ref="childComponentRef" v-if="whichQuestionType('dropdown_paragraph') " :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <Dropdown_list ref="childComponentRef" v-if="whichQuestionType('dropdown_list') " :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <Multiple_choice ref="childComponentRef" v-if="whichQuestionType('multiple_choice')" :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <True_false ref="childComponentRef" v-if="whichQuestionType('true_false')" :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <Free_write ref="childComponentRef" v-if="whichQuestionType('free_write')" :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <Images_fill ref="childComponentRef" v-if="whichQuestionType('images_fill')" :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                    <Images_click ref="childComponentRef" v-if="whichQuestionType('images_click')" :key="componentKey" :sessionCode="selectedSessionCode"  :questionId="selectedQuestionId" />
                </div>

            </v-card-text>
            <v-card-actions class="text-center" >
                <div>
                <span  v-for="(question, index) in questions" :key="question.questionId">
                <v-chip v-if="question.questionId != selectedQuestionId " class="mr-1 mt-1" @click="selectQuestion(question.questionId)">{{ index+1 }}</v-chip>
                <v-chip v-if="question.questionId == selectedQuestionId " class="mr-1 mt-1" text-color="white" color="green" @click="selectQuestion(question.questionId)">{{ index+1 }}</v-chip>
                </span>
                </div>

            </v-card-actions>
            </v-card>
        </v-dialog>
        
    </div>
</template>



<script>
import { mdiPlusCircleOutline ,mdiAlertCircle } from '@mdi/js'
import axios from 'axios';

import Pair_click_paragraph from '../../student/components_question_results/Pair_click_paragraph';
import Pair_click_list from '../../student/components_question_results/Pair_click_list';
import Pair_click_list2 from '../../student/components_question_results/Pair_click_list2';
import Fill_paragraph from '../../student/components_question_results/Fill_paragraph';
import Fill_list from '../../student/components_question_results/Fill_list';
import Fill_table from '../../student/components_question_results/Fill_table';
import Order_words from '../../student/components_question_results/Order_words';
import Group_words from '../../student/components_question_results/Group_words';
import Dropdown_paragraph from '../../student/components_question_results/Dropdown_paragraph';
import Dropdown_list from '../../student/components_question_results/Dropdown_list';
import Multiple_choice from '../../student/components_question_results/Multiple_choice';
import True_false from '../../student/components_question_results/True_false';
import Free_write from '../../student/components_question_results/Free_write';
import Images_fill from '../../student/components_question_results/Images_fill';
import Images_click from '../../student/components_question_results/Images_click';

export default {
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        results : [],
        leafletName : "",
        uid: "",
        dialogResults: false,
        componentKey: 0,

        leafletId:0,
        homeworkId:0,

        questions : [],

        selectedStudentId : 0,
        selectedStudentName : "",
        selectedQuestionId : 0,
        selectedSessionCode : "",
    }),
    components: {
        Pair_click_paragraph, Pair_click_list, Fill_paragraph, Fill_list, Fill_table, Order_words, Group_words , Dropdown_paragraph,
        Dropdown_list, Multiple_choice, True_false, Free_write, Images_fill, Images_click, Pair_click_list2
    },
    props: {
        bookId: String
    },
    methods: {

        correctAnswers(){
            this.$refs.childComponentRef.correctAnswers( );
        },
        studentAnswers(){
            this.$refs.childComponentRef.studentAnswers( );
        },

        reload() {
            this.componentKey += 1;  
            this.$forceUpdate();
        },

        selectQuestion(id){
            this.reload();
            this.selectedQuestionId = id
        },

        
        whichQuestionType(typ){
            let res = false;
            this.questions.forEach(e => {
            if(e.questionId == this.selectedQuestionId){
                if(e.questionType == typ){
                res = true;
                } 
            }
            });


            return res;
        },

        openDateModal(sessionCode,homework_statu,hwId){

            let sh = "1";

            if(hwId == "0"){
                sh = "1";
            }else{

                if(homework_statu == 'finished'){
                    sh = "1";
                }else{
                    sh = "0";
                }

            }

            if(sh == "1"){

                const data = {sessionCode : sessionCode }
                axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_leaflet",JSON.stringify(data)
                ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
                .then(
                response => {
                    this.questions = response.data.questions;
                    this.selectedQuestionId = this.questions[0].questionId;
                    this.selectedSessionCode = sessionCode;
                    this.homeworkId = response.data.homeworkId;
                    this.dialogResults = !this.dialogResults; 

                }  
                );
            
            }else{
                this.$root.Vtoast.show({message: 'You can\'t see the results until the homework finishes!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
            }
            
            

        },

        showAllRes(){


            this.$router.push('/student/book/'+this.bookId+'/tabResults/0');
            window.location.reload()

        },

        getResults(){

            this. results = [];

            const data = { bookId : this.bookId, leafletId : this.leafletId }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_book_results",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {
                    console.log(response.data);
                    this.results = response.data.results;
                    this.leafletName = response.data.leafletName;
                }  
            );

        }

    },
    setup() {
        return {
        icons: { mdiPlusCircleOutline ,mdiAlertCircle},
        }
    },
    created () {
        this.uid = this.$store.getters.getUid;
        this.selectedStudentId = this.$store.getters.getUid;
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted(){
        this.getResults();
    },
    watch:{
        dialogResults:function(newValue){
            if(!newValue){
                this.$refs.childComponentRef.stopVideo();
            }
        }
    }
}
</script>

