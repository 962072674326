<template>
  <div class=" py-2 bg_header">
    <v-card  tile flat color="transparent" >
      <v-container class="pa-0" >
        <v-toolbar color="transparent" flat>
          <a href="/teacher/classes" >
          <v-img :src="require('@/assets/images/EISEnglish_logo.png')" max-width="160px" class=""></v-img>
          </a>
          

          <div class="ml-15 text-center d-none d-sm-block">

            <a href="/teacher/classes" :class="headerMenuColor('classes')">
              <v-icon size="28" :class="headerMenuIconColor('classes')"  >{{icons.mdiAccountDetails   }}</v-icon> My Classes
            </a>

            <a href="/teacher/library" :class="headerMenuColor('library')" >
              <v-icon size="28" :class="headerMenuIconColor('library')" > {{ icons.mdiBookEditOutline  }}</v-icon> My Library
            </a>

          </div>
          
          <v-spacer></v-spacer>


          <span class="d-none d-sm-block">
            <v-btn icon depressed to="/teacher/messages" class="mr-3">
              <v-icon size="24" class="">{{ icons.mdiEmailOutline}}</v-icon>
            </v-btn>
          </span>


          <v-menu  bottom left offset-y  max-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="d-none d-sm-block" icon v-bind="attrs" v-on="on">
                <v-avatar color="teal" size="40"><span class="white--text f_16 ">{{initials}}</span></v-avatar>
              </v-btn>
              <v-icon size="30"  v-bind="attrs" v-on="on" class="d-block d-sm-none">{{ icons.mdiMenu   }}</v-icon>
            </template>
            <v-list >

              <div class="mx-2 d-block d-sm-none"><v-btn to="/teacher/classes" text block >My Classes</v-btn></div>
              <div class="mx-2 d-block d-sm-none"><v-btn to="/teacher/library" text block >My Library</v-btn></div>
              <div class="mx-2 d-block d-sm-none"><v-btn to="/teacher/messages" text block >Messages</v-btn></div>

              
              <div class="mx-2"><v-btn to="/teacher/settings" text block >MY PROFILE</v-btn></div>
              <div class="mx-2"><v-btn to="/teacher/logout" text block >LOGOUT</v-btn></div>
              
            </v-list>
          </v-menu>

        </v-toolbar>
      </v-container>
    </v-card>
  </div>

</template>

<script>
import { mdiLogout, mdiBellOutline, mdiBookEditOutline  ,mdiAccountDetails , mdiEmailOutline ,mdiMenu   } from '@mdi/js'

export default {
  data: () => ({
      valid: true,
      name : '',
      initials: '',
      page : ""
  }),
  methods: {

    headerMenuColor(gln){
      let cls = "header_menu_selected mx-3";
      if(gln != this.page){
          cls = "header_menu_normal mx-3 ";
      }
      return cls;
    },

    headerMenuIconColor(gln){
      let cls = "header_menu_selected_icon mr-1";
      if(gln != this.page){
          cls = "black--text mr-1";
      }
      return cls;
    },

    handleDrawer() {

      if(this.$store.getters.getAdminDrawer){
        this.$store.commit('setAdminDrawer', false);
      }else{
        this.$store.commit('setAdminDrawer', true);
      }

    },
    checkDrawer(){
      //console.log("checkDrawer");
      //console.log(this.$store.getters.getAdminDrawer);
      //console.log(this.$store.getters.getUserGroup);
    }
  },
  setup() {
    return {
      icons: { mdiLogout , mdiBellOutline ,mdiBookEditOutline , mdiAccountDetails , mdiEmailOutline, mdiMenu   },
    }
  },
  created () {
    this.initials =  this.$store.getters.getInitials;
    this.name =  this.$store.getters.getName;
    console.log(this.$route.name);

    if(this.$route.name == "teacher_Classes"){
      this.page = "classes";
    }else if(this.$route.name == "teacher_Library"){
      this.page = "library";
    }
  },
}
</script>

