<template>
  <div class="row mt-5">
    <div class="col-2">
        <v-img height="130" :src="VUE_APP_BOOK_MEDIA+bookCover" contain  position="center top"  />
    </div>
    <div class="col-10">
      <div class="f_24 font_bold color_pink">{{bookName}}</div>
      <div class="mt-1 font_bold color_black">{{bookSubtitle}}</div>
      <div class="f_14 color_muted mt-1">{{bookDescription}}</div>
      <div class="f_14 color_muted mt-1">Book Code: {{bookCode}}</div>
      <div class="f_14 color_muted mt-1">{{bookCodeDates}}</div>
    </div>
  </div>

</template>

<script>
import {} from '@mdi/js'

export default {
  props: {
        bookCover: String,
        bookName : String,
        bookSubtitle : String,
        bookDescription : String,
        bookCode : String,
        bookCodeDates : String,
  },
  data: () => ({
    VUE_APP_API_DOMAIN : "",
    VUE_APP_BOOK_MEDIA : process.env.VUE_APP_BOOK_MEDIA
  }),
  methods: {

    
  },
  setup() {
    return {
      icons: { },
    }
  },
  created () {
    this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
  },
}
</script>

