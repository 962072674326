<template>
    <div class="pa-2">
        
        <div class="text-right mt-3">
            <v-btn elevation="1" class="mr-2" color="info" text-color="white" small @click="correctQuestion()">Correct Answers</v-btn>
            <v-btn elevation="1"  color="primary" small @click="resetQuestion()">Reset</v-btn>
        </div>

        <div class="f_20 font_bold">{{questionOrder}} - {{questionTitle}}</div>
        
        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w"  controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>
        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>

        <ol class="mt-8" :type="listing_symbol" >
            <li class="mb-1"  v-for="item in true_false_items" :key='item.trueId'>
                <div class="row">
                    <div class="col-8"><span v-html='markup_do(item.text)'></span></div>
                    <div class="col-4 text-right">
                    
                        <v-chip label @click="selectItem(item.trueId,'true')" :class="findClass(item.trueId,'true')" color="white">
                            <v-icon :color="findIconColor(item.trueId,'true')" size="20" left>
                                {{findIcon(item.trueId,'true')}}
                            </v-icon>
                            <span class="color_black">True</span>
                        </v-chip>

                        <v-chip label @click="selectItem(item.trueId,'false')" :class="findClass(item.trueId,'false')" color="white">
                            <v-icon :color="findIconColor(item.trueId,'false')" size="20" left>
                                {{findIcon(item.trueId,'false')}}
                            </v-icon>
                            <span class="color_black">False</span>
                        </v-chip>
                    
                    </div>
                </div>
                
            </li>
        </ol>


        <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>


    </div>
</template>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import {  mdiCheckboxBlankOutline,mdiCheckboxMarked   } from '@mdi/js'
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'

export default {
    mixins: [markup],
    components: { Splide, SplideSlide  },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : "",
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        coverImages : null,
        listing_type: "",
        listing_symbol: "",
        true_false_items: null,

        dialogSound: false


    }),
    props: {
        questionId: String
    },
    methods: {
        test(){
            console.log(this.true_false_items);
        },

        correctQuestion(){


            this.true_false_items.forEach(e => {
                e.selected = e.answer
            });

            this.reload()
        },

        resetQuestion(){
            
            this.true_false_items.forEach(e => {
                e.selected =''
            });

            this.reload()

        },
        
        reload() {
            this.$forceUpdate();
        },

        selectItem(trueId,wh){
            this.true_false_items.forEach(element => {

                if(element.trueId == trueId){
                    element.selected  = wh
                }
                
            });

            this.reload()

        },

        findClass(trueId,wh){
            let res = "choice_line_div mr-2";
            this.true_false_items.forEach(element => {

                if(element.selected == wh && element.trueId == trueId){
                    res = "choice_line_div_selected mr-2";
                }
                
            });
            return res
        },
        findIcon(trueId,wh){
            let res = this.icons.mdiCheckboxBlankOutline;
            this.true_false_items.forEach(element => {

                if(element.selected == wh && element.trueId == trueId){
                    res = this.icons.mdiCheckboxMarked;
                }
                
            });
            return res
        },
        findIconColor(trueId,wh){
            let res = 'grey';
            this.true_false_items.forEach(element => {

                if(element.selected == wh && element.trueId == trueId){
                    res = 'blue darken-3';
                }
                
            });
            return res
        },

        getData(){
            const data = { questionId : this.questionId }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_question_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {
                    this.questionOrder = response.data.questionOrder;
                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.coverImages = response.data.coverImages;
                    this.true_false_items = response.data.true_false_items;

                    this.true_false_items.forEach(element => {
                        element.selected = '';
                    });

                }  
            );
        }

    },
    setup() {
        return {
        icons: { mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiFormatAlignLeft },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN
    },
    mounted() {
        this.getData();
    },
}
</script>