<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P">
        <v-container class="pt-0">


              <div class="d-flex flex-row mt-8">
                <v-icon size="30" class="black--text mr-3 mt-2" > {{ icons.mdiBookEditOutline }}</v-icon>
                <div class="f_24 font_bold pt-1 black--text "> My Classes</div>
                <v-spacer></v-spacer>
                <v-btn outlined class="ma-2 rounded-pill btn_outline_pink" @click="openCreateDialog">
                  <v-icon left  >{{icons.mdiPlusCircleOutline }}</v-icon> New Class
                </v-btn>
              </div>


              <div class="row mt-3">
                <div v-for="(item) in classes" :key="item.classId" class="col-md-6 ">
                  <v-card :class="bbClass(item.codeStatus)">
                    <a href="#" @click="dialogEdit = !dialogEdit; classSelect = item.classId" class="position-absolute" style="top:5px; right:5px">
                    <v-icon   >{{icons.mdiPlaylistEdit  }}</v-icon></a>
                    <div class="row">
                      <div class="col-md-4 pa-0"><v-img :src="VUE_APP_BOOK_MEDIA+item.bookCover"  contain position="center top" height="230" /></div>
                      <div class="col-md-8">
                        
                        <div class=" px-4">
                          <div class="color_black font_bold f_20">Class {{item.className}}</div>
                          <div class="color_muted f_14">{{item.schoolName}}</div>
                          <div class="color_pink font_medium mt-2">{{item.bookName}}</div>
                          <div class="mt-2"><v-btn @click="goToClass(item.classId,item.codeStatus)" href="#"  rounded color="error" >View Class</v-btn></div>
                          <div class="mt-4">
                            <span class="classkey_border px-2 py-1 f_14 color_black">Class Key: {{item.classKey}}</span>
                          </div>
                          <div class="f_14 mt-3"><v-icon size="20" >{{icons.mdiCalendarRange }}</v-icon> {{item.startDate }} > {{item.endDate }}</div>
                          
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>

              <div v-if="classes.length == 0" class="mt-8 text-center">
                <div class="font_bold f_24">No class found!</div>
                <div>You can create classes for your purchased books. </div>
                <div>First add a book, then you can create a class.</div>
              </div>



          <v-dialog v-model="dialogClass" persistent max-width="600px">
            <v-card>
              <v-card-title class="text-h5">Add New Class</v-card-title>
              <v-card-text>

                <div v-if="step == 2">
                  <v-text-field label="Class Name" outlined hide-details v-model="newClassName"></v-text-field>
                  <v-select hide-details v-model="schoolSelect" :items="schools" item-text="schoolName" item-value="schoolId" class="mt-4 mb-4"  label="Select School"  outlined  ></v-select>
                  
                  <v-menu  ref="menuEnd"  class="mt-4" v-model="menuEnd" :close-on-content-click="false" :return-value.sync="dateEnd" transition="scale-transition" offset-y min-width="auto">
                    <template  v-slot:activator="{ on, attrs }">
                      <v-text-field  hint="MM/DD/YYYY format"  v-model="dateEnd" label="End Date" outlined hide-details readonly v-bind="attrs" v-on="on" ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateEnd" no-title scrollable :max="maxSelectebleDate">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuEnd = false">Cancel</v-btn>
                      <v-btn color="primary" @click="$refs.menuEnd.save(dateEnd)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div v-if="step == 1">
                  
                  <div class="row my-3" v-for="item in books" :key="item.bookId">

                      <div v-if="item.codeStatus == 'used'" class="col-1 text-center" >
                        <a href="#" @click="selectBook(item.bookId)"><v-icon  color="blue"  >{{whichIcn(item.bookId) }}</v-icon></a>
                      </div>
                      <div v-if="item.codeStatus == 'used'" class="col-3"><v-img :src="VUE_APP_BOOK_MEDIA+item.bookCover" contain position="center top" height="80" /></div>
                      <div v-if="item.codeStatus == 'used'" class="col-8">
                        <div class="color_blue f_16 font_bold">{{item.bookName}}</div>
                        <div class="f_14 color_muted">{{item.bookSubtitle}}</div>
                      </div>

                  </div>

                </div>
                
              </v-card-text>
              <v-card-actions>
                <v-btn color="grey" text @click="dialogClass = false; step = 1" >Close</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" v-if="step == 2" elevation="0" @click="addNewClass" >SAVE</v-btn>
                <v-btn color="primary darken-1" v-if="step == 1" elevation="0" @click="nextstep" >Next</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEdit" persistent max-width="600px">
            <v-card>
              <v-card-title class="text-h5">Edit Class</v-card-title>
              <v-card-text>

                <v-text-field label="Class Name" outlined hide-details v-model="classSelectName"></v-text-field>
                <v-select hide-details v-model="classSelectSchool" :items="schools" item-text="schoolName" item-value="schoolId" class="mt-4 mb-4"  label="Select School"  outlined  ></v-select>
                
              </v-card-text>
              <v-card-actions>
                <v-btn color="grey" text @click="dialogEdit = false; step = 1" >Close</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1"  elevation="0" @click="saveClass" >SAVE</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-container>
      </div>

  </v-app>

</template>
 
<script>
  import checkAuthTeacher from '../../mixins/checkAuthTeacher';
  import TopBar from '../../views/teacher/components/TopBar'
  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup 
  ,mdiCheckBold, mdiAlertCircle, mdiMagnify,mdiRadioboxBlank,
  mdiRadioboxMarked,mdiCalendarRange,mdiPlaylistEdit      } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/teacher/components/Vtoast';
  import Confirm from '../../views/teacher/components/Confirm.vue';

  export default {
    mixins: [checkAuthTeacher],
    components: {
      TopBar,  Vtoast, Confirm
    },
    setup() {
      return {
        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,mdiCalendarRange,
          mdiMagnify,mdiRadioboxBlank ,mdiRadioboxMarked ,mdiPlaylistEdit 
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      VUE_APP_BOOK_MEDIA : process.env.VUE_APP_BOOK_MEDIA,
      uid: "",
      tab: null,
      name : '',
      initials: '',
      dialogClass: false,
      dialogEdit: false,
      dialogKey: false,
      classKey: "",
      newClassName: '',
      schools:[],
      schoolSelect: 0,

      email_approved : 1,

      books:[],
      bookSelect: 0,
      classes:[],
      page: 1,
      paginationLenght : 0,

      menuEnd: false,
      dateEnd: null,
      hourEnd:null,
      minuteEnd:null,
      hours:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
      minutes:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59],

      step : 1,
      bookEndtime : 0,
      maxSelectebleDate : "",

      classSelect: 0,
      classSelectName: "",
      classSelectSchool: 0,

    }),
    methods:{

      openCreateDialog (){
        if(this.books.length == 0){
          this.$root.Vtoast.show({message: 'You must have a book to create a class!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
        }else{
          this.dialogClass = !this.dialogClass
        }
      },

      goToClass(classId,status){
        if(status == "0"){
          this.$root.Vtoast.show({message: 'Your book code is expired!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
        }else{
          this.$router.push(`/teacher/class/${classId}`);
        }
        
      },

      bbClass(st){
        let cls = "ma-2";
        if(st == "0"){
          cls = "ma-2 opa50";
        }
        return cls
      },

      whichIcn(bookId){
        let icn = this.icons.mdiRadioboxBlank;
        if(bookId == this.bookSelect){
          icn = this.icons.mdiRadioboxMarked;
        }
        return icn;
      },

      selectBook(bookId){
        this.bookSelect = bookId
      },

      nextstep(){

        if(this.bookSelect == 0){
            this.$root.Vtoast.show({message: 'You must select a book!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
        }else{


          this.books.forEach(element => {

            if(element.bookId == this.bookSelect){
              console.log("---"+element.time_end);
              this.bookEndtime = element.time_end;
              const dd=  new Date(element.time_end * 1000);
              this.maxSelectebleDate = dd.getFullYear()+ "-" + ("0" + (dd.getMonth() + 1)).slice(-2) + "-" + ("0" + (dd.getDate())).slice(-2);
              console.log(this.maxSelectebleDate);
            }

          });

          this.step = 2;
          

        }
        
      },

      showKey(index){
        console.log("showKey");
        this.classKey = this.classes[index].classKey
      },
      
      addNewClass(){

          if(this.dateEnd == null || this.dateEnd == "" || this.newClassName == "" || this.schoolSelect == 0){
            this.$root.Vtoast.show({message: 'You must fill all fields!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
          }else{

            let sp = this.dateEnd.split("-");
            let dateSn = new Date(sp[0], parseInt(sp[1])-1, parseInt(sp[2]), 12, 12, 0, 0).getTime() / 1000;


            if(dateSn >= this.bookEndtime){
              this.$root.Vtoast.show({message: 'You can\'t select further date from book\'s expiration date!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
            }else{

              const data = {
                className : this.newClassName,
                dateEnd : dateSn,
                schoolId : this.schoolSelect,
                bookId : this.bookSelect
              }
              axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_class_add",JSON.stringify(data)
              ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
              .then(
                response => {
                  console.log(response.data);
                  if(response.data.result == "fail"){
                    this.$root.Vtoast.show({message: 'An error occured!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
                  }else{
                    this.$root.Vtoast.show({message: 'Class is added succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold });
                    this.getData();
                    this.step = 1;
                    this.dateEnd = null;
                    this.newClassName = "";
                    this.schoolSelect = 0;
                    this.bookSelect = 0;
                    this.dialogClass = false;
                  }
                }  
              );
            }

          }





      },

      saveClass(){
        if(this.classSelectName == "" ){
          this.$root.Vtoast.show({message: 'You need to write a name for the class!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
        }else{
              const data = {
                className : this.classSelectName,
                schoolId : this.classSelectSchool,
                classId : this.classSelect
              }
              axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_class_save",JSON.stringify(data)
              ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
              .then(
                response => {
                  if(response.data.result == "fail"){
                    this.$root.Vtoast.show({message: 'An error occured!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
                  }else{
                    this.$root.Vtoast.show({message: 'Class is saved succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold });
                    this.getData();
                  }
                  this.dialogEdit = false;
                }  
              );
        }
      },

      getData(){
          const data = { page : this.page }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_classes",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              this.classes = response.data.classes;
              this.paginationLenght = Math.ceil(response.data.itemCount / 10);

              if(response.data.email_approved == "0" ){
                this.$router.push('/teacher/approve_email');
              }

            }  
          );

          const data2 = { page : this.page }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_books",JSON.stringify(data2)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              this.books = response.data.books;
            }  
          );

          const data3 = {  }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_schools",JSON.stringify(data3)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              this.schools = response.data.schools;
            }  
          );
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },


    },

    watch: {
      classSelect: function (newValue, oldValue) {
        if(newValue != oldValue){
          
          this.classes.forEach(element => {
            if(element.classId == this.classSelect){
              console.log(element);
              this.classSelectName = element.className
              this.classSelectSchool = element.schoolId
            }
          });

        }
      }
    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    },
    

  }
</script>