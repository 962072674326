<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P">
      <v-container class="pt-0">

            


            <div class="d-flex flex-row mt-8">
              <v-icon size="30" class="color_black mr-3 mt-2" > {{ icons.mdiBookEditOutline }}</v-icon>
              <div class="f_24 font_bold pt-1 color_black "> My Library</div>
              <v-spacer></v-spacer>
              <v-btn outlined class="ma-2 rounded-pill btn_outline_blue" @click="dialogBook = !dialogBook">
                <v-icon left  >{{icons.mdiPlusCircleOutline }}</v-icon> Add New Book
              </v-btn>
            </div>


            <div class="row mt-3">
              <div v-for="item in books" :key="item.bookId" class="col-md-3 ">
                <v-card :class="bbClass(item.codeStatus)">
                  <div><v-img :src="VUE_APP_BOOK_MEDIA+item.bookCover" class="" contain position="center top" height="300" /></div>
                  <div class="mx-3 my-2">
                    <div class="color_blue f_20 font_bold">{{item.bookName.slice(0, 20) + (item.bookName.length > 20 ? "..." : "")}}</div>
                    <div class="f_14 color_muted">{{item.bookSubtitle}}</div>
                    
                  </div>
                  <div class="row mx-3 my-2 ">
                    <div class="col-12 px-0 text-right"><a @click="goToBook(item.bookId,item.codeStatus)" href="#" class="link_blue f_14"   >Book Detail</a></div>
                  </div>
                </v-card>
              </div>
            </div>

            <div v-if="books.length == 0" class="mt-8 text-center">
              <div class="font_bold f_24">No book found!</div>
              <div>Please add a book to create a class.</div>
            </div>



        <v-dialog v-model="dialogBook" persistent max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Add New Book</v-card-title>
            <v-card-text><v-text-field outlined hide-details label="Book Code" v-model="newBookCode"></v-text-field></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="dialogBook = false" >Close</v-btn>
              <v-btn color="primary darken-1" elevation="0" @click="addNewBook()" >Add</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
              


      </v-container>
      </div>

  </v-app>

</template>
 
<script>
  import checkAuthTeacher from '../../mixins/checkAuthTeacher';
  import TopBar from '../../views/teacher/components/TopBar'
  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup ,mdiCheckBold, mdiAlertCircle ,mdiMagnify   } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/teacher/components/Vtoast';
  import Confirm from '../../views/teacher/components/Confirm.vue';

  export default {
    mixins: [checkAuthTeacher],
    components: {
      TopBar,  Vtoast, Confirm
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup ,
          mdiMagnify 
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      VUE_APP_BOOK_MEDIA : process.env.VUE_APP_BOOK_MEDIA,
      uid: "",
      userName: "",
      tab: null,
      name : '',
      initials: '',
      dialogBook: false,
      newBookCode: '',
      books:[],
      page: 1,
      paginationLenght : 0
      

    }),
    methods:{
      goToBook(bookId,status){
        if(status == "finished"){
          this.$root.Vtoast.show({message: 'Your book code is expired!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
        }else{
          this.$router.push(`/teacher/book/${bookId}`);
        }
        
      },

      bbClass(st){
        let cls = "ma-2";
        if(st == "finished"){
          cls = "ma-2 opa50";
        }
        return cls
      },
      
      addNewBook(){
        console.log(this.uid);

        const data = {code: this.newBookCode  }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_book_add",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            console.log(response.data);
            if(response.data.result == "fail"){
              this.$root.Vtoast.show({message: 'An error occured!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
            }else if(response.data.result == "noBook"){
              this.$root.Vtoast.show({message: 'No code found available!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
            }else if(response.data.result == "still"){
              this.$root.Vtoast.show({message: 'You can\'t add the same book again!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
            }else{
              this.$root.Vtoast.show({message: 'Book is added succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold });
              this.books = [];
              this.getBookData();
              this.dialogBook = false;
            }
          }  
        );

      },

      getBookData(){
          const data = { page : this.page }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_books",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              this.books = response.data.books;
              this.paginationLenght = Math.ceil(response.data.itemCount / 10);
            }  
          );
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },


    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
      this.userName = this.$store.getters.getName;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getBookData();

    },
    

  }
</script>