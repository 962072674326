<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>LEAFLETS</div>
            <v-spacer></v-spacer>
            <v-btn small color="accent" elevation="0" :href="'/eisadmin/leaflet_add/'+this.$route.params.unitId" >New Leaflet</v-btn>
          </v-card-title>
          <v-card-subtitle><a href="/eisadmin/books" class="link_blue">Books</a> > <a :href="'/eisadmin/units/'+bookId" class="link_blue">{{bookName}}</a> > {{unitName}} </v-card-subtitle>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left"></th>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">Leaflet Name</th>
                  <th class="text-left">Questions</th>
                  <th class="text-left">Total Points</th>
                  <th width="200"></th>
                </tr>
              </thead>

                <draggable :list="leaflets" tag="tbody" @end="endDrag(leaflets)" >
                  <tr v-for="(item, index) in leaflets" :key="item.leafletId" >
                      <td><v-icon class="dragIcon">{{icons.mdiDrag}}</v-icon></td>
                      <td>{{ index +1 }}</td>
                      <td>{{ item.leafletName }}</td>
                      <td><v-btn :href="'/eisadmin/questions/'+ item.leafletId" small color="accent" outlined>{{ item.questionCount }}</v-btn></td>
                      <td>{{ item.totalPoint}}</td>
                      <td>
                        <div class="text-right">
                          <v-btn v-if="item.questionCount == 0" small text @click="deleteLeaflet(item.leafletId, index)">DELETE</v-btn>
                          <v-btn small text :href="'/eisadmin/leaflet_edit/' + item.leafletId">EDIT</v-btn>
                        </div>
                      </td>
                  </tr>
                </draggable>

            </template>
          </v-simple-table>

      </v-card>
    </div>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold, mdiDrag } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';
  import draggable from 'vuedraggable';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm ,draggable },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold, mdiDrag  },
      }
    },

    data(){
      return {
        unitId : this.$route.params.unitId,
        bookId: '',
        bookName: '',
        unitName: '',
        leaflets: []
      }
    },
    methods: {
      getData() {
        
        const data = { unitId : this.$route.params.unitId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/leaflets",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.leaflets = response.data.leaflets;
            this.bookId = response.data.bookId;
            this.bookName = response.data.bookName;
            this.unitName = response.data.unitName;

          }  
        );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },

      async deleteLeaflet(leafletId, index) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to delete this leaflet?"
          )
        ){

          const data = { leafletId : leafletId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/leaflet_delete",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Leaflet deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.leaflets.splice(index, 1);
              }
            }  
          );

          
        }
      },

      endDrag(temp_leaflets){
        let new_order = [];
        let i = 1;
        temp_leaflets.forEach(e => {
          const _u = {
            leafletId: e.leafletId,
            orderNo: i
          }
          new_order.push(_u);
          i++;
        });

        const data = { leafletOrders : new_order }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/leaflet_reorder",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } });
      },

    },

    watch: {
      page: function (newValue, oldValue) {
        if(newValue != oldValue){
          this.getData();
        }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

