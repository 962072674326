<template>
    <div class="row mt-5 color_normal">
        <div class="col-12 ">


            <div class="my-4 mx-1 py-2 tabs_border  row">
                <div class="mt-2 font_bold">Students ({{students.length}})</div>

                <div class="ml-3" style="width:200px">
                    <v-text-field class="" hide-details outlined dense @keyup="searchStudent" v-model="searchKeyword" label="Search"></v-text-field>
                </div>

                <v-spacer></v-spacer>
                <v-btn  v-if="students.length >= 1" text @click="openMessageModal(0)"><v-icon class="mr-1">{{icons.mdiEmailOutline }}</v-icon> Send Message</v-btn>
            </div>

            <div v-if="students.length >= 1" >

                <v-data-table :headers="headers" :items="students" item-key="studentName" hide-default-footer>

                    <template v-slot:item="row">
                        <tr>
                            <td><v-avatar color="pink lighten-1" size="32"><span class="white--text f_12">{{row.item.initials}}</span></v-avatar></td>
                            <td><a href="#" class="link_blue font_bold" @click="openUserModal(row.item.studentId)">{{row.item.studentName}}</a></td>
                            <td class="font_bold">{{row.item.leafletCount}}</td>
                            <td class="font_bold">{{row.item.correctPoints}}</td>
                            <td class="font_bold">{{row.item.wrongPoints}}</td>
                            <td class="font_bold">{{row.item.successPoint}}</td>
                            <td>
                                <v-menu  bottom left offset-y  max-width="200">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" icon v-bind="attrs" v-on="on">
                                        <v-icon>{{icons.mdiDotsVertical}}</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list >
                                        <div class="mx-2"><v-btn text block :href="'/teacher/student_results/'+classId+'/'+bookId+'/'+row.item.studentId" >RESULTS</v-btn></div>
                                        <div class="mx-2"><v-btn text block @click="openUserModal(row.item.studentId)">SEE PROFILE</v-btn></div>
                                        <div class="mx-2"><v-btn text block @click="openMessageModal(row.item.studentId)">SEND MESSAGE</v-btn></div>
                                        
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                    </template>

                </v-data-table>

            </div>

            <div v-if="students.length == 0" class="mt-8 text-center">
                <div class="font_bold f_24">No student found yet!</div>
            </div>


        </div>

        <v-dialog v-model="dialogUser" max-width="450px">
            <v-card class="color_normal">
                <v-card-title class="text-h5">
                    <v-avatar color="pink lighten-1" size="40"><span class="white--text f_12">{{student.studentInitials}}</span></v-avatar>
                    <v-spacer></v-spacer>
                    <a href="#" @click="dialogUser = false" ><v-icon class="mr-1">{{icons.mdiClose }}</v-icon></a>
                </v-card-title>

                <v-card-text>
                    <div class="font_bold f_16">{{student.studentName}}</div>
                    <div>{{student.studentEmail}}</div>
                </v-card-text>

                <v-card-actions>
                    <div class="row">
                        <div class="col"><v-btn color="black" outlined @click="openMessageModal(selectedStudentId)" ><v-icon class="mr-1">{{icons.mdiEmailOutline }}</v-icon> Send Message</v-btn></div>
                        <div class="col text-right"><v-btn color="red" outlined elevation="0" @click="removeFromClass(selectedStudentId)" >Remove from Class</v-btn></div>
                    </div>
                </v-card-actions>

            </v-card>
        </v-dialog>
        
        <v-dialog v-model="dialogMessage" max-width="600px">
            <v-card class="color_normal">
                <v-card-title class="text-h5">Send Message</v-card-title>

                <v-card-text>
                    <v-switch v-model="toAll" label="To All Class Students" ></v-switch>
                    <v-autocomplete v-if="toAll == false"
                        v-model="values"
                        :items="students" 
                        item-value="studentId"
                        item-text="studentName"
                        outlined
                        chips small-chips
                        label="To"
                        multiple
                    ></v-autocomplete>
                    <v-textarea outlined label="Your Message" v-model="message" ></v-textarea>
                </v-card-text>

                <v-card-actions >
                    <v-spacer></v-spacer>
                    <v-btn color="black" outlined elevation="0" @click="sendMessage()" >Send</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        
    </div>
</template>

<script>
import { mdiPlusCircleOutline, mdiDotsVertical,mdiClose , mdiEmailOutline  ,mdiAlertCircle ,mdiCheckBold } from '@mdi/js'
import axios from 'axios';

export default {
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        students:[],
        dialogUser: false,
        dialogMessage: false,
        selectedStudentId : 0,
        student : {
            studentName : ""
        },
        searchKeyword: "",
        values: [],
        message : "",
        toAll : false,


        headers: [
            { text: 'Avatar', value: 'initials', sortable: false },
            { text: 'Name', value: 'studentName' },
            { text: 'Leaflets', value: 'leafletCount' },
            { text: 'Correct', value: 'correctPoints' },
            { text: 'Wrong', value: 'wrongPoints' },
            { text: 'Success%', value: 'successPoint'  },
            { text: 'Actions', value: 'actions', sortable: false },
        ],

    }),
    props: {
        classId: String,
        bookId : String
    },
    methods: {

        searchStudent(){


            if(this.searchKeyword.length >=3){

                const data = {searchKeyword : this.searchKeyword, classId : this.classId }
                axios.post(process.env.VUE_APP_API_DOMAIN + "/t_students_search",JSON.stringify(data)
                ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
                .then(
                    response => {
                        console.log(response.data);
                        this.students = response.data.students;
                    }  
                );

            }

            if(this.searchKeyword.length ==0){
                this.page = 1;
                this.students = [];
                this.getStudentData();
            }

        },

        calculateSuccess(correctPoints,wrongPoints){
            let res = "";
            if(parseInt(wrongPoints) == 0 ){
                res = "%0";
            }else{
                res = "%" + Math.round(100* parseInt(correctPoints) / (parseInt(correctPoints)+parseInt(wrongPoints) ));
            }
                

            return res;
        },

        sendMessage(){
            console.log(this.values);
            
            let ss = "1";

            if(this.message == ""){
                ss = "0";
                this.$root.Vtoast.show({message: 'You must write a message!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
            }

            if(this.toAll == false && this.values.length == 0){
                ss = "0";
                this.$root.Vtoast.show({message: 'You must select a student!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
            }

            if(ss == "1"){

                let users = [];
                this.values.forEach((key, value) => {
                    console.log(key+"-"+value);
                    users.push(key);
                });


                const data = { values : users, toAll : this.toAll, message :this.message , classId: this.classId }
                axios.post(process.env.VUE_APP_API_DOMAIN + "/t_send_message",JSON.stringify(data)
                ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
                .then(
                    response => {
                        console.log(response.data);
                        this.$root.Vtoast.show({message: 'Message has been sent succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold });
                        this.dialogMessage = false;
                        this.values = [];
                        this.message = "";
                        this.toAll = false;
                    }  
                );
            }
        },

        openUserModal(studentId){

            this.selectedStudentId = studentId;

            const data = { studentId : studentId }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/t_student",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {
                    console.log(response.data);
                    this.student = response.data.student;
                    this.dialogUser = !this.dialogUser; 
                    this.selectedStudentId = studentId
                }  
            );

            
        },

        openMessageModal(studentId){
            this.selectedStudentId = studentId
            this.values = [];
            this.values.push(studentId);
            this.dialogMessage = !this.dialogMessage; 
        },

        getStudentData(){

            const data = { classId : this.classId }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/t_class_students",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {
                    console.log(response.data);
                    this.students = response.data.students;

                }  
            );

        },

        removeFromClass(studentId){
            const data = { studentId : studentId, classId : this.classId  }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/t_class_students_remove",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                () => {
                    this.dialogUser = false;
                    this.getStudentData();
                }  
            );
        }

    

    },
    setup() {
        return {
        icons: { mdiPlusCircleOutline, mdiDotsVertical,mdiClose , mdiEmailOutline , mdiAlertCircle ,mdiCheckBold },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted(){
        this.getStudentData();
    }
}
</script>

