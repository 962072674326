<template>
  <div class="bg_login">
    <div class="auth-wrapper auth-v1 ">
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <div class="auth-inner">
        <v-card class="">

          <div class=" text-center pt-8" ><img src="@/assets/images/EISEnglish_logo.png" width="220"></div>

          <div class="text-center px-2 f_12 mb-1">Please enter your email address.</div>
          <div class="text-center px-2 f_12 mb-8">We will send you an email to reset your password.</div>

          <v-card-text>
              <v-text-field v-model="email" outlined label="Email Address" placeholder="Email Address" hide-details class="mb-3" ></v-text-field>
              <v-btn v-on:click="handleRegister" large block class="mt-4 btn_eis" >Continue</v-btn>
          </v-card-text>


        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {  mdiEyeOutline, mdiEyeOffOutline, mdiAlertRhombus, mdiCheckBold } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Vtoast from '../views/eisadmin/components/Vtoast';
import '@/styles/login.css'

  export default {
    components: {  Vtoast },
    setup() {
      const email = ref('')
      
      return {

        email,
        
        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline,
          mdiAlertRhombus,
          mdiCheckBold
        },
      }
    },
    data(){
      return{
        email : '',
        
      }
      
    },
    computed: {},
    methods:{
      vtoastCallback() { 
        console.log("vtoastCallback");
      },



      handleRegister() {

        const data = {
          email : this.email,
        }

        if(this.email == "" ){
          this.$root.Vtoast.show({message: 'Please enter your email address!', snackType: 'fail', color: 'error', icon: mdiAlertRhombus})
        }else{

              axios.post(process.env.VUE_APP_API_DOMAIN + "/forgot_password",JSON.stringify(data)
              ).then(
                () => {
                  this.$root.Vtoast.show({message: 'Please check your email address! We have sent an email to you!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                }  

              );
            
          

        }

      }
    },
    created() {
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;

    },
  }
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
