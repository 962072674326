<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />

    <div class="pa-5" >
      
      <v-card elevation="0" outlined>
        <v-card-title>EDIT MATERIAL - {{bookName}}</v-card-title>

        <div class="pa-5"> 
          <v-form ref="form" >
            <v-text-field v-model="materialName" outlined  label="material Name"  ></v-text-field>
            <v-textarea v-model="materialDescription" outlined  label="material Description"  ></v-textarea>
            <v-switch v-model="switch1" label="Only for teacher"></v-switch>
            <v-select v-model="materialType" @change="changeMaterial" :items="materialTypes" item-text="name" item-value="type" class="mt-2"  label="Type"  outlined  ></v-select>

            <v-text-field outlined v-model="video" v-if="visible_video"  label="Video Url"  placeholder="https://" ></v-text-field>
            <v-text-field outlined v-model="sound" v-if="visible_sound"  label="Sound Url"  placeholder="https://"></v-text-field>
            <v-text-field outlined v-model="pdf" v-if="visible_pdf"  label="Pdf Url"  placeholder="https://"></v-text-field>

            <v-textarea outlined v-model="soundContent" v-if="visible_sound"  label="Sound Text"  placeholder=""></v-textarea>

          </v-form>
        </div>

        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <v-btn :to="'/eisadmin/materials/'+bookId" color="error" small elevation="0" class="mb-2 mr-2 mt-2" >CANCEL</v-btn>
          <v-btn class="float-right" @click="submit" small color="primary" elevation="0" >SAVE</v-btn>
        </v-card-actions>

      </v-card>

    </div>

</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiGithub, mdiAlertRhombus, mdiCheckBold
  } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';


  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline, mdiGithub,},
      }
    },

    data: () => ({
      bookName: '',
      bookId: '',
      materialId : 0,
      materialName: '',
      materialDescription: '',
      video: '',
      sound: '',
      pdf: '',
      materialType: 'sound',
      materialTypes : [ 'video','sound','pdf'],

      visible_pdf: false,
      visible_video: false,
      visible_sound: true,
      soundContent: "",
      switch1 : false

    }),

    methods: {
      changeMaterial(){

        this.visible_pdf = false;
        this.visible_video = false;
        this.visible_sound = false;


        if(this.materialType == "video"){
          this.visible_video = true;
        }
        if(this.materialType == "sound"){
          this.visible_sound = true;
        }
        if(this.materialType == "pdf"){
          this.visible_pdf = true;
        }
      },

      getData() {
        
        const data = { materialId : this.materialId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/material",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            console.log(response.data);
            this.bookName = response.data.bookName;
            this.bookId = response.data.bookId;
            this.materialName = response.data.material.materialName;
            this.materialDescription = response.data.material.materialDescription;
            this.video = response.data.material.materialFile;

            this.soundContent = response.data.material.soundContent;
            this.materialType = response.data.material.materialType;

            if(response.data.material.materialType == "video"){
              this.video = response.data.material.materialFile;
              
            }
            if(response.data.material.materialType == "sound"){
              this.sound = response.data.material.materialFile;
              
            }
            if(response.data.material.materialType == "pdf"){
              this.pdf = response.data.material.materialFile;
            }

            if(response.data.teacherOnly == '1'){
              this.switch1 = true
            }

            this.changeMaterial()


          }  
        );

      },
      submit () {

        if(this.materialName == ""){
          this.$root.Vtoast.show({message: 'You must fill the necessary fields!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
        }else{

          const data = {
            materialId : this.materialId,
            materialName : this.materialName,
            materialDescription : this.materialDescription,
            materialType : this.materialType,
            video : this.video,
            sound : this.sound,
            pdf : this.pdf,
            soundContent : this.soundContent,
            bookId : this.$route.params.bookId,
            forTeacher : this.switch1
          }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/material_save",JSON.stringify(data),{
              headers: {
                'Authorization': 'Bearer ' + this.$store.getters.getAccessToken  
              }
            }
          ).then(
            response => {

              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Material is added successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }

            }  

          );

        }
          
          
          


      },
      vtoastCallback() {
        //vtoastCallback
        this.$router.push(`/eisadmin/materials/${this.bookId}`);
      }
      
    },


    created() {

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.materialId = this.$route.params.materialId;
      this.getData();
    },
  }
</script>
