<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    
    <div class="pa-5" >
      <h1>Welcome!</h1>
    </div>

    
</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'

  export default {
    mixins: [checkAuth],

    components: {
      LeftMenu,
      TopBar
    },
    setup() {
        return {
        icons: {
          mdiMagnify,
          mdiBellOutline,
          mdiGithub,
        },
      }
    },



    created() {
      console.log("created");
      console.log(this.$store.getters.getAdminDrawer);

    },
    mounted () {
      console.log("mounted");
    },
  }
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>