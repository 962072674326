<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />

    <div class="pa-5" >
      
      <v-card elevation="0" outlined>
        <v-card-title>CREATE NEW LEAFLET</v-card-title>
        <v-card-subtitle>{{this.bookName}} - {{this.unitName}}</v-card-subtitle>
        <div class="pa-5"> 
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field outlined v-model="leafletName"  :rules="nameRules" label="Leaflet Name" required ></v-text-field>
          </v-form>
        </div>

        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <v-btn class="float-right" @click="submit" color="primary" elevation="0" >CREATE</v-btn>
        </v-card-actions>

      </v-card>

    </div>

</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiGithub, mdiAlertRhombus, mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';


  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline, mdiGithub,},
      }
    },

    data: () => ({
      valid: true,
      unitId: '',
      bookName: '',
      unitName: '',
      leafletName: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 2) || 'Name must be more than 2 characters',
      ],
    }),

    methods: {
      getData() {
        
        const data = { unitId : this.unitId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/unit",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.bookName = response.data.bookName;
            this.unitName = response.data.unit.unitName;
          }  
        );

      },
      submit () {
        
        if(this.$refs.form.validate()){
          
          const data = {
            leafletName : this.leafletName,
            unitId : this.unitId
          }

          axios.post(process.env.VUE_APP_API_DOMAIN + "/leaflet_add",JSON.stringify(data)
          ,{
              headers: {
                'Authorization': 'Bearer ' + this.$store.getters.getAccessToken  
              }
            }
          ).then(
            response => {

              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Leaflet is added successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }

            }  

          );

        }

      },
      vtoastCallback() {
        this.$router.push(`/eisadmin/leaflets/${this.$route.params.unitId}`);
      }
      
    },


    created() {

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.unitId = this.$route.params.unitId;
      this.getData();
    },
  }
</script>
