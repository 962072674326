<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>TEACHER SCHOOLS</div>
            <v-spacer></v-spacer>
            <v-btn small color="accent" @click="dialogSchool = !dialogSchool" elevation="0"  >ADD SCHOOL</v-btn>
          </v-card-title>
          <v-card-subtitle><a href="/eisadmin/teachers" class="link_blue">Teachers</a> > {{teacherName}}</v-card-subtitle>
          

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">SchoolName</th>
                  <th width="200"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in schools" :key="item.schoolId" >
                  
                    <td>{{ index +1 }}</td>
                    <td>{{ item.schoolName }}</td>
                    
                    <td class="text-right">

                        <v-btn small text @click="deleteSchool(item.schoolId)">REMOVE</v-btn>

                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          
      </v-card>
    </div>


    <v-dialog v-model="dialogSchool" persistent max-width="600px">
      
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New School to Teacher</span>
        </v-card-title>

        <v-card-text>

          <v-select v-model="schoolSelect" :items="all_schools" item-text="schoolName" item-value="schoolId" label="School"  outlined ></v-select>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="dialogSchool = false" >Close</v-btn>
          <v-btn color="primary darken-1" elevation="0" @click="saveData" >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold  },
      }
    },

    data(){
      return {
        teacherId: 0,
        teacherName: "",
        schools: [],
        all_schools : [],

        schoolSelect: 0,

        dialogSchool: false,
      }
    },

    methods: {

      selectedC(cc){
        this.classSelected = cc
      },

      getData() {
        
        const data = { teacherId : this.$route.params.teacherId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/teacher_schools",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.schools = response.data.schools;
            this.teacherName = response.data.teacherName;
          }  
        );

      },

      getAllSchoolData() {
        
        const data = { page : 0 }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/schools",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.all_schools = response.data.schools;
          }  
        );

      },


      saveData(){
        const data = {schoolId : this.schoolSelect, teacherId : this.$route.params.teacherId }
        console.log(data);
        console.log(data);
        axios.post(process.env.VUE_APP_API_DOMAIN + "/add_school2teacher",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          () => {
            this.dialogSchool = false;
            this.getData();
          }  
        );
      },

      vtoastCallback() {
        //this.getTeachersData();
      },

      async deleteSchool(schoolId) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to remove this school?"
          )
        ){

          const data = { schoolId : schoolId, teacherId : this.$route.params.teacherId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/teacher_deletefromschool",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Class deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.getData();
              }
            }  
          );

          
        }
      }

    },

    watch: {

      dialogSchool: function (newValue) {
        if(newValue == true){ this.getAllSchoolData(); }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open

      this.teacherId = this.$route.params.teacherId;
      this.getData();
    }
  }
</script>

