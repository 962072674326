<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white color_normal minhe100P">
        <v-container class="pt-0">

          

            <div class="mt-5">
              <a href="/student" class="mt-4 link_blue2">
                  <v-icon class="mt-n1 link_blue2" >{{icons.mdiChevronLeft  }}</v-icon>Back
              </a>
            </div>

            <ClassMini 
            :className = className 
            :schoolName = schoolName 
            :classKey = classKey 
            :classId = classId 
            :classStartDate = classStartDate 
            :classEndDate = classEndDate 
            v-on:changeDialogBox = changeDialogBox  />

            <v-tabs v-model="tab" class="mt-8 tabs_border">
              <v-tab @click="tabshow('tabBookdetails')">Book Details</v-tab>
              <v-tab @click="tabshow('tabMaterials')">Materials</v-tab>
              <v-tab @click="tabshow('tabResults')">My Results</v-tab>
            </v-tabs>

            <div v-if="activetab == 'tabBookdetails'">
              
              <BookMini 
              :bookCode = bookCode 
              :bookCodeDates = bookCodeDates 
              :bookCover = bookCover 
              :bookName = bookName 
              :bookSubtitle = bookSubtitle 
              :bookDescription = bookDescription  />


              <div class="mt-8 pb-5">

                  <v-simple-table v-for="item in units" :key="item.unitId">
                    <template v-slot:default>
                      <tbody>
                        <tr @click="gotoUnit(item.unitId)" style="cursor: pointer;" >
                          <td width="70" class="text-right d-none d-sm-table-cell">
                            <v-img :src="require('@/assets/images/quizIcon.png')" max-width="70px" class="ma-1 "></v-img>
                          </td>
                          <td class="tabs_border">
                            <div class="f_18 font_bold ">{{ item.unitName }}</div>
                            <div class="f_12 color_muted">{{ limitText(item.unitDescription,80) }}</div>
                            <v-icon :color="findClass4Leaflet(leaflet.classDetails.statu)" v-for="leaflet in item.leaflets" :key="leaflet.leafletId">{{icons.mdiRecordCircleOutline}} </v-icon>
                          </td>
                          <td class="text-right tabs_border">
                            <v-icon>{{icons.mdiChevronRight}}</v-icon>
                          </td>
                        </tr>

                      </tbody>
                    </template>
                  </v-simple-table>

              </div>


            </div>
            <div v-if="activetab == 'tabMaterials'">
              <TabMaterials :bookId="bookId" />
            </div>
            <div v-if="activetab == 'tabResults'">
              <TabResults :bookId="bookId" :leafletId="leafletId" />
            </div>



            <v-dialog v-model="dialogClassKey" persistent max-width="600px">
              <v-card>
                <v-card-title class="text-h5">Join Class</v-card-title>
                <v-card-text>
                  <v-text-field label="Class Key" outlined hide-details v-model="newClassKey"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="dialogClassKey = false" >Close</v-btn>
                  <v-btn color="primary darken-1" elevation="0" @click="joinClass()" >Join</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


        </v-container>
      </div>

  </v-app>



</template>

<script>
  import checkAuthStudent from '../../mixins/checkAuthStudent';
  import TopBar from '../../views/student/components/TopBar'
  import TabResults from '../../views/student/components/TabResults.vue'
  import TabMaterials from '../../views/student/components/TabMaterials.vue'
  import ClassMini from '../../views/student/components/ClassMini'
  import BookMini from '../../views/student/components/BookMini'
  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup,
  mdiChevronLeft,  mdiMagnify ,mdiAlertCircle ,mdiCheckBold, mdiRecordCircleOutline, mdiChevronRight } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/student/components/Vtoast';
  import Confirm from '../../views/student/components/Confirm.vue';

  export default {
    mixins: [checkAuthStudent],
    components: {
      TopBar,  Vtoast, Confirm , ClassMini, BookMini, TabResults, TabMaterials
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,
          mdiMagnify,
          mdiAlertCircle,
          mdiCheckBold,
          mdiRecordCircleOutline,
          mdiChevronRight,
          mdiChevronLeft
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',
      schoolName:"",
      classId : "0",
      className:"",
      classKey:"",
      classStartDate:"",
      classEndDate:"",
      bookId:0,
      leafletId:0,

      dialogClassKey: false,
      newClassKey: '',

      bookName:"",
      bookSubtitle:"",
      bookCover: "",
      bookDescription: "",
      bookCode: "",
      bookCodeDates: "",

      activetab: 'tabBookdetails',

      units:[],

      

    }),
    methods:{

      test(){
        console.log(this.classId);
      },

      changeDialogBox(){
        console.log("dsadasda");
        this.dialogClassKey = true;
      },

      tabshow(gl){
        if(gl == "tabStudents"){
          this.getStudentData();
        }
        this.activetab = gl;
      },

      gotoUnit(unitId){
        console.log(this.classId);
        console.log(this.bookId);
        console.log(unitId);
        this.$router.push('/student/unit/'+this.bookId+'/'+unitId);

      },


      limitText(text,len){
        return  text.length > len ? text.substring(0, len - 3) + "..." : text;
      },

      findClass4Leaflet(statu){
        console.log(statu);
        let res = "grey lighten-2";
        if(statu == "finished"){
          res = "primary";
        }
        if(statu == "started"){
          res = "green";
        }
        return res
      },


      joinClass(){
        
        const data = {classKey: this.newClassKey }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_class_join",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            if(response.data.result == "noClass"){
              console.log("noClass");
              this.$root.Vtoast.show({message: 'No class found! Please check your Class Key!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }else if(response.data.result == "noBook"){
              console.log("noBook");
              this.$root.Vtoast.show({message: 'No book found! You need to add book "'+response.data.bookName+'" in your library first!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }else if(response.data.result == "success"){
              console.log("success");
              this.$root.Vtoast.show({message: 'Class is added successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              
            }else{
              console.log("fail");
              this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }

          }  
        );

      },


      getData(){
        

          const data = { bookId : this.bookId , classId : this.classId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_book_details",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log("----");
              console.log(response.data);

              this.className = response.data.className;
              this.classKey = response.data.classKey;
              this.schoolName = response.data.schoolName;
              this.classId = response.data.classId;
              this.classStartDate = response.data.classStartDate;
              this.classEndDate = response.data.classEndDate;

              this.bookName = response.data.bookDetails.bookName;
              this.bookSubtitle = response.data.bookDetails.bookSubtitle;
              this.bookCover = response.data.bookDetails.bookCover;
              this.bookDescription = response.data.bookDetails.bookDescription;
              this.bookCode = response.data.bookCode;
              this.bookCodeDates = response.data.bookCodeDates;

              this.units = response.data.units;

            }  
          );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        this.dialogClassKey = false
        this.getData();
      },


    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
      this.bookId = this.$route.params.bookId;
      
      

      
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;

      if(this.$route.params.tab == "tabResults"){
        this.activetab = this.$route.params.tab;
        this.tab = 2;
      }

      if(this.$route.params.leafletId != null){
        this.leafletId = this.$route.params.leafletId
      }


      this.getData();
    },
    

  }
</script>