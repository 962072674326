<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white color_normal minhe100P">
        <v-container class="pt-0">

          

            <div class="d-flex flex-row mt-8">
              <v-icon size="30" class="black--text mr-3 mt-2" > {{ icons.mdiCog }}</v-icon>
              <div class="f_24 font_bold pt-1 black--text "> My Profile</div>
            </div>

            
              <div class="row mt-10 tabs_border  py-1">
                <div class="col-md-3 font_bold">Name Surname: </div>
                <div class="col-7">{{studentName}}</div>
                <div class="col-2 text-right"><v-btn small outlined @click="dialogName = !dialogName">EDIT</v-btn></div>
              </div>
              <div class="row mt-2 tabs_border py-1">
                <div class="col-md-3 font_bold">Email Address: </div>
                <div class="col-7">{{studentEmail}}</div>
                <div class="col-2 text-right"><v-btn small outlined @click="dialogEmail = !dialogEmail">EDIT</v-btn></div>
              </div>
              <div class="row mt-2 tabs_border py-1">
                <div class="col-md-3 font_bold">Password: </div>
                <div class="col-7">**********</div>
                <div class="col-2 text-right"><v-btn small outlined @click="dialogPass = !dialogPass">EDIT</v-btn></div>
              </div>


        </v-container>


        <v-dialog v-model="dialogPass" persistent max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Edit Your Password</v-card-title>
            <v-card-text>
              <v-text-field outlined hide-details label="Current Password" v-model="studentPass_old" type="password"></v-text-field>
              <v-text-field class="mt-3" outlined hide-details label="New Password" v-model="studentPass_new" type="password"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="dialogPass = false" >Close</v-btn>
              <v-btn color="primary darken-1" elevation="0" @click="savePass()" >Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogName" persistent max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Edit Your Name</v-card-title>
            <v-card-text><v-text-field outlined hide-details label="Name Surname" v-model="studentName2"></v-text-field></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="dialogName = false" >Close</v-btn>
              <v-btn color="primary darken-1" elevation="0" @click="saveName()" >Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEmail" persistent max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Edit Your Email Address</v-card-title>
            <v-card-text>
              <v-text-field v-if="emailSent == 0" outlined hide-details label="Email Address" v-model="studentEmail2"></v-text-field>
              
              <div class="mb-4" v-if="emailSent == 1">We have sent an email to you! Please enter the core here:</div>
              <v-text-field v-if="emailSent == 1" outlined hide-details label="Code" v-model="code"></v-text-field>
            
            </v-card-text>

            
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="dialogEmail = false" >Close</v-btn>
              <v-btn v-if="emailSent == 0" color="primary darken-1" elevation="0" @click="saveEmail()" >Save</v-btn>
              <v-btn  v-if="emailSent == 1" color="primary darken-1" elevation="0" @click="doneEmail()" >Done</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </div>

  </v-app>

</template>

<script>
  import checkAuthStudent from '../../mixins/checkAuthStudent';
  import TopBar from '../../views/student/components/TopBar'

  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup,
  mdiChevronLeft,  mdiMagnify ,mdiAlertCircle ,mdiCheckBold, mdiRecordCircleOutline, mdiChevronRight, mdiCog } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/student/components/Vtoast';
  import Confirm from '../../views/student/components/Confirm.vue';

  export default {
    mixins: [checkAuthStudent],
    components: {
      TopBar,  Vtoast, Confirm,
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,
          mdiMagnify,
          mdiAlertCircle,
          mdiCheckBold,
          mdiRecordCircleOutline,
          mdiChevronRight,
          mdiChevronLeft,
          mdiCog
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',

      studentName: '',
      studentName2: '',
      studentEmail: '',
      studentEmail2: '',

      studentPass_old : '',
      studentPass_new : '',
      
      emailSent: 0,
      code: "",

      dialogName: false,
      dialogEmail: false,
      dialogPass: false,

    }),
    methods:{

      test(){
        console.log(this.classId);
      },

      doneEmail(){
        if(this.code != ""){
          const data = {
            code : this.code,
            newEmail : this.studentEmail2
          }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_save_email",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              if(response.data.result == 'success'){
                this.emailSent = 0
                this.dialogEmail = false;
                this.getData();
              }else{
                this.$root.Vtoast.show({message: 'Please enter the code we have sent!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
              }
            }  
          );
        }else{
          this.$root.Vtoast.show({message: 'You need to enter your new email address!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
        }
      },

      saveEmail(){
        if(this.studentEmail2 != ""){
          const data = {
            studentEmail : this.studentEmail2
          }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_save_email_sendmail",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              if(response.data.result == 'success'){
                this.emailSent = 1
              }else{
                this.$root.Vtoast.show({message: 'This email has been taken by another user!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
              }
            }  
          );
        }else{
          this.$root.Vtoast.show({message: 'You need to enter your new email address!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
        }
      },

      savePass(){

        if(this.studentPass_old != "" || this.studentPass_new != ""){
          const data = {
            studentPass_old : this.studentPass_old,
            studentPass_new : this.studentPass_new
          }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_save_pass",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              if(response.data.result == 'success'){
                this.dialogPass = false;
                this.getData();
              }else{
                this.$root.Vtoast.show({message: 'Wrong password! Please check your current password. ', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
              }
            }  
          );
        }else{
          this.$root.Vtoast.show({message: 'You need to enter your current and new password!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
        }
      },


      saveName(){
        if(this.studentName2 != ""){
          const data = {
            studentName : this.studentName2
          }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_save_name",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            () => {
              this.dialogName = false;
              this.getData();
            }  
          );
        }else{
          this.$root.Vtoast.show({message: 'You need to enter your name and surname!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
        }
          
      },

      


      getData(){
        
          const data = { }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_details",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              this.studentId = response.data.studentId;
              this.studentName = response.data.studentName;
              this.studentName2 = response.data.studentName;
              this.studentEmail = response.data.studentEmail;
              this.studentEmail2 = response.data.studentEmail;
            }  
          );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        this.dialogClassKey = false
        this.getData();
      },


    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;

      this.getData();
    },
    

  }
</script>