<template>


    <v-main>
      <div>
          <h1>ABOUT</h1>
          <div>{{VUE_APP_API_DOMAIN}}</div>
      </div>
    </v-main>


</template>



<script>
  export default {

    data(){
      return{
        VUE_APP_API_DOMAIN : ""
      }
      
    },

    created() {

      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN
      
    }
  }
</script>

