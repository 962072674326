import { render, staticRenderFns } from "./BookUnit.vue?vue&type=template&id=05147854&"
import script from "./BookUnit.vue?vue&type=script&lang=js&"
export * from "./BookUnit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VApp,VBtn,VContainer,VIcon,VImg,VList,VMenu,VSimpleTable})
