<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white color_normal minhe100P">
        <v-container class="pt-0">

            <div class="mt-5">
              <a href="/student/messages" class="mt-4 link_blue2">
                  <v-icon class="mt-n1 link_blue2" >{{icons.mdiChevronLeft  }}</v-icon>Back
              </a>
            </div>

            <div class="d-flex flex-row mt-8">
              <v-icon size="30" class="black--text mr-3 mt-2" > {{ icons.mdiEmailOutline }}</v-icon>
              <div class="f_24 font_bold pt-1 black--text "> Messages</div>
            </div>

            <div class="row mt-8">
              <div class="mx-3">
                <div class=" font_medium">{{teacherName}} - {{teacherEmail}}</div>
                <div>Class: {{className}} </div>
              </div>
            </div>


            <v-simple-table  class="mt-10">
              <template v-slot:default>
                <tbody >
                  <tr v-for="item in messages" :key="item.messageId">
                    <td class="tabs_border ">
                      <div class="py-2 color_black">{{item.message}}</div>
                      <div class="py-2 f_14">{{item.date_created_text}}
                          <v-icon v-if="item.readed == '0'" size="20" class="black--text ml-3 " >{{ icons.mdiCheck }}</v-icon>
                          <v-icon  v-if="item.readed == '1'" size="20" class="blue--text ml-3 " >{{ icons.mdiCheckAll }}</v-icon>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </template>
            </v-simple-table>



        </v-container>
      </div>

  </v-app>

</template>

<script>
  import checkAuthStudent from '../../mixins/checkAuthStudent';
  import TopBar from '../../views/student/components/TopBar'

  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup,
  mdiChevronLeft,  mdiMagnify ,mdiAlertCircle ,mdiCheckBold, mdiRecordCircleOutline, mdiChevronRight, mdiEmailOutline } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/student/components/Vtoast';
  import Confirm from '../../views/student/components/Confirm.vue';

  export default {
    mixins: [checkAuthStudent],
    components: {
      TopBar,  Vtoast, Confirm,
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,
          mdiMagnify,
          mdiAlertCircle,
          mdiCheckBold,
          mdiRecordCircleOutline,
          mdiChevronRight,
          mdiChevronLeft,
          mdiEmailOutline
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',

      
      teacherId : 0,
      teacherName: '',
      teacherEmail: '',
      className: '',

      messages : [],

      

    }),
    methods:{

      test(){
        console.log(this.classId);
      },

      

      


      getData(){
        

          const data = {teacherId : this.teacherId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_message_user",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
                this.messages = [];

                this.messages = response.data.messages;
                this.teacherName = response.data.teacherName;
                this.teacherEmail = response.data.teacherEmail;
                this.className = response.data.className;
            }  
          );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        this.dialogClassKey = false
        this.getData();
      },


    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.teacherId = this.$route.params.teacherId;
      this.getData();
    },
    

  }
</script>