<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P">
        <v-container class="pt-0 pb-12">

              <div class="d-flex flex-row mt-8">
                <v-icon size="30" class="black--text mr-3 mt-2" > {{ icons.mdiLogout  }}</v-icon>
                <div class="f_24 font_bold pt-1 black--text "> Logout</div>
              </div>

              <div class="row mt-5  py-1">
                <div class="col-12 ">You are loggin out! Please wait... </div>
              </div>

        </v-container>
      </div>

  </v-app>

</template>
 
<script>
  import checkAuthTeacher from '../../mixins/checkAuthTeacher';
  import TopBar from '../../views/teacher/components/TopBar'
  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup , mdiMagnify ,mdiLogout   } from '@mdi/js'
  import Vtoast from '../../views/teacher/components/Vtoast';
  import Confirm from '../../views/teacher/components/Confirm.vue';

  export default {
    mixins: [checkAuthTeacher],
    components: {
      TopBar,  Vtoast, Confirm
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,
          mdiMagnify,
          mdiLogout 
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',
      dialogClass: false,
      dialogKey: false,

      teacherName: '',
      teacherEmail: '',
      
      

    }),
    methods:{



      getData(){
              this.$store.commit('setTokenLogin', {
                accessToken : "",
                refreshToken : "",
                userGroup : ""
              });

              this.$store.commit('setUid', "");
              this.$store.commit('setName', "");
              this.$store.commit('setInitials', "");

              this.$router.push('/');
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },


    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    },
    

  }
</script>