<template>

    <div class="pa-2">

        

        <div class="f_20 font_bold">{{questionOrder}} - {{questionTitle}}</div>
        
        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" id="vid" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w"  controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>

        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>


        <ol class="mt-8" :type="listing_symbol">
            <li class="mb-3"  v-for="item in dropdown_list_items" :key='item.dropId'>

                <span v-html='markup_do(item.text_pre)'></span>
                <v-chip outlined  v-if="show_correct == 0" class="ma-1" :color="findColor(item.correct)"  ><span class="color_black">{{item.answer_ogrenci}}</span></v-chip>
                <v-chip outlined  v-if="show_correct == 1" class="ma-1" color="green"  ><span class="color_black">{{item.answer_correct}}</span></v-chip>
                <span v-html='markup_do(item.text_post)'></span>

            </li>
        </ol>

        <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>


    </div>
</template>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'

export default {
    mixins: [markup],
    components: { Splide, SplideSlide  },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        coverImages : null,
        listing_type: "",
        listing_symbol: "",
        dropdown_list_items: null,
        dialogSound: false,
        show_correct: 0


    }),
    props: {
        questionId: String,
        sessionCode: String
    },
    methods: {

        stopVideo(){
            document.getElementById("vid").pause();
        },


        test(){
            console.log(this.dropdown_list_items);
        },
        correctAnswers(){
            this.show_correct = 1;
        },
        studentAnswers(){
            this.show_correct = 0;
        },
        findColor(crr){
            let res = "pink";
            if(crr == "1"){
                res = "green";
            }
            return res
        },
        reload() {
            this.$forceUpdate();
        },

        resetQuestion(){
            this.dropdown_list_items.forEach(e => {
                let i = 0;
                e.options.forEach(k => {
                    if(i == 0){
                        k.correct = "1";
                    }else{
                        k.correct = "0";
                    }
                    i++;
                });

            });
            this.reload();
        },

        correctQuestion(){
            this.dropdown_list_items.forEach(e => {
                e.options.forEach(k => {
                    if(k.dropOptionId == e.correctOptionId){
                        k.correct = "1";
                    }
                });
            });
            this.reload();
        },

        correctOption(dropId,dropOptionId){
            let res =  false;
            this.dropdown_list_items.forEach(e => {
                
                if(e.dropId == dropId){

                    e.options.forEach(k => {
                        if(k.dropOptionId == dropOptionId){
                            if(k.correct == "1"){
                                res = true;
                            }
                        }
                    });

                }
            });
            return res
        },

        showText(pairId){
            let metin = "";
            this.pair_click_paragraph_items.forEach(e => {
                if(pairId == e.pairId){
                    if(e.type == "text"){
                        metin += e.text+' ';
                    }else{
                        metin += '<span class="pair_chip">'+e.answer+'</span> ';
                    }
                }
            });

            return metin;
        },

        getData(){
            const data = { questionId : this.questionId, sessionCode: this.sessionCode }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_session_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {

                    console.log(response.data);

                    this.questionOrder = response.data.questionOrder;
                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.coverImages = response.data.coverImages;
                    this.listing_type = response.data.listing_type;
                    this.listing_symbol = response.data.listing_symbol;
                    this.dropdown_list_items = response.data.dropdown_list_items;

                    

                }  
            );
        }

    },
    setup() {
        return {
        icons: { mdiFormatAlignLeft},
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted() {
        this.getData();
    },
}
</script>