<template>
  <div class="bg_login">

    <video id="background-video" autoplay loop muted poster="@/assets/bg.mp4">
      <source src="@/assets/bg.mp4" type="video/mp4">
    </video> 

    
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card">

            <div class=" text-center pt-8" ><img src="@/assets/images/EISEnglish_logo.png" width="220"></div>

            <div class="text-center px-2 f_12 mb-10">
              Please sign in with your email address and password.
            </div>

          

          <v-card-text>
            <v-form>
              <v-text-field v-model="email" outlined label="Email Address" placeholder="Email Address" hide-details class="mb-3" ></v-text-field>

              <v-text-field v-model="password" outlined :type="isPasswordVisible ? 'text' : 'password'"  @keyup="keymonitor" 
                label="Password" placeholder="*******" :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" hide-details @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>

              <v-btn v-on:click="handleLogin" block color="primary" class="mt-6" >Sign In</v-btn>

            </v-form>
          </v-card-text>


        </v-card>
      </div>


    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {  mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'


  export default {
    setup() {
      const isPasswordVisible = ref(false)
      const email = ref('')
      const password = ref('')
      

      return {
        isPasswordVisible,
        email,
        password,
        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline,
        },
      }
    },
    data(){
      return{
        email : '',
        password: ''
      }
      
    },
    computed: {},
    methods:{
      keymonitor(event){
          if(event.keyCode === 13){
            this.handleLogin();
          }
      },
      handleLogin() {

        const data = {
          email : this.email,
          password : this.password
        }

        axios.post(process.env.VUE_APP_API_DOMAIN + "/eislogin",JSON.stringify(data)
        ).then(
          response => {

            console.log(response.data);

          
            if(response.data.result == "success"){

              this.$store.commit('setTokenLogin', {
                accessToken : response.data.accesstoken,
                refreshToken : response.data.refreshtoken,
                userGroup : response.data.user_group
              });

              this.$store.commit('setName', response.data.name);
              this.$store.commit('setInitials', response.data.initials);
              this.$store.commit('setUserGroup', response.data.user_group);
              this.$store.commit('setAuthority', response.data.authority);

              console.log("girdi");

              this.$router.push('/eisadmin');

            }else{
              this.$router.push('/eisadmin');
            }
          
          
          }  

        );

      }
    },
    created() {

      if (this.authToken) {
        //this.$router.push('/login');
      }
      
    }
  }
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
