<template>

    <div class="pa-2">

        <div class="f_20 font_bold">{{questionOrder}} - {{questionTitle}}</div>
        
        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" id="vid" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w" controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>
        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>


        <v-radio-group v-model="selectedId">
        <ul class="mt-8 pl-0" style='list-style-type:none' >
            <li class="mb-3"  v-for="item in multiple_choice_options" :key='item.optionId'>
                <div :class="optionClass(item.optionId)" >
                <v-radio :value="item.optionId" :label="item.option" ></v-radio>
                </div>
            </li>
        </ul>
        </v-radio-group>


        <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>


    </div>






</template>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'


export default {
    mixins: [markup],
    components: { Splide, SplideSlide  },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        coverImages : null,
        listing_type: "",
        listing_symbol: "",
        multiple_choice_options: null,
        correctId:0,
        selectedId:0,
        dialogSound: false

    }),
    props: {
        questionId: String,
        homeworkId: String,
        studentId: String,
    },
    methods: {

        stopVideo(){
            document.getElementById("vid").pause();
        },

        test(){
            console.log(this.multiple_choice_options);
        },

        optionClass(id){
            let res = "choice_line_div";
            if(id == this.correctId && id == this.selectedId){
                res = "choice_line_div_correct";
            }else if(id != this.correctId && id == this.selectedId){
                res = "choice_line_div_wrong";
            }
            return res
        },

        reload() {
            this.$forceUpdate();
        },

        resetQuestion(){
            this.selectedId = "0";
        },

        correctQuestion(){
            this.selectedId = this.correctId;
        },

        getData(){
            const data = { questionId : this.questionId , homeworkId : this.homeworkId , studentId : this.studentId}
            axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_homework_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {
                    console.log(response.data);
                    this.questionOrder = response.data.questionOrder;
                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.coverImages = response.data.coverImages;
                    this.multiple_choice_options = response.data.multiple_choice_options;

                    this.selectedId = this.multiple_choice_options[0].answerId_ogrenci;

                    this.multiple_choice_options.forEach(element => {
                        if(element.correct == "1"){
                            this.correctId = element.optionId;
                        }
                    });

                    

                }  
            );
        }

    },
    setup() {
        return {
        icons: {mdiFormatAlignLeft  },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted() {
        this.getData();
    },
}
</script>