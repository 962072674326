<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />

    <div class="pa-5" >
      
      <v-card elevation="0" outlined>
        <v-card-title>EDIT USER</v-card-title>

        <div class="pa-5"> 
          <v-form ref="form" >
            <v-text-field outlined v-model="userName" label="Name Surname"  ></v-text-field>
            <v-text-field outlined v-model="userEmail"  label="Email Address"  ></v-text-field>
            <v-text-field outlined v-model="userPassword"  label="Password"  ></v-text-field>
            <div>* if you don't want to change password, please don't write anything for password!</div>
          </v-form>
        </div>

        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <v-btn to="/eisadmin/users" color="error" small elevation="0" class="mb-2 mr-2 mt-2" >CANCEL</v-btn>
          <v-btn class="float-right" @click="submit" small color="primary" >Save</v-btn>
        </v-card-actions>

      </v-card>

    </div>

</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiGithub, mdiAlertRhombus, mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';


  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline, mdiGithub,},
      }
    },

    data: () => ({

      userName: '',
      userEmail: '',
      userPassword: '',

    }),

    methods: {
      getData(uid) {
        
        const data = { uid : uid }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/user",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            console.log(response.data);
            this.userEmail = response.data.user.email;
            this.userName = response.data.user.name;
          }  
        );

      },
      submit () {
        
        if(this.userName == "" || this.userEmail == ""){
          this.$root.Vtoast.show({message: 'Please write name and email!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
        }else{
          
          const data = {
            userName : this.userName,
            userEmail : this.userEmail,
            userPassword : this.userPassword,
            uid : this.$route.params.uid,

          }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/user_save",JSON.stringify(data)
          ,{
              headers: {
                'Authorization': 'Bearer ' + this.$store.getters.getAccessToken  
              }
            }
          ).then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'User is saved succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }

            }  

          );

        }

      },
      vtoastCallback() {
        this.$router.push('/eisadmin/users');
      }
      
    },
    created() {},
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast
      this.getData(this.$route.params.uid);
    },
  }
</script>

