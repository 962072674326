<template>

  <v-app>
      <TopBarLeaflet  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />

        <div class="book_subheaderbg d-none d-md-block" :style="'background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba('+bookHeaderBg_r+','+bookHeaderBg_g+','+bookHeaderBg_b+',1) 100%);'">
          <v-container class="py-0">
            <div class="row my-1 ">
              <div class="col-md-1">
                    <v-img :src="VUE_APP_BOOK_MEDIA+bookCover" height="80" contain  position="center top"  />
              </div>
              <div class="col-md-9">
                <div class="f_24 font_bold">{{bookName}}</div>
                <div class="f_14 ">{{leafletName}}</div>
                <div class="f_12 ">{{unitName}} <span class="mx-2">/</span> {{leafletName}}</div>
              </div>
              <div v-if="selectedQuestionId != -1" class="col-md-2 text-right "> 
                <v-btn plain elevation="0" @click="quitLeaflet()" class="mt-4 "><v-icon class="mr-1" >{{icons.mdiCloseCircleOutline}}</v-icon> Cancel</v-btn>
              </div>
            </div>
          </v-container>
        </div>

        <div class="book_subheaderbg d-block d-md-none" :style="'background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba('+bookHeaderBg_r+','+bookHeaderBg_g+','+bookHeaderBg_b+',1) 100%);'">
              <div class="text-center my-1 position-relative">
                <v-img :src="VUE_APP_BOOK_MEDIA+bookCover" height="80" contain  position="center top"  />
                <div class="f_18 font_bold">{{bookName}}</div>
                <div class="f_12 px-2">{{unitName}} <span class="mx-2">/</span> {{leafletName}}</div>
                <div v-if="selectedQuestionId != -1" class="position-absolute" style="right:10px; top:0px "> 
                  <v-btn plain elevation="0" class="pa-0" style="min-width:auto !important" @click="quitLeaflet()" ><v-icon >{{icons.mdiCloseCircleOutline}}</v-icon></v-btn>
                </div>
              </div>
        </div>

      <v-container class=" question_container_w">

                <Pair_click_paragraph ref="goingDataRef" v-if="whichQuestionType('pair_click_paragraph')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered  :questionId="selectedQuestionId"  />
                <Pair_click_list2 ref="goingDataRef" v-if="whichQuestionType('pair_click_list2')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Pair_click_list ref="goingDataRef"  v-if="whichQuestionType('pair_click_list')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Fill_paragraph  ref="goingDataRef" v-if="whichQuestionType('fill_paragraph')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Fill_list ref="goingDataRef" v-if="whichQuestionType('fill_list')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Fill_table ref="goingDataRef" v-if="whichQuestionType('fill_table')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Order_words ref="goingDataRef" v-if="whichQuestionType('order_words')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Group_words ref="goingDataRef" v-if="whichQuestionType('group_words')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Dropdown_paragraph ref="goingDataRef" v-if="whichQuestionType('dropdown_paragraph')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Dropdown_list ref="goingDataRef" v-if="whichQuestionType('dropdown_list')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Multiple_choice ref="goingDataRef" v-if="whichQuestionType('multiple_choice')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <True_false ref="goingDataRef" v-if="whichQuestionType('true_false')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Free_write ref="goingDataRef" v-if="whichQuestionType('free_write')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Images_fill ref="goingDataRef" v-if="whichQuestionType('images_fill')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Images_click ref="goingDataRef" v-if="whichQuestionType('images_click')" :key="componentKey" v-on:makeAllAnswered = makeAllAnswered :questionId="selectedQuestionId" />
                <Finished v-if="whichQuestionType('finished')" :key="componentKey" />

      </v-container>

      <div v-if="selectedQuestionId != -1" class="booklet_footerbg pa-5 mt-4">
        <v-container class="px-0 pt-8 text-center">
      
            <div class=" d-none d-md-block">
              <span :class="classQuestion_new(question.questionId)" v-for="(question, index) in questions" :key="question.questionId" >
                <span :class="classQuestion_ic_new(question.questionId)">{{ index+1 }}</span>
              </span>
            </div>

            <div class="d-block d-md-none text-center">
              {{questions.map(function(e) { return e.questionId; }).indexOf(selectedQuestionId) + 1}} / {{questions.length}}
            </div>

            <div class="clearfix"></div>

            <div class="mt-4 mb-8">
              <v-btn rounded class="color_white" @click="nextQuestion()" color="green">Next Question</v-btn>
            </div>

        </v-container>
        
      </div>

  </v-app>

</template>

<style >
  .theme--light.v-application{background-color: #f5f5f5 !important;}
</style>

<script>
  import checkAuthStudent from '../../mixins/checkAuthStudent';
  import TopBarLeaflet from '../../views/student/components/TopBarLeaflet'
  import {  mdiBookOpenPageVariant,mdiPlusCircleOutline,mdiAccountGroup ,mdiDotsVertical,mdiAlertCircle,mdiCloseCircleOutline   } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/student/components/Vtoast';
  import Confirm from './components/Confirm.vue';
  
  import Pair_click_paragraph from '../../views/student/components_question/Pair_click_paragraph';
  import Pair_click_list from '../../views/student/components_question/Pair_click_list';
  import Pair_click_list2 from '../../views/student/components_question/Pair_click_list2';
  import Fill_paragraph from '../../views/student/components_question/Fill_paragraph';
  import Fill_list from '../../views/student/components_question/Fill_list';
  import Fill_table from '../../views/student/components_question/Fill_table';
  import Order_words from '../../views/student/components_question/Order_words';
  import Group_words from '../../views/student/components_question/Group_words';
  import Dropdown_paragraph from '../../views/student/components_question/Dropdown_paragraph';
  import Dropdown_list from '../../views/student/components_question/Dropdown_list';
  import Multiple_choice from '../../views/student/components_question/Multiple_choice';
  import True_false from '../../views/student/components_question/True_false';
  import Free_write from '../../views/student/components_question/Free_write';
  import Images_fill from '../../views/student/components_question/Images_fill';
  import Images_click from '../../views/student/components_question/Images_click';
  import Finished from '../../views/student/components_question/Finished';

  export default {
    mixins: [checkAuthStudent],
    components: {
      TopBarLeaflet,  Vtoast, Confirm, Finished,
      Pair_click_paragraph, Pair_click_list, Fill_paragraph, Fill_list, Fill_table, Order_words, Group_words , Dropdown_paragraph,
      Dropdown_list, Multiple_choice, True_false, Free_write, Images_fill, Images_click, Pair_click_list2
    },
    
    setup() {
      return {

        icons: {
          mdiBookOpenPageVariant,
          mdiPlusCircleOutline ,
          mdiAccountGroup ,
          mdiDotsVertical ,
          mdiAlertCircle,
          mdiCloseCircleOutline
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      VUE_APP_BOOK_MEDIA : process.env.VUE_APP_BOOK_MEDIA,
      uid: "",
      tab: null,
      name : '',
      initials: '',

      sessionCode:'',
      bookId:0,
      unitId:0,
      leafletId:0,
      classId:0,

      className:"",
      bookName:"",
      unitName:"",
      leafletName:"",
      bookCover: "",
      bookDescription: "",
      units:[],
      questions:[],
      selectedQuestionId : 0,

      bookHeaderColor: "",
      bookHeaderBg_r:38,
      bookHeaderBg_g:101,
      bookHeaderBg_b:165,

      canGoNext : false,
      clickedNext : 0,

      componentKey: 0,

    }),
    methods:{

      test(){
        //let cc = this.$refs.goingDataRef.goingData();
        console.log(this.questions);
      },

      goAfterFinish(){
        this.$router.push('/student/unit/'+this.bookId+'/'+this.unitId);
      },

      reload() {
        this.componentKey += 1;  
        this.$forceUpdate();
      },

      async quitLeaflet(){

        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to quit this leaflet?"
          )
        ){

              const data = { sessionCode : this.sessionCode}
              axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_quit_leaflet",JSON.stringify(data)
              ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
              .then(
                (res) => {

                  if(res.data.result == "success"){
                    this.$router.push('/student/unit/'+res.data.bookId+'/'+res.data.unitId);
                  }else{
                    this.$router.push('/student');
                  }
                  
                }  
              );

        }

      },

      makeAllAnswered(gelen){
        this.canGoNext = gelen
      },

      nextQuestion(){

        if(this.canGoNext == true && this.clickedNext == 0){

          this.clickedNext = 1;

          let goingData = this.$refs.goingDataRef.goingData();
          goingData.sessionCode = this.sessionCode;

          const data = { goingData : goingData}
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_answer",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            () => {

              this.componentKey += 1;  

              let prevIndex = 0
              let nextIndex = 0
              let i = 0;
              this.questions.forEach(e => {
                  if(e.questionId == this.selectedQuestionId){
                    prevIndex = i;
                  }
                i++;
              });
              nextIndex = prevIndex +1;

              //finished - no other question
              if(nextIndex > this.questions.length-1){
                this.selectedQuestionId = -1;
                this.canGoNext = false;
                console.log("finished");
              }else{
                this.selectedQuestionId = this.questions[nextIndex].questionId;
                this.canGoNext = false;
              }

              this.clickedNext = 0;
              this.reload();

            }  
          );

          
        }else{
          this.$root.Vtoast.show({message: 'You must answer all questions!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
        }

      },

      whichQuestionType(typ){
        let res = false;

        if(typ == "finished" && this.selectedQuestionId == -1){
            res = true;
        }else{

          this.questions.forEach(e => {
            if(e.questionId == this.selectedQuestionId){
              if(e.questionType == typ){
                res = true;
              } 
            }
          });

        }

        
        return res;
      },

      getData(){

          const data = { sessionCode : this.sessionCode }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_leaflet",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {

              console.log(response.data);

              this.className = response.data.className;
              this.bookName = response.data.bookName;
              this.bookCover = response.data.bookCover;
              this.bookDescription = response.data.bookDescription;
              this.bookHeaderColor = response.data.bookHeaderColor;
              this.units = response.data.units;
              this.questions = response.data.questions;
              this.unitName = response.data.unitName;
              this.leafletName = response.data.leafletName;
              this.leafletId = response.data.leafletId;
              this.unitId = response.data.unitId;
              this.bookId = response.data.bookId;

              
              let isFinished = "1"; 
              response.data.questions.forEach(e => {
                  if(e.answered == 0 && this.selectedQuestionId == 0){
                    this.selectedQuestionId = e.questionId
                    isFinished = "0";
                  }
              });

              

            
              if(isFinished == "1"){

                this.selectedQuestionId = -1;

              }

                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(response.data.bookHeaderColor);

                this.bookHeaderBg_r = parseInt(result[1], 16);
                this.bookHeaderBg_g = parseInt(result[2], 16);
                this.bookHeaderBg_b = parseInt(result[3], 16);

                console.log(this.questions);
                console.log(this.selectedQuestionId);

            }  
          );
      },
      classLeaflets(id){
        let clr = "pl-8 f_14 "; 
        if(id == this.leafletId){
          clr = "pl-8 f_14 bg_selected ";
        }
        return clr;
      },

      classQuestion_new(id){
        let cls = "discip ";
        if(id == this.selectedQuestionId){
          cls = "discip_active";
        }
        return cls;
      },

      classQuestion_ic_new(id){
        let cls = "icchip";
        if(id == this.selectedQuestionId){
          cls = "icchip_active";
        }
        return cls;
      },

      listUnitOpen(id){
        let res= false;
        if(id == this.unitId){
          res =  true
        }
        return res;
      },
      selected_unit_leaflets(unitId){
        let q = [];
        this.units.forEach(e => {
          if(e.unitId == unitId){
            q = e.leaflets;
          }
        });

        return q;
      },

      selectQuestion(id){
        this.selectedQuestionId = id
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },
    },

    
    created() {

      this.uid = this.$store.getters.getUid;
      this.sessionCode = this.$route.params.sessionCode;
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    },

  }
</script>