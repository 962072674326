<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />

    <div class="pa-5" >

      <v-row class="mx-0" >
        <div>
          <div class="f_18 font-weight-bold">CREATE PAIR LIST QUESTION</div>
          <div class="f_14 color_muted">
            <a href="/eisadmin/books" class="link_blue">Books</a> > 
            <a :href="'/eisadmin/units/'+bookId" class="link_blue">{{bookName}}</a> > 
            <a :href="'/eisadmin/leaflets/'+unitId" class="link_blue">{{unitName}}</a> > 
            <a :href="'/eisadmin/questions/'+leafletId" class="link_blue">{{this.leafletName}}</a>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn :to="'/eisadmin/questions/'+leafletId" color="error" small elevation="0" class="mb-2 mr-2 mt-2" >CANCEL</v-btn>
        <v-btn @click="submit" color="success" small elevation="0" class="mb-2 mt-2" >SAVE</v-btn>
      </v-row >

      
      <v-row >
        <v-col cols="12" sm="8">

            <v-card elevation="0" outlined>
              <div class="pa-5"> 
                <v-form ref="form" v-model="valid" lazy-validation >
                  <v-text-field outlined v-model="questionTitle" label="Question Text"  ></v-text-field>
                  <v-select v-model="selectedCover" @change="changeCover" :items="coverTypes"  item-text="name" item-value="type" class="mt-2"  label="Cover Types"  outlined  ></v-select>
                  <v-file-input v-model="images" v-if="visible_image" outlined multiple small-chips  accept="image/png, image/jpeg" placeholder="Choose Images" label="Cover Images" name ="Cover Images" ></v-file-input>
                  <v-text-field outlined v-model="video" v-if="visible_video"  label="Video Url"  placeholder="https://" ></v-text-field>
                  <v-text-field outlined v-model="sound" v-if="visible_sound"  label="Sound Url"  placeholder="https://"></v-text-field>
                  <v-textarea outlined v-model="soundText" v-if="visible_sound"  label="Sound Text"  placeholder=""></v-textarea>
                  <v-textarea outlined v-model="text" v-if="visible_text"  label="Text Paragraph" ></v-textarea>
                </v-form>
              </div>
            </v-card>

            <v-card elevation="0" outlined  class="mt-3">
              <div class="pa-5"> 

                <v-row class="mb-4">
                  <v-spacer></v-spacer>
                  <v-btn elevation="0" outlined small text @click="addList">+</v-btn>
                </v-row>

                            
                <draggable :list="items"  @end="endDrag(items)" >
                  <div v-for="item in items" :key="item.itemId">

                      <v-card class="pa-3 mb-6" color="#f4f3f9" outlined >

                        <div class="row">
                            <v-spacer></v-spacer>
                            <v-btn elevation="0" class="mt-2 " color="primary" small text @click="deleteItem(item.itemId)">
                              <v-icon>{{icons.mdiDelete}}</v-icon>
                            </v-btn>
                        </div>
                        <v-divider class="my-4"></v-divider>

                        <div class="row">


                          <div :class="listClassBul('text')">
                            <v-textarea auto-grow rows="1" hide-details outlined  v-model="item.content" background-color="white" class="ma-0" label="Text" required  ></v-textarea>
                          </div>
                          <div :class="listClassBul('empty') ">
                              <v-select hide-details  v-model="item.wordId" :items="unusedWords(item.wordId)"  item-text="word" item-value="wordId" label="Select Word" @change="wordSelected()"  outlined ></v-select>
                          </div>
                          
                        </div>
                      </v-card>

                  </div>
                </draggable>




              </div>

            </v-card>



        </v-col>

        <v-col cols="12" sm="4">

          <v-card elevation="0" outlined >
            <v-card-title primary-title>Words / Sentences</v-card-title>
            <v-row class="mx-3">
                <v-col cols="12" sm="9" class="px-0">
                <v-text-field v-model="writtenWord" dense placeholder="word" outlined ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="pl-1 pr-0">
                <v-btn dark @click="addWord" class="min-width-auto" color="dark">ADD</v-btn>
                </v-col>

            </v-row>
            <v-chip v-for="word in words" :color="chipColor(word.used)" :key="word.wordId" class="ma-2" label close @click:close="deleteWord(word.wordId)">
              {{word.word}}
            </v-chip>
          </v-card>

          <v-card elevation="0" outlined class="mt-3">  
            <v-card-title primary-title>Listing</v-card-title>
            <div class="mx-5"> 
              <v-radio-group class="mt-0" v-model="selectedListing">
                <v-radio label="list (i, ii, iii, iv)" value="list_i" ></v-radio>
                <v-radio label="list (a, b, c, d)" value="list_a" ></v-radio>
                <v-radio label="list (1, 2, 3, 4)" value="list_1" ></v-radio>
              </v-radio-group>
            </div>

            <div class="mx-5">
                <v-radio-group class="mt-0" v-model="listingDirection">
                  <v-radio label="vertical (text+empty)" value="vertical_te" ></v-radio>
                  <v-radio label="vertical (empty+text)" value="vertical_et" ></v-radio>
                  <v-radio label="horizontal (text+empty)" value="horizontal_te" ></v-radio>
                  <v-radio label="horizontal (empty+text)" value="horizontal_et" ></v-radio>
                </v-radio-group>
            </div>



          </v-card> 


                    <v-card elevation="0" outlined class="mt-3">  
            <v-card-title primary-title>RICH TEXT</v-card-title>
            <div class="mx-5">Rich texts can be used for text covers, question texts. Do not use it for answer areas.</div>
            <div class="mx-5"> 

              <v-simple-table>
                <template v-slot:default>
                  
                  <tbody>
                    <tr>
                      <td>**text**</td>
                      <td><strong>BOLD</strong></td>
                    </tr>
                    <tr>
                      <td>*text*</td>
                      <td><em>ITALIC</em></td>
                    </tr>
                    <tr>
                      <td>`text`</td>
                      <td><code>CODE</code></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </div>
          </v-card> 

          
        </v-col>
      </v-row>



    </div>

</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiGithub, mdiAlertRhombus, mdiCheckBold, mdiDelete  } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import draggable from 'vuedraggable';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, draggable },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline, mdiGithub,mdiDelete },
      }
    },
    
    data: () => ({
      valid: true,
      leafletId : '',
      unitId: '',
      bookId: '',
      bookName: '',
      unitName: '',
      leafletName: '',
      questionTitle: '',
      text: '',
      video: '',
      sound: '',
      soundText: '',
      images: [],

      selectedListing:'list_i',
      listingDirection:'vertical_te',

      writtenWord : "",
      words : [],

      items : [],
      
      visible_image: false,
      visible_video: false,
      visible_sound: false,
      visible_text: false,
      selectedCover: {
        type: "empty",
        name: "Please Choose"
      },
      coverTypes : [
          {type: "empty", name: "Please Choose"} ,
          {type: "image", name: "Images"} ,
          {type: "video", name: "Video"} ,
          {type: "sound", name: "Sound"},
          {type: "text", name: "Text"}
        ],
      

    }),

    methods: {
      unusedWords(wordId){
        let _w = [];
        this.words.forEach(e => {
          if(e.used == "0" ){
            _w.push(e);
          }
          if(e.wordId == wordId){
            _w.push(e);
          }
        });
        return _w
      },
      addWord(){
        if(this.writtenWord != ""){
          this.words.push({
            wordId: this.randString(),
            word: this.writtenWord,
            used: 0
          });
          this.writtenWord = "";
        }else{
          this.$root.Vtoast.show({message: 'Please write the word!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
        }
      },
      deleteWord(wordId){
        let i = 0;
        this.words.forEach(e => {
          if(e.wordId == wordId){
            this.words.splice(i, 1);
          }
          i++;
        });
      },

      addList(){

        let groupId = this.randString();

        this.items.push({
          itemId:this.randString(),
          groupId: groupId,
          content:'',
          wordId: "",
        });

      },
      
      wordSelected(){

        setTimeout(() => {
          this.resetWords();
          this.items.forEach(e => {

            if(e.wordId != ""){
              
                this.words.forEach(ww => {
                  if(e.wordId == ww.wordId){
                    ww.used = "1";
                  }
                });  
              
            }

          });

        }, 500);
      },
      randString() {
          var result = '';
          var characters = 'abcdefghijklmnopqrstuvwxyz';
          var charactersLength = characters.length;
          for ( var i = 0; i < 10; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      getData() {
        
        const data = { leafletId : this.$route.params.leafletId }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/leaflet",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.bookId = response.data.bookId;
            this.bookName = response.data.bookName;
            this.unitId = response.data.unitId;
            this.unitName = response.data.unitName;
            this.leafletName = response.data.leaflet.leafletName;
          }  
        );

      },

      validateForm(){
        let res = true;
        
        if(this.questionTitle ==""){res = false}
        if(this.items.length == 0){res = false}

        this.items.forEach(e => {
          if(e.wordId == "" || e.content == ""){res = false}
        });

        if(res == false){
          this.$root.Vtoast.show({message: 'Please fill the necessary fields!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
        }

        return res;
      },

      submit () {

        if(this.validateForm()){

          const fd = new FormData();
          fd.append('questionType', "pair_click_list");

          if(this.selectedCover != 'image' && this.selectedCover != 'video' && this.selectedCover != 'sound' && this.selectedCover != 'text'){
            this.selectedCover = 'empty';
          }
          
          fd.append('leafletId', this.leafletId);
          fd.append('questionTitle', this.questionTitle);
          fd.append('selectedCover', this.selectedCover);
          fd.append('video', this.video);
          fd.append('sound', this.sound);
          fd.append('soundText', this.soundText);
          fd.append('text', this.text);

          fd.append('selectedListing', this.selectedListing);
          fd.append('listingDirection', this.listingDirection);

          fd.append('items', JSON.stringify(this.items));
          fd.append('words', JSON.stringify(this.words));

          for( var i = 0; i < this.images.length; i++ ){
            let image = this.images[i];
            fd.append('images[' + i + ']', image);
          }

          axios.post(process.env.VUE_APP_API_DOMAIN + "/question_pair_click_list_add",fd,{ headers: { 'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            
            response => {
              //console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Question is added successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }
            }  
          );
        }

      },
      vtoastCallback() {
        this.$router.push(`/eisadmin/questions/${this.leafletId}`);
      },
      changeCover(){

        this.visible_image = false;
        this.visible_video = false;
        this.visible_sound = false;
        this.visible_text = false;
        
        if(this.selectedCover == "image"){
          this.visible_image = true;
        }
        if(this.selectedCover == "video"){
          this.visible_video = true;
        }
        if(this.selectedCover == "sound"){
          this.visible_sound = true;
        }
        if(this.selectedCover == "text"){
          this.visible_text = true;
        }
      },
      chipColor(used) {
        return used == "1" ? "primary" : "";
      },
      resetWords(){
        this.words.forEach(ww => {
          ww.used = "0";
        });  
      },
      deleteItem(itemId){
        let i = 0;
        this.items.forEach(e => {
          if(e.itemId == itemId){
            this.items.splice(i, 1);
          }
          i++;
        });
        this.wordSelected();
      },
      listClassBul(gelen){
        let don = "";
        if(this.listingDirection == "vertical_te" || this.listingDirection == "vertical_et"){
          if(gelen == "empty" && this.listingDirection == "vertical_et"){
            don = "col order-first";
          }else{
            don = "col ";
          }
        }
        


        if(this.listingDirection == "horizontal_te" || this.listingDirection == "horizontal_et"){
          
          don = "";

          if(gelen == "empty" && this.listingDirection == "horizontal_et"){
            don = "col-12 order-first";
          }else{
            don = "col-12 ";
          }
        }
        

        return don;
      }

      
      
    },


    created() {

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.leafletId = this.$route.params.leafletId;
      this.getData();
    },

    watch: {
      

    }
  }
</script>
