<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white color_normal minhe100P">
        <v-container class="pt-0">

          

            <div class="d-flex flex-row mt-8">
              <v-icon size="30" class="black--text mr-3 mt-2" > {{ icons.mdiEmailOutline }}</v-icon>
              <div class="f_24 font_bold pt-1 black--text "> Messages</div>
            </div>

            <v-simple-table  class="mt-8">
              <template v-slot:default>
                <tbody >
                  <tr v-for="item in messageUsers" :key="item.teacherId">
                    <td class="tabs_border font_medium">
                      {{item.teacherName}}  <v-chip v-if="item.unread >= 1" class="ml-2" small color="red" text-color="white">{{item.unread}}</v-chip>
                    </td>
                    <td class=" tabs_border">
                      {{item.className}}
                    </td>
                    <td width="100" class="text-right tabs_border">
                      <v-btn :to="'/student/message/'+item.teacherId" small elevation="0" color="info">messages</v-btn>
                    </td>
                  </tr>

                </tbody>
              </template>
            </v-simple-table>

            <div v-if="messageUsers.length == 0" class="mt-8 text-center">
                <div class="font_bold f_24">No message found!</div>
                <div>You can read your teacher's messages. </div>
              </div>


        </v-container>
      </div>

  </v-app>

</template>

<script>
  import checkAuthStudent from '../../mixins/checkAuthStudent';
  import TopBar from '../../views/student/components/TopBar'

  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup,
  mdiChevronLeft,  mdiMagnify ,mdiAlertCircle ,mdiCheckBold, mdiRecordCircleOutline, mdiChevronRight, mdiEmailOutline } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/student/components/Vtoast';
  import Confirm from '../../views/student/components/Confirm.vue';

  export default {
    mixins: [checkAuthStudent],
    components: {
      TopBar,  Vtoast, Confirm,
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,
          mdiMagnify,
          mdiAlertCircle,
          mdiCheckBold,
          mdiRecordCircleOutline,
          mdiChevronRight,
          mdiChevronLeft,
          mdiEmailOutline
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',

      messageUsers:[],

      

    }),
    methods:{

      test(){
        console.log(this.classId);
      },

      

      


      getData(){
        

          const data = { }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_message_users",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              this.messageUsers = response.data.users;
            }  
          );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        this.dialogClassKey = false
        this.getData();
      },


    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;

      this.getData();
    },
    

  }
</script>