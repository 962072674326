<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>ALL CLASSROOMS</div>
            <v-spacer></v-spacer>

            <div style="width:200px">
              <v-text-field class="ml-0 mr-3 my-0" hide-details outlined dense  v-model="searchKeyword" label="Search"></v-text-field>
            </div>
            <v-btn small color="accent" elevation="0" :href="'/eisadmin/class_add/'" >NEW CLASSROOM</v-btn>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">Class Name</th>
                  <th class="text-left">School Name</th>
                  <th class="text-left">Teachers</th>
                  <th width="200"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in classes" :key="item.classId" >
                  
                    <td>{{ (page - 1) * 10 + index +1 }}</td>
                    <td>{{ item.className }}</td>
                    <td><v-btn :href="'/eisadmin/school_classes/'+ item.schoolId" small color="accent" text>{{ item.schoolName }}</v-btn></td>
                    <td><v-btn :href="'/eisadmin/class_teachers/'+ item.classId" small color="accent" outlined >{{ item.teacherCount }}</v-btn></td>
                    
                    <td>
                      <v-row justify="space-around">
                        <v-btn small text @click="deleteClass(item.classId, index)">DELETE</v-btn>
                        <v-btn small text :href="'/eisadmin/class_edit/'+ item.classId">EDIT</v-btn>
                      </v-row>
                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions class="mt-3" >
            <v-spacer></v-spacer>
            <v-pagination v-model="page" :length= this.paginationLenght :total-visible=7 ></v-pagination>
          </v-card-actions>
      </v-card>
    </div>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold  },
      }
    },

    data(){
      return {
        searchKeyword: "",
        classes: [],
        page: 1,
        paginationLenght : 0
      }
    },

    methods: {
      getData() {
        
        const data = { page : this.page }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/classes",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.classes = response.data.classes;
            this.paginationLenght = Math.ceil(response.data.itemCount / 10);
          }  
        );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },

      async deleteClass(classId, index) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to delete this class?"
          )
        ){

          const data = { classId : classId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/class_delete",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Class deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.classes.splice(index, 1);
              }
            }  
          );

          
        }
      }

    },

    watch: {
      page: function (newValue, oldValue) {
        if(newValue != oldValue){
          this.getData();
        }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

