
import axios from 'axios';

    
export default {
    data: () => ({
        VUE_APP_API_DOMAIN : ""
    }),

    computed: {
        
    },
    created: function () {

        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN

        const accessToken =  this.$store.getters.getAccessToken;
        const refreshToken =  this.$store.getters.getRefreshToken;
        const userGroup =  this.$store.getters.getUserGroup;

        console.log(userGroup);

        if (refreshToken == "") {
            //no refreshToken 
            this.$router.push('/');
        }else{
            // check tokens are valid
            const data = {
                accessToken : accessToken,
                refreshToken : refreshToken
            }


            axios.post(process.env.VUE_APP_API_DOMAIN + "/checkAuthStudent",JSON.stringify(data)
            ).then(
                response => {

                    if(response.data.result == "fail"){
                        this.$router.push('/');
                    }else{
                        if(response.data.is_accesstoken_renewed == "1"){
                            //reset the accesstoken
                            this.$store.commit('setAccessTokenUserGroup', {
                                accessToken : response.data.accesstoken,
                                userGroup : response.data.user_group
                            });
                            
                        }

                    }

                }  

            ).catch(() => {
                this.$router.push('/');
            });

            
        }

        
    },
    methods: {
        displayMessage: function () {
           // console.log("Now printing from a mixin function")
        }
    }
}