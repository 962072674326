<template>

    <div class="pa-2">

        <div class="f_20 mt-3 font_bold">{{questionOrder}} - {{questionTitle}}</div>

        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w" controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>
        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>

        <div class="mt-8 row">
            <div class="col-12 text-center">
                

                <span v-for="item in word_list_user" :key="item.orderId"  >
                    <v-chip label v-if="item.content == ''" class="ma-1 clickable_options_empty" ><span class="color_black"></span></v-chip>
                    <v-chip label v-if="item.content != ''" @click="reverseWordKasa(item.wordId)" class="ma-1 clickable_options_selected" color="white"><span class="color_black">{{item.content}}</span></v-chip>
                </span>
                {{mark}}
            </div>


            <div class="col-12 mt-5 text-center ">
                <v-chip v-if="this.word_list.length == 0" color="grey lighten-2 " style="width:100px" class="ma-1" ></v-chip>
                <v-chip label @click="clickWordKasa(item.wordId)" v-for="item in word_list" :key="item.wordId" color="white"  class="ma-1 clickable_options"><span class="color_black">{{item.content}}</span></v-chip>
            </div>
        </div>


        <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'

export default {
    mixins: [markup],
    components: { Splide, SplideSlide  },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        mark : "",
        listing_type: "",
        listing_symbol: "",
        coverImages : null,
        order_words_items: null,
        order_words_words: null,

        word_list_user : [],

        word_list_original : [],
        word_list : [],
        dialogSound: false

    }),
    props: {
        questionId: String
    },
    methods: {
        
        test(){
            console.log(this.order_words_items);
            console.log(this.goingData());
        },

        goingData(){
            let dat = { 
                questionId :  this.questionId,
                questionType : "order_words",
                answerDetails : [] 
            }

            let i = 0;
            this.word_list_user.forEach(e => {

                dat.answerDetails.push({
                    orderId : this.order_words_items[i].orderId,
                    reply : e.wordId
                });

                i++;
            });
            return dat
        },

        selectItem(){
            
            let allOk = true;
            this.word_list_user.forEach(e => {

                if(e.content.length == 0){
                    allOk = false
                }

            });

            this.$emit('makeAllAnswered', allOk);
            
        },


        reload() {
            this.$forceUpdate();
        },
        resetQuestion(){

            this.word_list = [];
            this.word_list_original.forEach(r => {
                this.word_list.push({
                    wordId: r.wordId,
                    content: r.content
                });
            });

            this.word_list_user.forEach(element => {
                element.wordId = "";
                element.content = "";
            });
            this.reload()
        },

        correctQuestion(){
            this.word_list = [];
            let i = 0;
            this.order_words_items.forEach(e => {
                
                this.word_list_user[i].wordId = e.wordId;
                this.word_list_user[i].content = e.answer;
                i++;
            });
        },

        shuffle1(array) {
            console.log("shuffle");
            return array.sort(() => Math.random() - 0.5);
        },


        clickWordKasa(wordId){
            let ind = 0;
            let ind_next = 0;
            this.word_list_user.forEach(e => {
                if(e.content != ""){
                    ind_next++;
                }
            });
            this.word_list.forEach(e => {
                if(e.wordId == wordId){
                    this.word_list_user[ind_next].content=  e.content;
                    this.word_list_user[ind_next].wordId=  e.wordId;
                    this.word_list.splice(ind, 1); 
                }
                ind++; 
            });
            this.selectItem();
        },

        reverseWordKasa(wordId){

            let ind_user = 0;
            let i = 0;
            this.word_list_user.forEach(e => {
                if(e.wordId == wordId){
                    ind_user = i;
                }
                i++;
            });

            this.word_list.push({
                wordId: this.word_list_user[ind_user].wordId,
                content: this.word_list_user[ind_user].content,
            });

            this.word_list_user.splice(ind_user, 1); 
            this.word_list_user.push({
                wordId: 0,
                content: "",
            });

            this.selectItem();

        },

        getData(){
            const data = { questionId : this.questionId }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_question_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {
                    this.questionOrder = response.data.questionOrder;
                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.mark = response.data.orderWordsEnd;
                    this.coverImages = response.data.coverImages;
                    this.listing_type = response.data.listing_type;
                    this.listing_symbol = response.data.listing_symbol;
                    this.order_words_items = response.data.order_words_items;
                    this.order_words_words = response.data.order_words_words;

                    this.order_words_words.forEach(e => {
                        this.word_list.push({
                            wordId: e.wordId,
                            content: e.content
                        });
                        this.word_list_original.push({
                            wordId: e.wordId,
                            content: e.content
                        });
                        this.word_list_user.push({
                            wordId: 0,
                            content: ""
                        });
                    });

                    this.word_list = this.shuffle1(this.word_list);
                    this.word_list_original = this.shuffle1(this.word_list_original);

                }  
            );
        }

    },

    setup() {
        return {
        icons: { mdiFormatAlignLeft },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted() {
        this.getData();
    },
}
</script>