<template>

  <v-app>
      <TopBarLeaflet  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />


        <div class="book_subheaderbg d-none d-md-block" :style="'background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba('+bookHeaderBg_r+','+bookHeaderBg_g+','+bookHeaderBg_b+',1) 100%);'">
          <v-container class="py-0">
            <div class="row my-1 ">
              <div class="col-md-1">
                    <v-img :src="VUE_APP_BOOK_MEDIA+bookCover" height="80" contain  position="center top"  />
              </div>
              <div class="col-md-9">
                <div class="f_24 font_bold">{{bookName}}</div>
                <div class="f_14 font_bold">{{leafletName}}</div>
                <div class="f_12">{{className}}<span class="mx-1">/</span>{{unitName}}<span class="mx-1">/</span>{{leafletName}}</div>
              </div>
              <div class="col-md-2 text-right "> 
                <v-btn plain elevation="0" :to="'/teacher/unit/'+classId+'/'+bookId+'/'+unitId" class="mt-4 "><v-icon class="mr-1" >{{icons.mdiCloseCircleOutline}}</v-icon> Cancel</v-btn>
              </div>
            </div>
          </v-container>
        </div>


        <div class="book_subheaderbg  d-block d-md-none" :style="'background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba('+bookHeaderBg_r+','+bookHeaderBg_g+','+bookHeaderBg_b+',1) 100%);'">


          <div class="text-center my-1 position-relative">
            <v-img :src="VUE_APP_BOOK_MEDIA+bookCover" height="80" contain  position="center top"  />
            <div class="f_18 font_bold">{{bookName}}</div>
            <div class="f_12 px-2">{{unitName}} <span class="mx-2">/</span> {{leafletName}}</div>
            <div v-if="selectedQuestionId != -1" class="position-absolute" style="right:10px; top:0px "> 
              <v-btn plain elevation="0" class="pa-0" style="min-width:auto !important" :to="'/teacher/unit/'+classId+'/'+bookId+'/'+unitId" ><v-icon >{{icons.mdiCloseCircleOutline}}</v-icon></v-btn>
            </div>
          </div>


        </div>






      <v-container class=" question_container_w">

                <Pair_click_paragraph v-if="whichQuestionType('pair_click_paragraph')" :key="componentKey" :questionId="selectedQuestionId"  />
                <Pair_click_list2 v-if="whichQuestionType('pair_click_list2')" :key="componentKey" :questionId="selectedQuestionId" />
                <Pair_click_list v-if="whichQuestionType('pair_click_list')" :key="componentKey" :questionId="selectedQuestionId" />
                <Fill_paragraph v-if="whichQuestionType('fill_paragraph')" :key="componentKey" :questionId="selectedQuestionId" />
                <Fill_list v-if="whichQuestionType('fill_list')" :key="componentKey" :questionId="selectedQuestionId" />
                <Fill_table v-if="whichQuestionType('fill_table')" :key="componentKey" :questionId="selectedQuestionId" />
                <Order_words v-if="whichQuestionType('order_words')" :key="componentKey" :questionId="selectedQuestionId" />
                <Group_words v-if="whichQuestionType('group_words')" :key="componentKey" :questionId="selectedQuestionId" />
                <Dropdown_paragraph v-if="whichQuestionType('dropdown_paragraph')" :key="componentKey" :questionId="selectedQuestionId" />
                <Dropdown_list v-if="whichQuestionType('dropdown_list')" :key="componentKey" :questionId="selectedQuestionId" />
                <Multiple_choice v-if="whichQuestionType('multiple_choice')" :key="componentKey" :questionId="selectedQuestionId" />
                <True_false v-if="whichQuestionType('true_false')" :key="componentKey" :questionId="selectedQuestionId" />
                <Free_write v-if="whichQuestionType('free_write')" :key="componentKey" :questionId="selectedQuestionId" />
                <Images_fill v-if="whichQuestionType('images_fill')" :key="componentKey" :questionId="selectedQuestionId" />
                <Images_click v-if="whichQuestionType('images_click')" :key="componentKey" :questionId="selectedQuestionId" />

      </v-container>

      <div class="booklet_footerbg pa-5 mt-4">
        <v-container class="px-0 pt-8 text-center">

            <span :class="classQuestion_new(question.questionId)" v-for="(question, index) in questions" :key="question.questionId" @click="selectQuestion(question.questionId)">
              <span :class="classQuestion_ic_new(question.questionId)">{{ index+1 }}</span>
            </span>

            <div class="clearfix"></div>

            <div class="mt-4 mb-8">
              <v-btn rounded v-if="showNext == 1" @click="nextQuestion()" class="color_white" color="green">Next Question</v-btn>
              <v-btn rounded v-if="showNext == 0" :to="'/teacher/unit/'+classId+'/'+bookId+'/'+unitId" class="color_white" color="green">Finish</v-btn>

            </div>

        </v-container>
        
      </div>

  </v-app>

</template>

<style >
  .theme--light.v-application{background-color: #f5f5f5 !important;}
</style>

<script>
  import checkAuthTeacher from '../../mixins/checkAuthTeacher';
  import TopBarLeaflet from '../../views/teacher/components/TopBarLeaflet'
  import {  mdiBookOpenPageVariant,mdiPlusCircleOutline,mdiAccountGroup ,mdiDotsVertical ,mdiCloseCircleOutline   } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/teacher/components/Vtoast';
  import Confirm from './components/Confirm.vue';
  
  import Pair_click_paragraph from '../../views/teacher/components_question/Pair_click_paragraph';
  import Pair_click_list from '../../views/teacher/components_question/Pair_click_list';
  import Pair_click_list2 from '../../views/teacher/components_question/Pair_click_list2';
  import Fill_paragraph from '../../views/teacher/components_question/Fill_paragraph';
  import Fill_list from '../../views/teacher/components_question/Fill_list';
  import Fill_table from '../../views/teacher/components_question/Fill_table';
  import Order_words from '../../views/teacher/components_question/Order_words';
  import Group_words from '../../views/teacher/components_question/Group_words';
  import Dropdown_paragraph from '../../views/teacher/components_question/Dropdown_paragraph';
  import Dropdown_list from '../../views/teacher/components_question/Dropdown_list';
  import Multiple_choice from '../../views/teacher/components_question/Multiple_choice';
  import True_false from '../../views/teacher/components_question/True_false';
  import Free_write from '../../views/teacher/components_question/Free_write';
  import Images_fill from '../../views/teacher/components_question/Images_fill';
  import Images_click from '../../views/teacher/components_question/Images_click';



  export default {
    mixins: [checkAuthTeacher],
    components: {
      TopBarLeaflet,  Vtoast, Confirm,
      Pair_click_paragraph, Pair_click_list, Fill_paragraph, Fill_list, Fill_table, Order_words, Group_words , Dropdown_paragraph,
      Dropdown_list, Multiple_choice, True_false, Free_write, Images_fill, Images_click, Pair_click_list2
    },
    
    setup() {
      return {

        icons: {
          mdiBookOpenPageVariant,
          mdiPlusCircleOutline ,
          mdiAccountGroup ,
          mdiDotsVertical ,
          mdiCloseCircleOutline 
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      VUE_APP_BOOK_MEDIA : process.env.VUE_APP_BOOK_MEDIA,
      uid: "",
      tab: null,
      name : '',
      initials: '',

      bookId:0,
      unitId:0,
      leafletId:0,
      classId:0,

      className:"",
      bookName:"",
      unitName:"",
      leafletName:"",
      bookCover: "",
      bookDescription: "",
      units:[],
      questions:[],
      selectedQuestionId : 0,

      bookHeaderColor: "",
      bookHeaderBg_r:38,
      bookHeaderBg_g:101,
      bookHeaderBg_b:165,

      componentKey: 0,
      showNext : 1

    }),
    methods:{

      nextQuestion(){

        this.reload();
      
        let prevIndex = 0
        let nextIndex = 0
        let i = 0;
        this.questions.forEach(e => {
            
            if(e.questionId == this.selectedQuestionId){
              prevIndex = i;
            }
          i++;
        });
        nextIndex = prevIndex +1;
        this.selectedQuestionId = this.questions[nextIndex].questionId;

        if(nextIndex == (this.questions.length-1)){
          this.showNext = 0;
        }else{
          this.showNext = 1;
        }

      },

      test(){
        console.log("bookId" + this.bookId);
        console.log("unitId" + this.unitId);
        console.log("leafletId" + this.leafletId);

      },

      whichQuestionType(typ){
        let res = false;
        
        this.questions.forEach(e => {
          if(e.questionId == this.selectedQuestionId){
            if(e.questionType == typ){
              res = true;
            } 
          }
        });
        return res;
      },

      getData(){
          const data = { bookId : this.bookId, unitId: this.unitId, leafletId: this.leafletId , classId: this.classId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_leaflet",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {

              this.className = response.data.className;
              this.bookName = response.data.bookName;
              this.bookCover = response.data.bookCover;
              this.bookDescription = response.data.bookDescription;
              this.bookHeaderColor = response.data.bookHeaderColor;
              this.units = response.data.units;
              this.questions = response.data.questions;
              this.unitName = response.data.unitName;
              this.leafletName = response.data.leafletName;
              this.selectedQuestionId = this.questions[0].questionId;

              var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(response.data.bookHeaderColor);
              
              this.bookHeaderBg_r = parseInt(result[1], 16);
              this.bookHeaderBg_g = parseInt(result[2], 16);
              this.bookHeaderBg_b = parseInt(result[3], 16);

            }  
          );
      },

      classLeaflets(id){
        let clr = "pl-8 f_14 "; 
        if(id == this.leafletId){
          clr = "pl-8 f_14 bg_selected ";
        }
        return clr;
      },

      reload() {
        this.componentKey += 1;  
        this.$forceUpdate();
      },

      classQuestion_new(id){
        let cls = "discip ";
        if(id == this.selectedQuestionId){
          cls = "discip_active";
        }
        return cls;
      },

      classQuestion_ic_new(id){
        let cls = "icchip";
        if(id == this.selectedQuestionId){
          cls = "icchip_active";
        }
        return cls;
      },

      classQuestion(id){
        let cls = "mx-1";
        if(id == this.selectedQuestionId){
          cls = "deep-purple white--text mx-1";
        }
        return cls;
      },

      listUnitOpen(id){
        let res= false;
        if(id == this.unitId){
          res =  true
        }
        return res;
      },

      selected_unit_leaflets(unitId){
        let q = [];
        this.units.forEach(e => {
          if(e.unitId == unitId){
            q = e.leaflets;
          }
        });

        return q;
      },

      selectQuestion(id){
        this.reload();
        this.selectedQuestionId = id;

        let ind = 0;
        let i =0;
        this.questions.forEach(e => {
            
            if(e.questionId == id){
              ind = i+1;
            }
          i++;
        });

        if(ind == this.questions.length){
          this.showNext = 0;
        }else{
          this.showNext = 1;
        }
        
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },
    },

    created() {

      this.uid = this.$store.getters.getUid;
      this.bookId = this.$route.params.bookId;
      this.classId = this.$route.params.classId;
      this.unitId = this.$route.params.unitId;
      this.leafletId = this.$route.params.leafletId;
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    },

  }
</script>