<template>

    <div class="pa-2">

        <div class="text-right mt-3">
            <v-btn elevation="1" class="mr-2" color="info" text-color="white" small @click="correctQuestion()">Correct Answers</v-btn>
            <v-btn elevation="1"  color="primary" small @click="resetQuestion()">Reset</v-btn>
        </div>

        <div class="f_20 font_bold">{{questionOrder}} - {{questionTitle}}</div>
        
        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w" controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>
        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>

        <div class="mt-8 row">
            <div class="col-12">
                
                <ol :type="listing_symbol">
                    <li  v-for="item in pair_click_list_items" :key='item.pairId' class="mb-5">
                        <div v-if="listing_type == 'vertical_te'" class="row justify-center align-center">
                            <div class="col-md-6 py-1"><span v-html='markup_do(item.text)'></span></div>
                            <div class="col-md-6 py-1">
                                <div :class="classbosluk(item.pairId)">
                                    <draggable
                                            class="dragArea dragAreabefore"
                                            group="mainlist"
                                            @start="startdragging"
                                            @change="log($event, item.pairId)"
                                            @end="enddragging"
                                            v-model= item.list
                                        > 
                                        <v-chip label v-for="item in item.list" :id="item.wordId" :key="item.wordId" color="white"   class="draggable_options" ><span class="color_black">{{item.content}}</span></v-chip>
                                    </draggable>
                                </div>                            </div>
                        </div>
                        <div v-if="listing_type == 'vertical_et'" class="row justify-center align-center">
                            <div class="col-md-6 py-1">
                                <div :class="classbosluk(item.pairId)">
                                    <draggable
                                            class="dragArea dragAreabefore"
                                            group="mainlist"
                                            @start="startdragging"
                                            @change="log($event, item.pairId)"
                                            @end="enddragging"
                                            v-model= item.list
                                        > 
                                        <v-chip label v-for="item in item.list" :id="item.wordId" :key="item.wordId" color="white"   class="draggable_options" ><span class="color_black">{{item.content}}</span></v-chip>
                                    </draggable>
                                </div>  
                            </div>
                            <div class="col-md-6 py-1"><span v-html='markup_do(item.text)'></span></div>
                        </div>
                        <div v-if="listing_type == 'horizontal_te'" class="row ">
                            <div class="col-12"><span v-html='markup_do(item.text)'></span></div>
                            <div class="col-12 py-0 px-0">
                                <div :class="classbosluk(item.pairId)">
                                <draggable
                                        tag="div"
                                        class="dragArea dragAreabefore"
                                        group="mainlist"
                                        @start="startdragging"
                                        @change="log($event, item.pairId)"
                                        @end="enddragging"
                                        v-model= item.list
                                    >
                                    <v-chip label v-for="item in item.list" :id="item.wordId" :key="item.wordId" color="white"   class="draggable_options" ><span class="color_black">{{item.content}}</span></v-chip>
                                </draggable>
                                </div>
                            </div>
                        </div>
                        <div v-if="listing_type == 'horizontal_et'" class="px-2">
                            <div class="w_100 " >
                                <div class="h_1">&#8203;</div>
                                <div :class="classbosluk(item.pairId)">
                                    
                                    <draggable
                                            class="dragArea dragAreabefore"
                                            group="mainlist"
                                            @start="startdragging"
                                            @change="log($event, item.pairId)"
                                            @end="enddragging"
                                            v-model= item.list
                                        > 
                                        <v-chip label v-for="item in item.list" :id="item.wordId" :key="item.wordId" color="white"   class="draggable_options" ><span class="color_black">{{item.content}}</span></v-chip>
                                    </draggable>
                                </div>
                            </div>
                            <div class="w_100 mt-2" ><span v-html='markup_do(item.text)'></span></div>
                        </div>

                    </li>
                </ol>

            </div>
            <div class="col-12 text-center mt-5">
                
                <v-chip v-if="this.word_list.length == 0" color="grey lighten-2 " style="width:100px" class="ma-1" ></v-chip>
                <draggable
                    class="dragArea"
                    group="mainlist"
                    @start="startdragging"
                    @end="enddragging"
                    @change="kasa"
                    v-model = word_list
                >
                    <v-chip label v-for="item in word_list" :id="item.wordId" :key="item.wordId" color="white"   class="ma-1 draggable_options" ><span class="color_black">{{item.content}}</span></v-chip>
                </draggable>
            </div>
        </div>

        <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import draggable from 'vuedraggable';
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'

export default {
    mixins: [markup],
    components: { Splide, SplideSlide , draggable },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        listing_type: "",
        listing_symbol: "",
        coverImages : null,
        pair_click_list_items: null,
        pair_click_list_words: null,


        selected_wordId : 0,

        word_list_original : [],
        word_list : [],

        dialogSound: false

    }),
    props: {
        questionId: String
    },
    methods: {
        classbosluk(id){
            let res = "chip_white_border mt-1";

            this.pair_click_list_items.forEach(e => {
                if(e.pairId == id && e.list.length >= 1){
                    res= "chip_white_border_full mt-1";
                }   
            });

            return res;
        },
        correctQuestion(){
            this.resetQuestion();
            this.pair_click_list_items.forEach(e => {

                this.word_list_original.forEach(t => {
                    if(t.wordId == e.wordId){

                        e.list.push({
                            wordId: t.wordId,
                            content: t.content
                        });
                    }
                });

            });
            

            this.word_list = [];
        },
        resetQuestion(){
            this.word_list = this.word_list_original;
            this.pair_click_list_items.forEach(e => {
                e.list = [];
            });
        },
        reload() {
            this.$forceUpdate();
        },
        
        startdragging(env){
            console.log("dragging id:"+env.clone.id);
            this.selected_wordId = env.clone.id
            
        },
        enddragging(){
            this.reload()
        },
        kasa(){
            console.log("kasa değişti");
        },
        log(evt,pairId){


            this.pair_click_list_items.forEach(e => {

                if(e.pairId == pairId){

                    let li  = 0;
                    e.list.forEach(y => {
                        if(y.wordId != this.selected_wordId){

                            console.log("deleted item");

                            this.word_list.push({
                                wordId: y.wordId,
                                content: y.content
                            });
                            e.list.splice(li, 1);
                            this.selected_wordId = 0;

                        }
                        li++;
                    });

                }
                
            });
        },

        shuffle1(array) {
            return array.sort(() => Math.random() - 0.5);
        },

        getData(){
            const data = { questionId : this.questionId }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_question_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {

                    this.questionOrder = response.data.questionOrder;
                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.coverImages = response.data.coverImages;
                    this.listing_type = response.data.listing_type;
                    this.listing_symbol = response.data.listing_symbol;
                    this.pair_click_list_items = response.data.pair_click_list_items;
                    this.pair_click_list_words = response.data.pair_click_list_words;

                    this.pair_click_list_items.forEach(element => {
                        element.list = [];
                    });
                    this.pair_click_list_words.forEach(e => {
                        this.word_list.push({
                            wordId: e.wordId,
                            content: e.content
                        });
                        this.word_list_original.push({
                            wordId: e.wordId,
                            content: e.content
                        });
                    });

                    
                    this.word_list = this.shuffle1(this.word_list);
                    this.word_list_original = this.shuffle1(this.word_list_original);
                    

                }  
            );
        }

    },
    setup() {
        return {
        icons: { mdiFormatAlignLeft },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted() {
        this.getData();
    },
}
</script>