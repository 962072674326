<template>
  <div class="bg_login">
    <div class="auth-wrapper auth-v1 ">
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <div class="auth-inner">
        <v-card class="">

          <div class=" text-center pt-8" ><img src="@/assets/images/EISEnglish_logo.png" width="220"></div>

          <div class="text-center px-2 f_12 mb-1">Please enter your email address.</div>
          <div class="text-center px-2 f_12 mb-8">We will send you an email to reset your password.</div>

          <v-card-text>

              <v-text-field v-model="password1" hide-details outlined label="Password" type="password" placeholder="*******" ></v-text-field>
              <v-text-field class="mt-3" v-model="password2" hide-details outlined label="Password Again" type="password" placeholder="*******" ></v-text-field>
              <v-btn v-on:click="handleRegister" large block class="mt-4 btn_eis" >Save</v-btn>

          </v-card-text>


        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {  mdiEyeOutline, mdiEyeOffOutline, mdiAlertRhombus, mdiCheckBold } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Vtoast from '../views/eisadmin/components/Vtoast';
import '@/styles/login.css'

  export default {
    components: {  Vtoast },
    setup() {
      const email = ref('')
      
      return {

        email,
        
        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline,
          mdiAlertRhombus,
          mdiCheckBold
        },
      }
    },
    data(){
      return{
        md : "",
        password1 : '',
        password2 : '',
        
      }
      
    },
    computed: {},
    methods:{
      vtoastCallback() { 
        console.log("vtoastCallback");
        this.$router.push('/');
      },

      getData(){
        const data = {
          md : this.md,
        }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/forgot_password_check_md",JSON.stringify(data)
              ).then(
                response => {
                  console.log(response.data.result);
                  if(response.data.result == 'fail'){
                    this.$router.push('/');
                  }

                }  

              );
      },

      handleRegister() {

        const data = {
          password : this.password1,
          md : this.md
        }

        if(this.password1 == "" || this.password2 == "" || this.password1 != this.password2 ){
          this.$root.Vtoast.show({message: 'Please enter your new password!', snackType: 'fail', color: 'error', icon: mdiAlertRhombus})
        }else{

              axios.post(process.env.VUE_APP_API_DOMAIN + "/forgot_password_save",JSON.stringify(data)
              ).then(
                response => {
                  console.log(response.data);
                  this.$root.Vtoast.show({message: 'Your password is saved! Please login your account with your new password.', snackType: 'success', color: 'success', icon: mdiCheckBold })
                }  

              );
            
          

        }

      }
    },
    created() {
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.md = this.$route.params.md;
      this.getData();
    },
  }
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
