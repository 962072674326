<template>
    <div class="pa-2">
        
        <div class="text-right mt-3">
            <v-btn elevation="1" class="mr-2" color="info" text-color="white" small @click="correctQuestion()">Correct Answers</v-btn>
            <v-btn elevation="1"  color="primary" small @click="resetQuestion()">Reset</v-btn>
        </div>

        <div class="f_20 font_bold">{{questionOrder}} - {{questionTitle}}</div>
        
        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w"  controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>
        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>

        <div class="mt-8 row">
            <div class="col-12">
                <span v-for="item in pair_click_paragraph_items" :key="item.pairId"  >
                    <span  v-if="item.type == 'text'"><span v-html='markup_do(item.text)'></span></span> 

                    
                    <div :class="classbosluk(item.pairId)" v-if="item.type == 'empty'">
                        <draggable
                            class="dragArea dragAreabefore"
                            group="mainlist"
                            @start="startdragging"
                            @change="log($event, item.pairId)"
                            @end="enddragging"
                            v-model= item.list
                        > 
                            <v-chip label v-for="item in item.list" :id="item.wordId" :key="item.wordId" color="white"   class="draggable_options" ><span class="color_black">{{item.content}}</span></v-chip>
                        </draggable>
                    </div> 
                </span>
            </div>
            <div class="col-12 text-center mt-5">
                
                    <v-chip v-if="this.word_list.length == 0" color="grey lighten-2 " style="width:100px" class="ma-1" ></v-chip>
                <draggable
                    class="dragArea dragAreabefore"
                    group="mainlist"
                    @start="startdragging"
                    @end="enddragging"
                    @change="kasa"
                    v-model = word_list
                >
                    <v-chip label v-for="item in word_list" :id="item.wordId" :key="item.wordId" color="white"   class="ma-1 draggable_options" ><span class="color_black">{{item.content}}</span></v-chip>
                </draggable>
            </div>
        </div>

        <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>


    </div>
</template>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import draggable from 'vuedraggable';
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'

export default {
    mixins: [markup],
    components: { Splide, SplideSlide, draggable  },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        coverImages : null,
        pair_click_paragraph_items: null,
        pair_click_paragraph_words: null,

        selected_wordId : 0,

        word_list_original : [],
        word_list : [],

        dialogSound: false

    }),
    props: {
        questionId: String
    },
    methods: {

        test(){
            console.log(this.pair_click_paragraph_items);
        },

        classbosluk(id){
            let res = "chip_white_border_paragraph mt-1";

            this.pair_click_paragraph_items.forEach(e => {
                if(e.pairId == id && e.list.length >= 1){
                    res= "chip_white_border_paragraph_full mt-1";
                }   
            });

            return res;
        },

        correctQuestion(){
            this.resetQuestion();
            this.pair_click_paragraph_items.forEach(e => {

                this.word_list_original.forEach(t => {
                    if(t.wordId == e.wordId){

                        e.list.push({
                            wordId: t.wordId,
                            content: t.content
                        });
                    }
                });

            });
            

            this.word_list = [];
        },
        resetQuestion(){
            this.word_list = this.word_list_original;
            this.pair_click_paragraph_items.forEach(e => {
                e.list = [];
            });
        },
        reload() {
            this.$forceUpdate();
        },
        
        startdragging(env){
            console.log("dragging id:"+env.clone.id);
            this.selected_wordId = env.clone.id
            
        },
        enddragging(){
            this.reload()
        },
        kasa(){
            console.log("kasa değişti");
        },
        log(evt,pairId){

            this.pair_click_paragraph_items.forEach(e => {

                if(e.pairId == pairId){

                    let li  = 0;
                    e.list.forEach(y => {
                        if(y.wordId != this.selected_wordId){

                            console.log("deleted item");

                            this.word_list.push({
                                wordId: y.wordId,
                                content: y.content
                            });
                            e.list.splice(li, 1);
                            this.selected_wordId = 0;

                        }
                        li++;
                    });

                }
                
            });
        },

        showText(pairId){
            let metin = "";
            this.pair_click_paragraph_items.forEach(e => {
                if(pairId == e.pairId){
                    if(e.type == "text"){
                        metin += e.text+' ';
                    }else{
                        metin += '<span class="pair_chip">'+e.answer+'</span> ';
                    }
                }
            });

            return metin;
        },

        shuffle1(array) {
            return array.sort(() => Math.random() - 0.5);
        },

        getData(){
            const data = { questionId : this.questionId }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_question_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {
                    
                    console.log(response.data);
                    this.questionOrder = response.data.questionOrder;
                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.coverImages = response.data.coverImages;
                    this.pair_click_paragraph_items = response.data.pair_click_paragraph_items;
                    this.pair_click_paragraph_words = response.data.pair_click_paragraph_words;

                    this.pair_click_paragraph_items.forEach(element => {
                        element.list = [];
                    });

                    this.pair_click_paragraph_words.forEach(e => {
                        this.word_list.push({
                            wordId: e.wordId,
                            content: e.content
                        });
                        this.word_list_original.push({
                            wordId: e.wordId,
                            content: e.content
                        });
                    });

                    
                    this.word_list = this.shuffle1(this.word_list);
                    this.word_list_original = this.shuffle1(this.word_list_original);


                }  
            );
        }

    },
    setup() {
        return {
        icons: {mdiFormatAlignLeft },
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted() {
        this.getData();
    },
}
</script>