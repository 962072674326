import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'

//import Auth from './modules/auth'

Vue.use(Vuex);

const getters = {
    getAccessToken: state => {
        return state.accessToken
    },
    getRefreshToken: state => {
        return state.refreshToken
    },
    getUserGroup: state => {
        return state.userGroup
    },
    getAdminDrawer: state => {
        return state.adminDrawer
    },
    getName: state => {
        return state.name
    },
    getInitials: state => {
        return state.initials
    },
    
    getUid: state => {
        return state.uid
    },
    getAuthority: state => {
        return state.authority
    },
    
};
const actions = {};
const mutations = {
    setTokenLogin (state, data) {
        state.accessToken = data.accessToken;
        state.refreshToken = data.refreshToken;
        state.userGroup = data.userGroup;
    },
    setAccessTokenUserGroup (state, data) {
        state.accessToken = data.accessToken;
        state.userGroup = data.userGroup;
    },
    setUserGroup (state, userGroup) {
        state.userGroup = userGroup;
    },
    setAdminDrawer (state, drawer) {
        state.adminDrawer = drawer;
    },
    setName (state, drawer) {
        state.name = drawer;
    },
    setInitials (state, drawer) {
        state.initials = drawer;
    },
    setUid (state, drawer) {
        state.uid = drawer;
    },
    setAuthority (state, authority) {
        state.authority = authority;
    },

};

export const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        uid:"",
        name: "",
        initials: "",
        accessToken : "",
        refreshToken : "",
        userGroup : "",
        authority : "",
        adminDrawer : true
    },
    actions,
    getters,
    mutations,
});

export default store;

