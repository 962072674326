<template>
  <v-navigation-drawer v-model="drawer" app  width="260" :right="$vuetify.rtl"  >

    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/eisadmin" class=" text-center text-decoration-none">
        <img src="@/assets/images/EISEnglish_logo.png" width="160px">
      </router-link>
    </div>

    <v-list expand class="vertical-nav-menu-items " >

      <v-list-group :value="true" >
          <v-icon slot="prependIcon" color="primary">{{ icons.mdiBook }}</v-icon>
        
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Books</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item  :to="'/eisadmin/books'" class="ms-3" link>
            <v-list-item-icon><v-icon >{{ icons.mdiCircleMedium }}</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>All Books</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item  :to="'/eisadmin/archivedbooks'" class="ms-3" link>
            <v-list-item-icon><v-icon>{{ icons.mdiCircleMedium }}</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Archived Books</v-list-item-title></v-list-item-content>
          </v-list-item>




        </v-list-group>



      <v-list-item  :to="'/eisadmin/schools'" link>
        <v-list-item-icon><v-icon color="primary">{{ icons.mdiSchool }}</v-icon></v-list-item-icon>
        <v-list-item-content><v-list-item-title>Schools</v-list-item-title></v-list-item-content>
      </v-list-item>


      <v-list-item :to="'/eisadmin/teachers'" link>
        <v-list-item-icon><v-icon color="primary">{{ icons.mdiHumanMaleBoard }}</v-icon></v-list-item-icon>
        <v-list-item-content><v-list-item-title>Teachers</v-list-item-title></v-list-item-content>
      </v-list-item>

      <v-list-item  :to="'/eisadmin/students'" link>
        <v-list-item-icon><v-icon color="primary">{{ icons.mdiAccount }}</v-icon></v-list-item-icon>
        <v-list-item-content><v-list-item-title>Students</v-list-item-title></v-list-item-content>
      </v-list-item>

      <LeftMenuSeperator v-if="authority == 'admin'" title="OTHERS" />

      <v-list-item  v-if="authority == 'admin'"  :to="'/eisadmin/users'" link>
        <v-list-item-icon><v-icon color="primary">{{ icons.mdiShieldAccount }}</v-icon></v-list-item-icon>
        <v-list-item-content><v-list-item-title>Admin Users</v-list-item-title></v-list-item-content>
      </v-list-item>

      

    </v-list>

  </v-navigation-drawer>
</template>

<script>

import { mdiBook, mdiHumanMaleBoard ,mdiAccount, mdiSchool ,mdiAccountGroup ,mdiShieldAccount , mdiCircleMedium    } from '@mdi/js'
import LeftMenuSeperator from '../../eisadmin/components/LeftMenuSeperator'



export default {
  components: {
    LeftMenuSeperator
  },

  data() {
    return {
      drawer : this.$store.getters.getAdminDrawer,
      authority :""
    }
  },
  created()  {
    this.$store.watch( (state, getters) => getters.getAdminDrawer, (n) => { this.drawer = n; });
    this.authority = this.$store.getters.getAuthority  ;
  },
  watch: {
    drawer: function (newValue) {
      this.$store.commit('setAdminDrawer', newValue);
    }
  },

  setup() {
    return {
      icons : {
        mdiBook : mdiBook,
        mdiSchool: mdiSchool,
        mdiAccountGroup:mdiAccountGroup,
        mdiHumanMaleBoard: mdiHumanMaleBoard,
        mdiAccount: mdiAccount,
        mdiCircleMedium :mdiCircleMedium,
        mdiShieldAccount:mdiShieldAccount 
      
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}




</style>
