<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P">
        <v-container class="pt-0 pb-12">

          <div class="mt-5">
            <a href="/teacher/messages" class="mt-4 link_blue2">
                <v-icon class="mt-n1 link_blue2" >{{icons.mdiChevronLeft  }}</v-icon>Back
            </a>
          </div>

            <div class="d-flex flex-row mt-8">
              <v-icon size="30" class="black--text mr-3 mt-2" > {{ icons.mdiEmailOutline }}</v-icon>
              <div class="f_24 font_bold pt-1 black--text "> Messages</div>
            </div>


            <div class="row mt-8">
              <div class="mx-3">
                <div class=" font_medium">{{studentName}} - {{studentEmail}}</div>
                <div>Class: {{className}} </div>
              </div>
              <v-spacer></v-spacer>
              <v-btn text @click="openMessageModal(0)"><v-icon class="mr-1">{{icons.mdiEmailOutline }}</v-icon> Send Message</v-btn>
            </div>


            <v-simple-table  class="mt-10">
              <template v-slot:default>
                <tbody >
                  <tr v-for="item in messages" :key="item.messageId">
                    <td class="tabs_border ">
                      <div class="py-2 color_black">{{item.message}}</div>
                      <div class="py-2 f_14">{{item.date_created_text}}
                          <v-icon v-if="item.readed == '0'" size="20" class="black--text ml-3 " >{{ icons.mdiCheck }}</v-icon>
                          <v-icon  v-if="item.readed == '1'" size="20" class="blue--text ml-3 " >{{ icons.mdiCheckAll }}</v-icon>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </template>
            </v-simple-table>

              

        </v-container>
      </div>

      <v-dialog v-model="dialogMessage" max-width="600px">
            <v-card class="color_normal">
                <v-card-title class="text-h5">
                    Send Message
                </v-card-title>

                <v-card-text>

                    <v-textarea
                    outlined
                    label="Your Message"
                    v-model="message"
                    ></v-textarea>

                </v-card-text>

                <v-card-actions >
                    <v-spacer></v-spacer>
                    <v-btn color="black" outlined elevation="0" @click="sendMessage()" >Send</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

  </v-app>

</template>
 
<script>
  import checkAuthTeacher from '../../mixins/checkAuthTeacher';
  import TopBar from '../../views/teacher/components/TopBar'
  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup ,mdiChevronLeft, mdiMagnify ,mdiEmailOutline, mdiCheck ,mdiCheckAll ,mdiAlertCircle ,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/teacher/components/Vtoast';
  import Confirm from '../../views/teacher/components/Confirm.vue';

  export default {
    mixins: [checkAuthTeacher],
    components: {
      TopBar,  Vtoast, Confirm
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,
          mdiMagnify,
          mdiEmailOutline,mdiChevronLeft,
          mdiCheck,mdiCheckAll,mdiAlertCircle,mdiCheckBold
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',
      dialogClass: false,
      dialogKey: false,

      studentId : 0,
      studentName: '',
      studentEmail: '',
      className: '',

      messages : [],

      message : "",

      dialogMessage: false
      
      

    }),
    methods:{

      openMessageModal(){
          this.dialogMessage = !this.dialogMessage; 
      },

      sendMessage(){
            
            let ss = "1";

            if(this.message == ""){
                ss = "0";
                this.$root.Vtoast.show({message: 'You must write a message!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
            }

            if(ss == "1"){

                let users = [];
                users.push(this.studentId);


                const data = { values : users, toAll : false, message :this.message , classId: 0 }
                axios.post(process.env.VUE_APP_API_DOMAIN + "/t_send_message",JSON.stringify(data)
                ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
                .then(
                    response => {
                        console.log(response.data);
                        this.$root.Vtoast.show({message: 'Message has been sent succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold });
                        this.dialogMessage = false;
                        this.values = [];
                        this.message = "";

                        this.getData();
                    }  
                );
            }
        },



      getData(){
          const data = {studentId : this.studentId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_message_user",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              
              this.messages = [];

              this.messages = response.data.messages;
              this.studentName = response.data.studentName;
              this.studentEmail = response.data.studentEmail;
              this.className = response.data.className;
            }  
          );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },


    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.studentId = this.$route.params.studentId;
      this.getData();
    },
    

  }
</script>