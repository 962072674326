<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P color_normal">
        <v-container class="pt-0">


          <v-alert  v-for="(alert,index) in homework_alerts" :key="index" class="mt-5"  dense border="left" :icon="icons.mdiAlertOutline" type="warning" >
            <a class="link_white" :href="'/student/unit/'+alert.bookId+'/'+alert.unitId">{{alert.bookName}} > {{alert.unitName}} > {{alert.leafletName}} is active for you until  {{alert.finishDate}}.</a>
          </v-alert>

          <div class="mt-8 border-bottom1 f_26 pb-4">Hi, {{name}}</div>

            <div class="d-none d-sm-block">
              <div class="d-flex flex-row mt-8">
                <v-icon size="30" class="black--text mr-3 mt-2" >{{ icons.mdiBookEditOutline }}</v-icon>
                <div class="f_24 font_bold pt-1 black--text ">My Library</div>
                <v-spacer></v-spacer>
                <v-btn outlined class="ma-2 rounded-pill btn_outline_blue" @click="dialogBook = !dialogBook">
                  <v-icon left  >{{icons.mdiPlusCircleOutline }}</v-icon> Add New Book
                </v-btn>
                <v-btn outlined class="ma-2 rounded-pill btn_outline_pink" @click="dialogClassKey = !dialogClassKey">
                  <v-icon left  >{{icons.mdiPlusCircleOutline }}</v-icon> Join Class
                </v-btn>
              </div>
            </div>

            <div class="d-block d-sm-none text-center mt-8">
                <div class="f_24 font_bold pt-1 black--text mb-3 ">
                  <v-icon size="30" class="black--text mr-3" >{{ icons.mdiBookEditOutline }}</v-icon> My Library
                </div>
                
                <v-btn outlined small class="ma-2 rounded-pill btn_outline_blue" @click="dialogBook = !dialogBook">
                  <v-icon left  >{{icons.mdiPlusCircleOutline }}</v-icon> Add New Book
                </v-btn>
                <v-btn outlined small class="ma-2 rounded-pill btn_outline_pink" @click="dialogClassKey = !dialogClassKey">
                  <v-icon left  >{{icons.mdiPlusCircleOutline }}</v-icon> Join Class
                </v-btn>
            </div>

            <div class="row mt-3">
              <div v-for="item in books" :key="item.bookId" @click="goToBook(item.bookId,item.book_status)" :class="findbookClass(item.book_status)">
                <v-card class="ma-2 pb-2 minhe410">
                  <div><v-img :src="VUE_APP_BOOK_MEDIA+item.bookCover" class="" contain position="center top" height="300" /></div>
                  <div class="mx-3 my-2 text-lg-left text-center">
                    <div class="color_blue f_20 font_bold">{{item.bookName}}</div>
                    <div class="f_14 color_muted">{{item.bookSubtitle}}</div>
                  </div>
                  <div class="mx-3 text-lg-left text-center color_pink my-2">{{item.className}}</div>
                </v-card>
              </div>
            </div>

            <div v-if="books.length == 0" class="mt-8 text-center">
              <div class="font_bold f_24">No book found!</div>
              <div>Please add a book with Book Code.</div>
            </div>

            <v-dialog v-model="dialogClassKey" persistent max-width="600px">
              <v-card>
                <v-card-title class="text-h5">Join Class</v-card-title>
                <v-card-text>
                  <v-text-field label="Class Key" outlined hide-details v-model="classKey"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="dialogClassKey = false" >Close</v-btn>
                  <v-btn color="primary darken-1" elevation="0" @click="joinClass()" >Join</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogBook" persistent max-width="600px">
              <v-card>
                <v-card-title class="text-h5">Add New Book</v-card-title>
                <v-card-text><v-text-field outlined hide-details label="Book Code" v-model="newBookCode"></v-text-field></v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="dialogBook = false" >Close</v-btn>
                  <v-btn color="primary darken-1" elevation="0" @click="addNewBook()" >Add</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

        </v-container>
      </div>

  </v-app>

</template>
 
<script>
  import checkAuthStudent from '../../mixins/checkAuthStudent';
  import TopBar from '../../views/student/components/TopBar'
  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup ,mdiCheckBold, mdiAlertCircle, mdiMagnify , mdiAlertOutline   } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/student/components/Vtoast';
  import Confirm from '../../views/student/components/Confirm.vue';

  export default {
    mixins: [checkAuthStudent],
    components: {
      TopBar,  Vtoast, Confirm
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,
          mdiMagnify,
          mdiAlertOutline 
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      VUE_APP_BOOK_MEDIA : process.env.VUE_APP_BOOK_MEDIA,
      uid: "",
      tab: null,
      name : '',
      initials: '',
      dialogClassKey: false,
      dialogBook: false,
      dialogKey: false,
      classKey: '',
      newBookCode: '',

      books:[],
      bookSelect: 0,
      classes:[],
      page: 1,
      paginationLenght : 0,

      email_approved : 1,

      homework_alerts : []
      

    }),
    methods:{

      findbookClass(status){
        let cls = "col-md-3 cursorLink";
        if(status == "passive"){
          cls = "col-md-3 cursorLink opa50";
        }
        return cls;
      },


      addNewBook(){
        console.log(this.uid);

        const data = {code: this.newBookCode  }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_book_add",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            console.log(response.data);
            if(response.data.result == "fail"){
              this.$root.Vtoast.show({message: 'An error occured!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
            }else if(response.data.result == "noBook"){
              this.$root.Vtoast.show({message: 'No code found available!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
            }else if(response.data.result == "still_active"){
              this.$root.Vtoast.show({message: 'You can\'t add the same book!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
            }else{
              this.$root.Vtoast.show({message: 'Book is added succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold });
              this.books = [];
              this.getData();
              this.dialogBook = false;
            }
          }  
        );

      },
      
      joinClass(){
        console.log(this.classKey);

        const data = {classKey: this.classKey }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_class_join",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            if(response.data.result == "noClass"){
              console.log("noClass");
              this.$root.Vtoast.show({message: 'No class found! Please check your Class Key!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }else if(response.data.result == "noBook"){
              console.log("noBook");
              this.$root.Vtoast.show({message: 'No book found! You need to add book "'+response.data.bookName+'" in your library first!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }else if(response.data.result == "success"){
              console.log("success");
              this.$root.Vtoast.show({message: 'Class is added successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              
            }else{
              console.log("fail");
              this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
            }

          }  
        );

      },

      getData(){

          const data2 = { page : this.page }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_books",JSON.stringify(data2)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              this.books = response.data.books;
              this.homework_alerts = response.data.homework_alerts;
              this.email_approved = response.data.email_approved;

              if(response.data.email_approved == "0" ){
                this.$router.push('/student/approve_email');
              }
            }  
          );

          
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        this.dialogClassKey = false
        this.getData();
      },

      goToBook(bookId,status){
        if(status == "passive"){
          this.$root.Vtoast.show({message: 'Your book code is expired!', snackType: 'fail', color: 'warning', icon: mdiAlertCircle})
        }else{
          this.$router.push(`/student/book/${bookId}`);
        }
        
      }


    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
      let sp = this.$store.getters.getName.split(" ");
      this.name = sp[0];
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    },
    

  }
</script>