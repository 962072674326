<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P">
        <v-container class="pt-0 pb-12">

              <div class="d-flex flex-row mt-8">
                <v-icon size="30" class="black--text mr-3 mt-2" > {{ icons.mdiCog }}</v-icon>
                <div class="f_24 font_bold pt-1 black--text "> My Profile</div>
              </div>

              <div class="row mt-10 tabs_border  py-1">
                <div class="col-md-3 font_bold">Name Surname: </div>
                <div class="col-7">{{teacherName}}</div>
                <div class="col-2 text-right"><v-btn small outlined @click="dialogName = !dialogName">EDIT</v-btn></div>
              </div>
              <div class="row mt-2 tabs_border py-1">
                <div class="col-md-3 font_bold">Email Address: </div>
                <div class="col-7">{{teacherEmail}}</div>
                <div class="col-2 text-right"><v-btn small outlined @click="dialogEmail = !dialogEmail">EDIT</v-btn></div>
              </div>
              <div class="row mt-2 tabs_border py-1">
                <div class="col-md-3 font_bold">Password: </div>
                <div class="col-7">**********</div>
                <div class="col-2 text-right"><v-btn small outlined @click="dialogPass = !dialogPass">EDIT</v-btn></div>
              </div>
              <div class="row mt-2 tabs_border py-1">
                <div class="col-md-3 font_bold">My Schools: 
                </div>
                <div class="col-7">
                  <span v-for="sc in teacherSchools" :key="sc.schoolId">{{sc.schoolName}}, </span>
                </div>
                <div class="col-2 text-right"><v-btn small outlined @click="dialogSchool = !dialogSchool">Add</v-btn></div>
              </div>

        </v-container>

        <v-dialog v-model="dialogSchool" persistent max-width="600px">
      
          <v-card>
            <v-card-title>
              <span class="text-h5">Add New School to Teacher</span>
            </v-card-title>

            <v-card-text>

              <v-select v-model="schoolSelect" :items="all_schools" item-text="schoolName" item-value="schoolId" label="School"  outlined ></v-select>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="dialogSchool = false" >Close</v-btn>
              <v-btn color="primary darken-1" elevation="0" @click="saveData" >Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="dialogPass" persistent max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Edit Your Password</v-card-title>
            <v-card-text>
              <v-text-field outlined hide-details label="Current Password" v-model="teacherPass_old" type="password"></v-text-field>
              <v-text-field class="mt-3" outlined hide-details label="New Password" v-model="teacherPass_new" type="password"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="dialogPass = false" >Close</v-btn>
              <v-btn color="primary darken-1" elevation="0" @click="savePass()" >Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogName" persistent max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Edit Your Name</v-card-title>
            <v-card-text><v-text-field outlined hide-details label="Name Surname" v-model="teacherName2"></v-text-field></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="dialogName = false" >Close</v-btn>
              <v-btn color="primary darken-1" elevation="0" @click="saveName()" >Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEmail" persistent max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Edit Your Email Address</v-card-title>
            <v-card-text>
              <v-text-field v-if="emailSent == 0" outlined hide-details label="Email Address" v-model="teacherEmail2"></v-text-field>
              
              <div class="mb-4" v-if="emailSent == 1">We have sent an email to you! Please enter the core here:</div>
              <v-text-field v-if="emailSent == 1" outlined hide-details label="Code" v-model="code"></v-text-field>
            
            </v-card-text>

            
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="dialogEmail = false" >Close</v-btn>
              <v-btn v-if="emailSent == 0" color="primary darken-1" elevation="0" @click="saveEmail()" >Save</v-btn>
              <v-btn  v-if="emailSent == 1" color="primary darken-1" elevation="0" @click="doneEmail()" >Done</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </div>

  </v-app>

</template>

<script>
  import checkAuthTeacher from '../../mixins/checkAuthTeacher';
  import TopBar from '../../views/teacher/components/TopBar'
  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup , mdiMagnify ,mdiCog, mdiAlertCircle  } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/teacher/components/Vtoast';
  import Confirm from '../../views/teacher/components/Confirm.vue';

  export default {
    mixins: [checkAuthTeacher],
    components: {
      TopBar,  Vtoast, Confirm
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,
          mdiMagnify,
          mdiCog,
          mdiAlertCircle
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',
      dialogClass: false,
      dialogKey: false,

      teacherName: '',
      teacherName2: '',
      teacherEmail: '',
      teacherEmail2: '',
      teacherSchools: '',
      all_schools : [],

      teacherPass_old : '',
      teacherPass_new : '',

      emailSent: 0,
      code: "",

      schoolSelect: 0,
      dialogSchool: false,
      dialogName: false,
      dialogEmail: false,
      dialogPass: false,

    }),
    methods:{

      doneEmail(){
        if(this.code != ""){
          const data = {
            code : this.code,
            newEmail : this.teacherEmail2
          }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_save_email",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              if(response.data.result == 'success'){
                this.emailSent = 0
                this.dialogEmail = false;
                this.getData();
              }else{
                this.$root.Vtoast.show({message: 'Please enter the code we have sent!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
              }
            }  
          );
        }else{
          this.$root.Vtoast.show({message: 'You need to enter your new email address!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
        }
      },

      saveEmail(){
        if(this.teacherEmail2 != ""){
          
          const data = {
            teacherEmail : this.teacherEmail2
          }
          
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_save_email_sendmail",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              if(response.data.result == 'success'){
                this.emailSent = 1
              }else{
                this.$root.Vtoast.show({message: 'This email has been taken by another user!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
              }
            }  
          );
        }else{
          this.$root.Vtoast.show({message: 'You need to enter your new email address!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
        }
      },

      savePass(){

        if(this.teacherPass_old != "" || this.teacherPass_new != ""){
          const data = {
            teacherPass_old : this.teacherPass_old,
            teacherPass_new : this.teacherPass_new
          }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_save_pass",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              if(response.data.result == 'success'){
                this.dialogPass = false;
                this.getData();
              }else{
                this.$root.Vtoast.show({message: 'Wrong password! Please check your current password. ', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
              }
            }  
          );
        }else{
          this.$root.Vtoast.show({message: 'You need to enter your current and new password!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
        }
      },


      saveName(){
        if(this.teacherName2 != ""){
          const data = {
            teacherName : this.teacherName2
          }
        
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_save_name",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            () => {
              this.dialogName = false;
              this.getData();
            }  
          );
        }else{
          this.$root.Vtoast.show({message: 'You need to enter your name and surname!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
        }
          
      },

      saveData(){
        const data = {schoolId : this.schoolSelect }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_school_add",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          () => {
            this.dialogSchool = false;
            this.getData();
          }  
        );
      },

      getData(){
          const data = { }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_details",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              this.teacherName = response.data.teacherName;
              this.teacherName2 = response.data.teacherName;
              this.teacherEmail = response.data.teacherEmail;
              this.teacherEmail2 = response.data.teacherEmail;
              this.teacherSchools = response.data.teacherSchools;
            }  
          );

          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_schools",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              this.all_schools = response.data.schools;
            }  
          );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },


    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    },
    

  }
</script>