<template>
  <div class=" py-2 bg_header">
    <v-card  tile flat color="transparent" >
      <v-container class="pa-0" >
        <v-toolbar color="transparent" flat>
          <a href="/student" >
          <v-img :src="require('@/assets/images/EISEnglish_logo.png')" max-width="160px" class=""></v-img>
          </a>

          <v-spacer></v-spacer>


          <div class="text-center d-none d-sm-block">

            <a href="/student" :class="headerMenuColor('')" >
              <v-icon size="28" :class="headerMenuIconColor('')" > {{ icons.mdiBookEditOutline  }}</v-icon> My Library
            </a>

          </div>
          
          <v-spacer></v-spacer>

          <v-btn icon depressed to="/student/messages" class="mr-3">
            <v-icon size="24" class="">{{ icons.mdiEmailOutline  }}</v-icon>
            <v-badge v-if="messageBadge>=1" color="red" :content="messageBadge" />
          </v-btn>

          <v-menu  bottom left offset-y  max-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" icon v-bind="attrs" v-on="on">
                <v-avatar color="teal" size="40"><span class="white--text f_16">{{initials}}</span></v-avatar>
              </v-btn>
            </template>
            <v-list >

              <div class="mx-2"><v-btn to="/student/settings" text block >MY PROFILE</v-btn></div>
              <div class="mx-2 d-block d-sm-none"><v-btn to="/student" text block >MY LIBRARY</v-btn></div>
              <div class="mx-2"><v-btn to="/student/logout" text block >LOGOUT</v-btn></div>
              
            </v-list>
          </v-menu>

        </v-toolbar>
      </v-container>
    </v-card>
  </div>

</template>

<script>
import { mdiLogout, mdiBellOutline, mdiBookEditOutline  ,mdiAccountDetails , mdiEmailOutline   } from '@mdi/js'
import axios from 'axios';

export default {
  data: () => ({
      valid: true,
      name : '',
      initials: '',
      page : "",

      messageBadge : 0
  }),
  methods: {

    getData(){
        

          const data = { }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_unreaded_message_count",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              this.messageBadge = response.data.messageBadge;
            }  
          );

      },

    headerMenuColor(gln){
      let cls = "header_menu_selected mx-3";
      if(gln != this.page){
          cls = "header_menu_normal mx-3 ";
      }
      return cls;
    },

    headerMenuIconColor(gln){
      let cls = "header_menu_selected_icon mr-1";
      if(gln != this.page){
          cls = "black--text mr-1";
      }
      return cls;
    },

    handleDrawer() {

      if(this.$store.getters.getAdminDrawer){
        this.$store.commit('setAdminDrawer', false);
      }else{
        this.$store.commit('setAdminDrawer', true);
      }

    },
    checkDrawer(){
      //console.log("checkDrawer");
      //console.log(this.$store.getters.getAdminDrawer);
      //console.log(this.$store.getters.getUserGroup);
    }
  },
  setup() {
    return {
      icons: { mdiLogout , mdiBellOutline ,mdiBookEditOutline , mdiAccountDetails , mdiEmailOutline  },
    }
  },
  created () {
    this.initials =  this.$store.getters.getInitials;
    this.name =  this.$store.getters.getName;
    console.log(this.$route.name);

    if(this.$route.name == "teacher_Classes"){
      this.page = "classes";
    }else if(this.$route.name == "teacher_Library"){
      this.page = "library";
    }
    this.getData();
  },
}
</script>

