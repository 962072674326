<template>

    <div class="pa-2">

        <div class="f_20 mt-3 font_bold">{{questionOrder}} - {{questionTitle}}</div>

        <div class="mt-4 image_galdiv" v-if="coverType == 'image'">
            <Splide :options="{ rewind: true , arrows: false}">
                <SplideSlide v-for="image in coverImages" :key="image.imageId">
                <img :src="VUE_APP_QUESTION_MEDIA+image.image" style="width: 100%">
                </SplideSlide>
            </Splide>
        </div>
        <div class="mt-4 image_viddiv" v-if="coverType == 'video'">
            <video width="100%" height="auto" controls controlsList="nodownload">
                <source :src="coverVideo" type="video/mp4">
            </video>
        </div>
        <div class="mt-4" v-if="coverType == 'sound'">
            <audio controls class="audio_shadow audio_w"  controlsList="nodownload">
                <source :src="coverSound" type="audio/mp3">
            </audio>
            <v-btn class="ml-3 mt-n10" @click="dialogSound = !dialogSound" icon outlined color="secondary" depressed elevation="2" dark><v-icon class="mt-n1" size="20">{{icons.mdiFormatAlignLeft }}</v-icon></v-btn>
        </div>
        <div class="mt-4" v-if="coverType == 'text'"><span v-html='markup_do(coverText)'></span></div>

        <div class="mt-2 prglineh row">
            <div class="col-12">
                <span v-for="item in dropdown_paragraph_items" :key="item.dropId"  >
                    <span  v-if="item.dropType == 'text'"><span v-html='markup_do(item.dropText)'></span></span>

                    <select style="display:inline" class="mx-2 selectInline"  v-if="item.dropType == 'drop'" v-model="item.selectedId" @change="selectItem()" >
                        <option :selected="correctOption(item.dropId,ops.dropOptionId)" :value="ops.dropOptionId" v-for="ops in item.options" :key="ops.dropOptionId">{{ops.content}}</option>
                    </select>

                </span>
            </div>
            
        </div>


    <v-dialog v-model="dialogSound" max-width="600px">
            <v-card>
                <div class="pa-4" v-html='markup_do(coverSoundText)'>
                
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>

import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@/styles/main.css'
import markup from '../../../mixins/markup';
import {  mdiFormatAlignLeft  } from '@mdi/js'

export default {
    mixins: [markup],
    components: { Splide, SplideSlide  },
    data: () => ({
        VUE_APP_API_DOMAIN : "",
        VUE_APP_QUESTION_MEDIA : process.env.VUE_APP_QUESTION_MEDIA,
        questionOrder : null,
        questionTitle : "",
        coverType : "",
        coverVideo : "",
        coverSound : "",
        coverSoundText : "",
        coverText : "",
        coverImages : null,
        dropdown_paragraph_items: null,
        dialogSound: false
        


    }),
    props: {
        questionId: String
    },
    methods: {

        selectItem(){
            let allOk = true;
            this.dropdown_paragraph_items.forEach(e => {

                if(e.selectedId == undefined && e.dropType == 'drop'){
                    allOk = false
                }

            });

            this.$emit('makeAllAnswered', allOk);
        },

        goingData(){

            let dat = { 
                questionId :  this.questionId,
                questionType : "dropdown_paragraph",
                answerDetails : [] 
            }

            this.dropdown_paragraph_items.forEach(e => {
                if(e.dropType == 'drop'){
                    dat.answerDetails.push({
                        dropId : e.dropId,
                        reply : e.selectedId
                    });
                }
            });

            return dat
        },


        test(){
            console.log(this.dropdown_paragraph_items);
            console.log(this.goingData());
        },
        reload() {
            this.$forceUpdate();
        },

        resetQuestion(){
            this.dropdown_paragraph_items.forEach(e => {
                let i = 0;
                e.options.forEach(k => {
                    if(i == 0){
                        k.correct = "1";
                    }else{
                        k.correct = "0";
                    }
                    i++;
                });

            });
            this.reload();
        },

        correctQuestion(){
            this.dropdown_paragraph_items.forEach(e => {
                e.options.forEach(k => {
                    if(k.dropOptionId == e.correctOptionId){
                        k.correct = "1";
                    }
                });
            });
            this.reload();
        },

        correctOption(dropId,dropOptionId){
            let res =  false;
            this.dropdown_paragraph_items.forEach(e => {
                
                if(e.dropId == dropId){

                    e.options.forEach(k => {
                        if(k.dropOptionId == dropOptionId){
                            if(k.correct == "1"){
                                res = true;
                            }
                        }
                    });

                }
            });
            return res
        },

        getData(){
            const data = { questionId : this.questionId }
            axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_question_details",JSON.stringify(data)
            ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
            .then(
                response => {

                    this.questionOrder = response.data.questionOrder;
                    this.questionTitle = response.data.questionTitle;
                    this.coverType = response.data.coverType;
                    this.coverVideo = response.data.coverVideo;
                    this.coverSound = response.data.coverSound;
                    this.coverSoundText = response.data.coverSoundText;
                    this.coverText = response.data.coverText;
                    this.coverImages = response.data.coverImages;
                    this.dropdown_paragraph_items = response.data.dropdown_paragraph_items;

                    this.dropdown_paragraph_items.forEach(e => {
                        let correctOptionId = "30";
                        e.options.forEach(k => {
                            if(k.correct == "1"){
                                correctOptionId = k.dropOptionId;
                            }
                            k.correct = "0";
                            
                        });

                        e.options.unshift({
                            content: "",
                            correct: "1",
                            dropOptionId: e.dropOptionId,

                        });

                        e.correctOptionId = correctOptionId;
                        e.selectedId = undefined;
                    });

                }  
            );
        }

    },
    setup() {
        return {
        icons: { mdiFormatAlignLeft},
        }
    },
    created () {
        this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    },
    mounted() {
        this.getData();
    },
}
</script>