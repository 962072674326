<template>

  <v-app>
      <TopBar  />
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white minhe100P">
      <v-container class="pt-0">

        
        <div class="mt-5">
          <a :href="'/teacher/class/'+classId" class="mt-4 link_blue2">
              <v-icon class="mt-n1 link_blue2" >{{icons.mdiChevronLeft  }}</v-icon>Back
          </a>
        </div>

        <ClassDetails :classStartDate="classStartDate" :classEndDate="classEndDate" :className="className" :schoolName="schoolName" :classKey="classKey"/>

        <BookDetails :bookCode="bookCode" :bookCover="bookCover" :bookName="bookName"  :bookSubtitle="bookSubtitle"  :bookDescription="bookDescription" />

        <div class="mt-8">

          <div class="tabs_border pb-1 f_24 font_bold  mb-4">
            <v-icon size="32">{{icons.mdiTextBoxOutline }}</v-icon>  {{unitName}}
          </div>

          <v-simple-table v-for="item in leaflets" :key="item.leafletId">
            <template v-slot:default>
              <tbody>
                <tr >
                  <td width="70" class="text-right d-none d-sm-table-cell">
                    <v-img :src="require('@/assets/images/quizIcon.png')" max-width="70px" class="ma-1"></v-img>
                  </td>
                  <td class="tabs_border">
                    <div class="f_18 font_bold ">{{ item.leafletName }} 
                    
                    <v-chip v-if="item.leafletStatu.statu == 'finished'" color="primary" class="ml-2 font_regular" small>finished</v-chip>
                    <v-chip v-if="item.leafletStatu.statu == 'started'" color="green" class="ml-2 font_regular" small>started</v-chip>
                    <v-chip v-if="item.leafletStatu.statu == 'waiting'" color="orange" class="ml-2 font_regular" small>waiting</v-chip>

                    </div>
                    <div class="f_12 color_muted">{{ item.leafletStatu.time_start }} - {{ item.leafletStatu.time_end }}</div>
                    <div class="f_12 color_muted mt-1">{{ limitText(item.leafletDescription,80) }}</div>

                  </td>
                  <td width="130" class=" tabs_border  d-none d-sm-table-cell">
                    {{ item.questionCount }}  questions
                  </td>
                  <td width="100" class="text-right tabs_border">
                    <v-menu  bottom left offset-y  max-width="200">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" icon v-bind="attrs" v-on="on">
                          <v-icon>{{icons.mdiDotsVertical}}</v-icon>
                        </v-btn>
                      </template>
                      <v-list >
                        <div class="mx-2"><v-btn text block :href="'/teacher/leaflet/'+bookId+'/'+classId+'/'+unitId+'/'+item.leafletId" >QUESTIONS</v-btn></div>
                        <div class="mx-2"><v-btn  text block @click="openDateModal(item.leafletId)">HOMEWORK</v-btn></div>
                        <div class="mx-2"><v-btn v-if="item.leafletStatu.statu != 'waiting' && item.leafletStatu.homeworkId != '0'" text block :href="'/teacher/homework_results/'+item.leafletStatu.homeworkId">RESULTS</v-btn></div>
                        
                      </v-list>
                    </v-menu>
                  </td>
                </tr>

              </tbody>
            </template>
          </v-simple-table>

        </div>


        <v-dialog v-model="dialogDates" persistent max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Homework</v-card-title>
            <v-card-text>


              <div class="row mt-3 mb-1">
                <div class="col-6 px-1">
                  
                  <v-menu  ref="menuStart"   v-model="menuStart" :close-on-content-click="false" :return-value.sync="dateStart" transition="scale-transition" offset-y min-width="auto">
                    <template  v-slot:activator="{ on, attrs }">
                      <v-text-field  hint="MM/DD/YYYY format"   v-model="dateStart" label="Start Date" outlined hide-details readonly v-bind="attrs" v-on="on" ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateStart" :min="nowDate"  no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuStart = false">Cancel</v-btn>
                      <v-btn color="primary" @click="$refs.menuStart.save(dateStart)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>

                </div>
                <div class="col-3 px-1">
                  <v-select hide-details v-model="hourStart" :items="hours" item-text="Hour" item-value="Hour" max-width="10"   label="Hour"  outlined  ></v-select>
                </div>
                <div class="col-3 px-1">
                  <v-select hide-details v-model="minuteStart" :items="minutes" item-text="Minute" item-value="Minute"   label="Minute"  outlined  ></v-select>
                </div>
              </div>

              <div class="row mt-1 mb-4">
                <div class="col-6 px-1">

                  
                  
                  <v-menu  ref="menuEnd"   v-model="menuEnd" :close-on-content-click="false" :return-value.sync="dateEnd" transition="scale-transition" offset-y min-width="auto">
                    <template  v-slot:activator="{ on, attrs }">
                      <v-text-field  hint="MM/DD/YYYY format"  v-model="dateEnd" label="Due Date" outlined hide-details readonly v-bind="attrs" v-on="on" ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateEnd" :min="nowDate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuEnd = false">Cancel</v-btn>
                      <v-btn color="primary" @click="$refs.menuEnd.save(dateEnd)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>

                </div>
                <div class="col-3 px-1">
                  <v-select hide-details v-model="hourEnd" :items="hours" item-text="Hour" item-value="Hour" max-width="10"   label="Hour"  outlined  ></v-select>
                </div>
                <div class="col-3 px-1">
                  <v-select hide-details v-model="minuteEnd" :items="minutes" item-text="Minute" item-value="Minute"   label="Minute"  outlined  ></v-select>
                </div>
              </div>





    
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="dialogDates = false" >Close</v-btn>
              <v-btn color="primary darken-1" elevation="0" @click="saveDates()" >START</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-container>
      </div>

  </v-app>

  

</template>

<style >
  
</style>
 
<script>
  import checkAuthTeacher from '../../mixins/checkAuthTeacher';
  import TopBar from '../../views/teacher/components/TopBar'
  import BookDetails from '../../views/teacher/components/BookDetails'
  import ClassDetails from '../../views/teacher/components/ClassDetails'
  import {  mdiBookOpenPageVariant,mdiPlusCircleOutline,mdiAccountGroup ,mdiDotsVertical ,mdiChevronRight,
  mdiRecordCircleOutline ,mdiTextBoxOutline,mdiChevronLeft ,mdiAlertRhombus    } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/teacher/components/Vtoast';
  import Confirm from './components/Confirm.vue';

  export default {
    mixins: [checkAuthTeacher],
    components: {
      TopBar,  Vtoast, Confirm, BookDetails , ClassDetails
    },
    setup() {
      return {

        icons: {
          mdiBookOpenPageVariant,
          mdiPlusCircleOutline ,
          mdiAccountGroup ,
          mdiDotsVertical ,
          mdiChevronRight,
          mdiRecordCircleOutline ,
          mdiTextBoxOutline ,
          mdiChevronLeft,mdiAlertRhombus
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',

      classId:0,
      bookId:0,
      unitId:0,
      unitName:"",
      schoolName:"",
      className:"",
      classKey:"",
      classStartDate:"",
      classEndDate:"",
      classClosed:null,
      bookName:"",
      bookSubtitle:"",
      bookCover: "",
      bookDescription: "",
      bookCode: "",
      leaflets:[],

      selectedLeaflet : 0,

      dialogDates: false,

      dateStart: null,
      dateEnd: null,
      menuStart: false,
      menuEnd: false,
      hourStart:null,
      minuteStart:null,
      hourEnd:null,
      minuteEnd:null,
      hours:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
      minutes:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59],
      nowDate : ""

    }),
    methods:{

      test(){
        console.log(this.dateStart);
      },

      limitText(text,len){
        return  text.length > len ? text.substring(0, len - 3) + "..." : text;
      },


      openDateModal(leafletId){

        console.log(this.leaflets);

        let isHwVar = 0;

        this.leaflets.forEach(e => {
          if(e.leafletId == leafletId && e.leafletStatu.homeworkId != "0"){
            
            this.dateStart = e.leafletStatu.start_data_4pop;
            this.hourStart = parseInt(e.leafletStatu.start_hour_4pop);
            this.minuteStart = parseInt(e.leafletStatu.start_minute_4pop);

            this.dateEnd = e.leafletStatu.end_data_4pop;
            this.hourEnd = parseInt(e.leafletStatu.end_hour_4pop);
            this.minuteEnd = parseInt(e.leafletStatu.end_minute_4pop);
            isHwVar = 1;
          }
        });

        if(isHwVar == 0){
          this.dateStart = null;
          this.hourStart = null;
          this.minuteStart = null;

          this.dateEnd = null;
          this.hourEnd = null;
          this.minuteEnd = null;
        }

        console.log(this.dateStart);
        if(this.classClosed){
          this.dialogDates = !this.dialogDates; 
          this.selectedLeaflet = leafletId;

        }else{
          this.$root.Vtoast.show({message: 'This class is ended! Please contact with the admin!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
        }


      },

      saveDates(){
        const data2 = { 
          leafletId : this.selectedLeaflet,

          dateEnd : this.dateEnd,
          hourEnd : this.hourEnd,
          minuteEnd : this.minuteEnd,
          
          dateStart : this.dateStart,
          hourStart : this.hourStart,
          minuteStart : this.minuteStart,
          
          classId : this.classId
        };

        console.log(data2);

        if(this.dateEnd == null || this.dateStart == null || this.hourEnd == null || this.minuteEnd == null 
        || this.hourStart == null  || this.minuteStart == null 
        ){
          this.$root.Vtoast.show({message: 'You must select the start and due dates!', snackType: 'error', color: 'error', icon: mdiAlertRhombus  });
        }else{


          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_leafletdate_save",JSON.stringify(data2)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            (res) => {

              console.log(res.data);
              this.dialogDates = false;
              this.getData();
            }  
          );


        }
        

      },


      getData(){

          const data = { classId : this.classId, bookId : this.bookId, unitId: this.unitId  }

          console.log(data);
          axios.post(process.env.VUE_APP_API_DOMAIN + "/t_teacher_class_unit",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log("response.data");
              console.log(response.data);
              this.bookId = response.data.bookId;
              this.bookName = response.data.bookName;
              this.bookSubtitle = response.data.bookSubtitle;
              this.bookCover = response.data.bookCover;
              this.bookDescription = response.data.bookDescription;
              this.bookCode = response.data.bookCode;
              this.leaflets = response.data.leaflets;
              this.className = response.data.className; 
              this.unitName = response.data.unitName; 
              this.classKey = response.data.classKey; 
              this.schoolName = response.data.schoolName; 
              this.classStartDate = response.data.classStartDate; 
              this.classEndDate = response.data.classEndDate; 
              this.classClosed = response.data.classClosed; 

            }  
          );

          
      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },


    },
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN
      this.uid = this.$store.getters.getUid;
      this.classId = this.$route.params.classId;
      this.bookId = this.$route.params.bookId;
      this.unitId = this.$route.params.unitId;

      const dd=  new Date();
      this.nowDate =dd.getFullYear()+"-"+("0" + (dd.getMonth() + 1)).slice(-2)+"-"+ ("0" + (dd.getDate() )).slice(-2)
      console.log("nowDate:"+this.nowDate);
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    },
    

  }
</script>