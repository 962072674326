<template>

  <v-app>
      <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
      <Confirm ref="Confirm" />
      <div class="bg_white color_normal minhe100P">
        <v-container class="pt-0">

            <div class="f_24 font_bold pt-1 black--text text-center mt-8"> Approve Your Email Address</div>

              <div class="row mt-5  py-1">
                <div class="col-12 text-center">
                    <div class="mb-4" >We have sent an email to you! Please enter the code here:</div>
                    
                    <div class="mx-auto" style="width:300px">
                      <v-text-field outlined hide-details label="Code"  v-model="code"></v-text-field>
                    </div>
                    <v-btn class="mt-3"  color="primary darken-1" elevation="0" @click="done()" >Continue</v-btn>
                    
                    <div class="mt-6" >If you didn't get the email: <a href="#" class="link_blue" @click="resend()">resend</a></div>
                    
                </div>
              </div>

        </v-container>

      </div>

  </v-app>

</template>

<script>
  import checkAuthStudent from '../../mixins/checkAuthStudent';
  import {  mdiBookEditOutline,mdiPlusCircleOutline,mdiAccountGroup,
  mdiChevronLeft,  mdiMagnify ,mdiAlertCircle ,mdiCheckBold, mdiRecordCircleOutline, mdiChevronRight, mdiCog } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/student/components/Vtoast';
  import Confirm from '../../views/student/components/Confirm.vue';

  export default {
    mixins: [checkAuthStudent],
    components: {
      Vtoast, Confirm,
    },
    setup() {
      return {

        icons: {
          mdiBookEditOutline,
          mdiPlusCircleOutline ,
          mdiAccountGroup,
          mdiMagnify,
          mdiAlertCircle,
          mdiCheckBold,
          mdiRecordCircleOutline,
          mdiChevronRight,
          mdiChevronLeft,
          mdiCog
        },
      }
    },
    data: () => ({
      VUE_APP_API_DOMAIN : "",
      uid: "",
      tab: null,
      name : '',
      initials: '',
      code: '',


    }),
    methods:{

      resend(){
        const data = {}
        axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_send_approve_code",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            () => {
              this.$root.Vtoast.show({message: 'We have sent you an email, please check your email address!', snackType: 'success', color: 'success', icon: mdiCheckBold })
            }  
          );

      },


      done(){
        if(this.code != ""){
          const data = {
            code : this.code
          }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/s_student_approve_email",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response.data);
              if(response.data.result == 'success'){
                  this.$router.push('/student');
              }else{
                this.$root.Vtoast.show({message: 'Please check the code!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
              }
            }  
          );
        }else{
          this.$root.Vtoast.show({message: 'Please enter the code which we have sent you!', snackType: 'error', color: 'error', icon: mdiAlertCircle  });
        }
      },

    },

    
    created() {
      this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
      this.uid = this.$store.getters.getUid;
    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;

      //this.getData();
    },
    

  }
</script>