<template>
  <v-card height="66px" tile flat >
    <v-toolbar flat>

      <v-app-bar-nav-icon @click="handleDrawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn to="/eisadmin/logout" class="me-2" depressed>
        <v-icon size="22" class="mr-2"> {{ icons.mdiLogout }}</v-icon> Logout
      </v-btn>

    </v-toolbar>
  </v-card>
</template>

<script>
import { mdiLogout } from '@mdi/js'

export default {
  methods: {
    handleDrawer() {

      if(this.$store.getters.getAdminDrawer){
        this.$store.commit('setAdminDrawer', false);
      }else{
        this.$store.commit('setAdminDrawer', true);
      }

    },
    logout(){
      //console.log("checkDrawer");
      //console.log(this.$store.getters.getAdminDrawer);
      //console.log(this.$store.getters.getUserGroup);
    }
  },
  setup() {
    return {
      icons: { mdiLogout },
    }
  },
}
</script>

