<template>

<v-app>
    <TopBar  />
    <LeftMenu ></LeftMenu>
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />

    <div class="pa-5" >
      
      <v-card elevation="0" outlined>
        <v-card-title>CREATE NEW BOOK</v-card-title>

        <div class="pa-5"> 
          <v-form ref="form" v-model="valid" lazy-validation>

            <v-row>
              <v-col cols="12" md="8">
                <v-text-field v-model="bookName" outlined :rules="nameRules" label="Book Name" required ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="bookSubtitle" outlined  label="Subtitle" ></v-text-field>
              </v-col>
            </v-row>

            
            <v-textarea outlined v-model="bookDescription" name="description" label="Book Description" value=""></v-textarea>
            <v-file-input v-model="files" outlined :rules="imageRules" required accept="image/png, image/jpeg" placeholder="Book Cover" label="Book Cover" name ="Book Cover" ></v-file-input>
            

            <v-color-picker v-model="bgCo" hide-inputs elevation="0" mode="hexa" class="mt-4"  ></v-color-picker>
            <v-text-field v-model="bgCo" outlined  label="Leaflet Header Color" ></v-text-field>

          </v-form>
        </div>

        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <v-btn class="float-right" @click="submit" color="primary" elevation="0" >CREATE</v-btn>
        </v-card-actions>

      </v-card>

    </div>

</v-app>

</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiMagnify, mdiBellOutline, mdiAlertRhombus, mdiCheckBold} from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';


  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast },

    setup() {
      return {
        icons: {
          mdiMagnify, mdiBellOutline,},
      }
    },

    data: () => ({
      valid: true,
      files: [],
      bookDescription: "",
      bookName: '',
      bookSubtitle: "",
      selectedImage : null,
      bgCo : "#FF0000",
      nameRules: [
        v => !!v || 'Book name is required',
        v => (v && v.length >= 2) || 'Name must be more than 2 characters',
      ],
      imageRules: [
        value => !value || value.size < 5000000 || 'Image size must be less than 5 MB!',
      ],

    }),

    methods: {
      imageSelected(){
        this.selectedImage = this.files[0];
        console.log("imageSelected");
        console.log(this.files[0]);
      },
      submit () {

        console.log(this.files);
        
        if(this.$refs.form.validate()){

          const fd = new FormData();

          fd.append('image', this.files);
          fd.append('bookName', this.bookName);
          fd.append('bookDescription', this.bookDescription);
          fd.append('bookSubtitle', this.bookSubtitle);
          fd.append('bgColor', this.bgCo);
          

          axios.post(process.env.VUE_APP_API_DOMAIN + "/book_add",fd
          ,{
              headers: {
                'Authorization': 'Bearer ' + this.$store.getters.getAccessToken  
              }
            }
          ).then(
            response => {

              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'Book is added successfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
              }else{
                this.$root.Vtoast.show({message: 'An error occurred!', snackType: 'fail', color: 'warning', icon: mdiAlertRhombus})
              }

            }  

          );
          
        }

      

      },
      vtoastCallback() {
        //vtoastCallback
        this.$router.push('/eisadmin/books');
      }
      
    },


    created() {

    },
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      
    },
  }
</script>
