<template>
  <v-app>
    <TopBar  />
    <LeftMenu />
    <Vtoast ref="Vtoast" :callBack="vtoastCallback" />
    <Confirm ref="Confirm" />

    <div class="pa-5" >
      <v-card elevation="0" outlined>
          <v-card-title>
            <div>SCHOOLS</div>
            <v-spacer></v-spacer>
            <v-btn small color="accent" elevation="0" :href="'/eisadmin/school_add/'" >NEW SCHOOL</v-btn>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60" class="text-left">#</th>
                  <th class="text-left">School</th>
                  <th class="text-left">Classrooms</th>
                  <th width="200"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in schools" :key="item.schoolId" >
                  
                    <td>{{ (page - 1) * 10 + index +1 }}</td>
                    <td>{{ item.schoolName }}</td>
                    <td><v-btn :href="'/eisadmin/school_classes/'+ item.schoolId" small color="accent" outlined >{{ item.classCount }}</v-btn></td>
                    <td class="text-right">
                        <v-btn v-if="item.classCount == 0" small text @click="deleteSchool(item.schoolId, index)">DELETE</v-btn>
                        <v-btn small text :href="'/eisadmin/school_edit/'+ item.schoolId">EDIT</v-btn>
                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions class="mt-3" >
            <v-spacer></v-spacer>
            <v-pagination v-model="page" :length= this.paginationLenght :total-visible=7 ></v-pagination>
          </v-card-actions>
      </v-card>
    </div>

  </v-app>
</template>

<script>

  import checkAuth from '../../mixins/checkAuth';
  import LeftMenu from '../../views/eisadmin/components/LeftMenu'
  import TopBar from '../../views/eisadmin/components/TopBar'
  import { mdiTrashCanOutline,mdiCheckBold } from '@mdi/js'
  import axios from 'axios';
  import Vtoast from '../../views/eisadmin/components/Vtoast';
  import Confirm from '../../views/eisadmin/components/Confirm.vue';

  export default {
    mixins: [checkAuth],
    components: { LeftMenu, TopBar, Vtoast, Confirm },

    setup() {
      return {
        icons: { mdiTrashCanOutline, mdiCheckBold  },
      }
    },

    data(){
      return {
        schools: [],
        page: 1,
        paginationLenght : 0
      }
    },

    methods: {
      getData() {
        
        const data = { page : this.page }
        axios.post(process.env.VUE_APP_API_DOMAIN + "/schools",JSON.stringify(data)
        ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
        .then(
          response => {
            this.schools = response.data.schools;
            this.paginationLenght = Math.ceil(response.data.itemCount / 10);
          }  
        );

      },

      vtoastCallback() {
        console.log('vtoastCallback');
        //this.$router.push('/eisadmin/allschools');
      },

      async deleteSchool(schoolId, index) {
        if(
          await this.$refs.Confirm.open(
            "Confirm",
            "Are you sure you want to delete this school?"
          )
        ){

          const data = { schoolId : schoolId }
          axios.post(process.env.VUE_APP_API_DOMAIN + "/school_delete",JSON.stringify(data)
          ,{ headers: {'Authorization': 'Bearer ' + this.$store.getters.getAccessToken } })
          .then(
            response => {
              console.log(response);
              if(response.data.result == "success"){
                this.$root.Vtoast.show({message: 'School deleted succesfully!', snackType: 'success', color: 'success', icon: mdiCheckBold })
                this.schools.splice(index, 1);
              }
            }  
          );

          
        }
      }

    },

    watch: {
      page: function (newValue, oldValue) {
        if(newValue != oldValue){
          this.getData();
        }
      }
    },
    
    mounted() {
      this.$root.Vtoast = this.$refs.Vtoast;
      this.$root.$Confirm = this.$refs.Confirm.open
      this.getData();
    }
  }
</script>

