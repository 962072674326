<template>
  <div class="row border-bottom1  mt-5">
    <div class="col-md">
      <div class="f_24 font_bold">Class {{className}}</div>
      <div class="">{{schoolName}}</div>
    </div>
    <div class="col-md ">
      
      <div class=" text-right">
          <span class="color_muted f_12 mr-2 d-none d-sm-inline"><v-icon class="mt-n1" size="20">{{icons.mdiInformationOutline}}</v-icon> Share with student</span>
        <span class="classkey_border pa-2 f_14 color_black">Class Key: {{classKey}}</span>
      </div>

      <div class="text-right f_14 mt-3 pr-2" >
        <v-icon size="20" >{{icons.mdiCalendarRange }}</v-icon> {{classStartDate }} > {{classEndDate }}
      </div>
    
    </div>
  </div>

</template>

<script>
import {mdiInformationOutline, mdiCalendarRange} from '@mdi/js'

export default {
  props: {
        className: String,
        schoolName : String,
        classKey : String,
        classStartDate : String,
        classEndDate : String,
  },
  data: () => ({
    VUE_APP_API_DOMAIN : "",
  }),
  methods: {

    
  },
  setup() {
    return {
      icons: {mdiInformationOutline, mdiCalendarRange },
    }
  },
  created () {
    this.VUE_APP_API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
  },
}
</script>

